import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

type Props = {
  className?: string;
  handleClick?: () => void;
};
const BackButton: React.FC<Props> = ({ className, handleClick }) => {
  const history = useHistory();
  const onClick = () => {
    if (handleClick) handleClick();
    else history.goBack();
  };
  return (
    <Button className={className} variant="text" onClick={onClick}>
      <ArrowBackIosIcon fontSize="small" />
      <Typography variant="subtitle2">Back</Typography>
    </Button>
  );
};

export default BackButton;
