import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import page1 from "../assets/images/explain/page1.png";
import page2 from "../assets/images/explain/page2.png";
import page3 from "../assets/images/explain/page3.png";
import page4 from "../assets/images/explain/page4.png";
import React, { useState } from "react";
import ArrowBackdIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingTop: "12px",
      background: "#487C71",
      height: "calc(var(--vh) * 100)",
    },
    carousel: {
      overflowX: "hidden",
    },
    inner: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
      transition: "transform 0.3s",
    },
    carouselItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100vw",
      background: "#487C71",
      padding: "0 16px",
    },
    indicators: {
      position: "fixed",
      bottom: "12px",
      left: "50%",
      transform: "translateX(-50%)",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    indicator: {
      width: "6px",
      height: "6px",
      background: "#EDEDED",
      borderRadius: "6px",
      marginRight: "8px",
    },
    active: {
      background: "#E95225",
    },
    arrow: {
      color: "white",
      position: "fixed",
      top: "50%",
      left: "16px",
      zIndex: 10,
    },
    arrowForward: {
      color: "white",
      position: "fixed",
      top: "50%",
      right: "16px",
      zIndex: 10,
    },
    disabled: {
      opacity: 0.4,
    },
    title: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "28px",
      textAlign: "center",
      letterSpacing: "0.18px",
      color: "rgba(255, 255, 255, 0.87)",
    },
    info: {
      width: "50%",
      overflowWrap: "break-word",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "24px",
      textAlign: "center",
      letterSpacing: "0.18px",
      color: "#FFFFFF",
    },
    subtitle1: {
      width: "80%",
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 600,
      textAlign: "center",
      letterSpacing: "0.15543px",
      color: "#FFFFFF",
    },
    image: {
      height: "calc(var(--vh) * 75)",
      margin: "0",
    },
    closeIcon: {
      color: "white",
      position: "absolute",
      right: "16px",
      top: "16px",
    },
  })
);

const Carousel = ({ children, onClose }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const classes = useStyles();
  const updateIndex = (newIndex) => {
    if (newIndex < React.Children.count(children) && newIndex >= 0) {
      setActiveIndex(newIndex);
    }
    if (newIndex === React.Children.count(children)) {
      onClose();
    }
  };

  return (
    <div className={classes.carousel}>
      <ArrowBackdIosIcon
        className={clsx(classes.arrow, activeIndex === 0 && classes.disabled)}
        onClick={() => {
          updateIndex(activeIndex - 1);
        }}
      />
      <div
        onClick={() => updateIndex(activeIndex + 1)}
        className={classes.inner}
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div className={classes.indicators}>
        {React.Children.map(children, (child, index) => {
          return (
            <div
              className={clsx(
                classes.indicator,
                index === activeIndex && classes.active
              )}
            />
          );
        })}
      </div>
      <ArrowForwardIosIcon
        className={clsx(
          classes.arrowForward,
          activeIndex + 1 >= React.Children.count(children) && classes.disabled
        )}
        onClick={() => {
          updateIndex(activeIndex + 1);
        }}
      />
    </div>
  );
};
interface Props {
  onClose: () => void;
}

const Explain: React.VFC<Props> = ({ onClose }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        How to use Electrek Explorer
      </Typography>
      <CloseIcon className={classes.closeIcon} onClick={onClose} />
      <Carousel onClose={onClose}>
        <Box className={classes.carouselItem}>
          <img src={page1} className={classes.image} />
          <Box className={classes.info}>Choose your region </Box>
        </Box>
        <Box className={classes.carouselItem}>
          <Box className={classes.info}>
            Choose your mode of exploration and discover routes
          </Box>
          <img src={page2} className={classes.image} />
        </Box>
        <Box className={classes.carouselItem}>
          <img src={page3} className={classes.image} />
          <Box className={classes.subtitle1}>
            Select Nat Nav to see E-charging, E-Accommodation, Nature and
            Adventure spots
          </Box>
        </Box>
        <Box className={classes.carouselItem}>
          <Box
            className={classes.subtitle1}
            style={{ position: "absolute", top: "0" }}
          >
            You have 2 options to filter your preferences
          </Box>
          <img src={page4} className={classes.image} />
          <Button fullWidth variant="contained" onClick={onClose}>
            Start
          </Button>
        </Box>
      </Carousel>
    </Box>
  );
};

export default Explain;
