import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import { auth, facebookProvider, googleProvider } from "../firebase";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const AuthContext = React.createContext<any | null>(null);

export const useAuth: any = () => useContext(AuthContext);

export const AuthProvider: React.VFC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>();
  const [loading, setLoading] = useState<boolean>(true);

  const signUp = (email: string, password: string) =>
    auth.createUserWithEmailAndPassword(email, password);

  const signInWithEmail = (email: string, password: string) =>
    auth.signInWithEmailAndPassword(email, password);

  const sendPasswordResetEmail = (email: string) =>
    auth.sendPasswordResetEmail(email);

  const signInWithFacebook = () => auth.signInWithPopup(facebookProvider);

  const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

  const logOut = () => auth.signOut();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        auth.signInAnonymously();
      }
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    signUp,
    signInWithEmail,
    signInWithFacebook,
    signInWithGoogle,
    sendPasswordResetEmail,
    logOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
