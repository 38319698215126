import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import config from "../config";
import AppNavBar from "../components/navbars/AppNavBar";
import eBikePic from "../assets/images/route-picker/ebike.jpg";
import eCarPic from "../assets/images/route-picker/ecar.jpg";
import navigationPic from "../assets/images/route-picker/nav.jpg";
import eBikePicM from "../assets/images/route-picker/ebikem.jpg";
import eCarPicM from "../assets/images/route-picker/ecarm.jpg";
import navigationPicM from "../assets/images/route-picker/navm.jpg";
import clsx from "clsx";

import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import BottomNavBar from "../components/navbars/BottomNavBar";
import { useDevice } from "../hooks/useDevice";
import AppBar from "../components/navbars/AppBar";
import Tooltips from "../components/toolltips/ExploreTooltips";
import { useDispatch } from "react-redux";
import { setType } from "../store/slices/route.slice";

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      //height: "100vh",
      height: "calc(var(--vh, vh) * 100)",
    },
    mobileWrapper: {
      position: "absolute",
      top: "56px",
      width: "100vw",
      display: "grid",
      gridTemplateRows: "1fr 1fr 1fr",
      height: "calc((var(--vh) * 100) - 108px)",
    },
    text: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    card: {
      overflow: "hidden",
      height: "100%",
      position: "relative",
      textAlign: "center",
    },
    mobileCard: {
      overflow: "hidden",
      width: "100%",
      position: "relative",
      textAlign: "center",
    },
    container: {
      height: "calc((var(--vh) * 100) - 56px)",
    },
    shadow: {
      position: "absolute",
      height: "100%",
      opacity: "0%",
      width: "100%",
      background: "black",
      top: "0",
      left: "0",
      transition: "opacity .2s ease-out",
      "&:hover": {
        opacity: "40%",
      },
    },
  })
);
const WhiteTextTypography = withStyles({
  root: {
    textAlign: "center",
    margin: "auto",
    color: "#FFFFFF",
  },
})(Typography);

const RoutePicker = (): JSX.Element => {
  const history = useHistory();
  const classes = useStyles();
  const isMobile = useDevice();
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Box className={classes.container}>
        {isMobile ? (
          <AppBar position="fixed" />
        ) : (
          <AppNavBar activeTabIndex={0} items={config.bottomNavItems} />
        )}
        {isMobile || <Tooltips />}
        <Box
          className={clsx(isMobile ? classes.mobileWrapper : classes.wrapper)}
        >
          <div
            className={clsx(isMobile ? classes.mobileCard : classes.card)}
            onClick={() => {
              dispatch(setType("eCar"));
              history.push({
                pathname: "/route-details",
                state: { eBike: false, eCar: true },
              });
            }}
          >
            <img
              src={isMobile ? eCarPicM : eCarPic}
              width="100%"
              height="100%"
            />
            <div className={classes.shadow}></div>
            <div>
              <WhiteTextTypography variant="h6" className={classes.text}>
                Electric Roadtrips
              </WhiteTextTypography>
            </div>
          </div>
          <div
            className={classes.card}
            onClick={() => {
              dispatch(setType("eBike"));
              history.push({
                pathname: "/route-details",
                state: { eBike: false, eCar: true },
              });
            }}
          >
            <img
              src={isMobile ? eBikePicM : eBikePic}
              width="100%"
              height="100%"
            />
            <div className={classes.shadow}></div>
            <div>
              <WhiteTextTypography variant="h6" className={classes.text}>
                E-Bike Adventures
              </WhiteTextTypography>
            </div>
          </div>
          <div
            className={classes.card}
            onClick={() =>
              history.push({
                pathname: "/route-map",
                state: { eBike: true, eCar: false },
              })
            }
          >
            <img
              src={isMobile ? navigationPicM : navigationPic}
              width="100%"
              height="100%"
            />
            <div className={classes.shadow}></div>
            <div>
              <WhiteTextTypography variant="h6" className={classes.text}>
                Nat Nav
              </WhiteTextTypography>
            </div>
          </div>
        </Box>
      </Box>
      {isMobile && (
        <Box mt={5}>
          <BottomNavBar activeTabIndex={0} items={config.bottomNavItems} />
        </Box>
      )}
    </Fragment>
  );
};

export default RoutePicker;
