import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@material-ui/core";
import { RewildingSiteIcons } from "../../types/rewildingSiteIcons";
import { useDevice } from "../../hooks/useDevice";
const useStyles = makeStyles({
  container: {
    position: "relative",
    display: "flex",
    padding: "6px 12px",
    marginLeft: "16px",
    marginBottom: "16px",
    height: "34px",
    textTransform: "capitalize",
    alignItems: "center",
  },
  close: {
    cursor: "pointer",
    marginLeft: "9px",
    width: "24px",
    height: "24px",
    borderRadius: "25px",
  },
  closeIcon: {
    background: "rgba(0, 0, 0, 0.12)",
    padding: "5px",
    color: "white",
    width: "inherit",
    position: "absolute",
    borderRadius: "20px",
  },
  shadow: {
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: -1,
    width: "100%",
    height: "100%",
    opacity: "0.6",
    borderRadius: "30px",
  },
  text: {
    marginLeft: "5px",
    color: "white",
    whiteSpace: "nowrap",
  },
});

interface Props {
  filter: string;
  removeFilter: () => void;
}

const FilterItem: React.VFC<Props> = ({ filter, removeFilter }) => {
  const classes = useStyles();
  const isMobile = useDevice();
  const isEVChargeFilter = filter === "EV Charge Point";
  const evChargeBackground =
    "linear-gradient(to top left, #22B3D2 50%, #70C063 50%)";

  const background = !isEVChargeFilter
    ? RewildingSiteIcons[filter]?.color
    : evChargeBackground;
  return (
    <span className={classes.container}>
      <div className={classes.shadow} style={{ background }} />
      <img src={RewildingSiteIcons[filter]?.point} />
      {isMobile || <div className={classes.text}>{filter}</div>}
      <Box className={classes.close}>
        <CloseIcon className={classes.closeIcon} onClick={removeFilter} />
      </Box>
    </span>
  );
};

export default FilterItem;
