import { LatLong } from "./location";

export enum RewildingSiteTypes {
  // Accommodation = "Accommodation",
  // CarPark = "Car Park",
  // Hide = "Hide",
  // Offices = "Offices",
  // VisitorCentre = "Visitor Centre",
  EVChargePoint = "EV Charge Point",
  EVCarHire = "EV-Car hire",
  BikeHire = "Bike Hire",
  ElectrekBikeChargeCafe = "Electrek Bike Charge Cafe",
  FoodAndDrink = "Food and Drink",
  Nature = "Nature",
  Walks = "Walks",
  EVAccommodation = "EV Accommodation",
  BikeFriendlyAccommodation = "Bike Friendly Accommodation",
  OtherAccomodation = "Eco Accomodation",
  HistoricSite = "Historic site",
  TrainBusStation = "Train Station/Bike Bus",
  FerryStation = "Ferry Terminal",
  AdventureActivity = "Adventure Activity",
  Town = "Town",
  WildSwim = "Wild Swim",
  Art = "Art",
}

export type RewildingSite = {
  id: string; // firebase document id
  name: string;
  type: RewildingSiteTypes;
  project?: string;
  activties?: string[];
  bookingRequired?: boolean;
  address?: string;
  location: LatLong;
  link?: string;
  image?: string;
  stopNumber: bigint;
};
