import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";
import { CategoryProvider } from "./contexts/CategoryContext";
import { LocationProvider } from "./contexts/LocationContext";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";
import Welcome from "./pages/Welcome";
import CreateAccount from "./pages/account/CreateAccount";
import SignUpWithEmail from "./pages/account/SignUpWithEmail";
import SignIn from "./pages/account/SignIn";
import LocationAccess from "./pages/routes/LocationAccess";
import RoutesList from "./pages/RoutesList";
import Community from "./pages/Community";
import FAQs from "./pages/FAQs";
import Settings from "./pages/Settings";
import UsedStorage from "./pages/UsedStorage";
import RouteMap from "./pages/routes/RouteMap";
import NotFound from "./pages/NotFound";
import RoutePicker from "./pages/RoutePicker";
import Promote from "./pages/routes/Promote";
import Comment from "./pages/routes/Comment";
import "./App.css";
import RegionalMap from "./pages/RegionalMap";
import TermsAndCondition from "./pages/TermsAndConditions";
import PrivacyAndPolicy from "./pages/PrivacyAndPolicy";
import ResetPassword from "./pages/account/passwordReset/ResetPassword";
import MapContainer from "./pages/MapContainer";
import { useDispatch } from "react-redux";
import { AlertProvider } from "./contexts/AlertProvider";
import { setPoiFeaturesAsync } from "./store/slices/poiFeatures.slice";
import { setRoutesInfoAsync } from "./store/slices/route.slice";
import ResetPasswordConfirm from "./pages/account/passwordReset/ResetPasswordConfirm";

const hideLogo = () => document.getElementById("logo")?.remove();

function App(): JSX.Element {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      dispatch(setRoutesInfoAsync());
      dispatch(setPoiFeaturesAsync());
      setTimeout(() => hideLogo(), 500);
    }
  }, [currentUser]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <CategoryProvider>
          <LocationProvider>
            <AlertProvider>
              <Router>
                <Switch>
                  <Route exact path="/" component={Welcome} />
                  <Route path="/create-account" component={CreateAccount} />
                  <Route path="/sign-up" component={SignUpWithEmail} />
                  <Route path="/sign-in" component={SignIn} />
                  <Route path="/reset" component={ResetPassword} />
                  <Route
                    path="/_/auth/action"
                    component={ResetPasswordConfirm}
                  />
                  <Route path="/location-access" component={LocationAccess} />
                  <Route path="/route-picker" component={RoutePicker} />
                  <Route path="/explore" component={RegionalMap} />
                  <Route path="/route-description" component={RoutesList} />
                  <Route path="/community" component={Community} />
                  <Route path="/faqs" component={FAQs} />
                  <Route path="/settings" component={Settings} />
                  <Route path="/used-storage" component={UsedStorage} />
                  <Route path="/promote" component={Promote} />
                  <Route path="/comment" component={Comment} />
                  <Route
                    path="/route-details/:routeId/poi-details/:poiId"
                    component={MapContainer}
                  />
                  <Route
                    path="/route-details/:routeId"
                    component={MapContainer}
                  />
                  <Route path="/route-details" component={MapContainer} />
                  <Route
                    path="/terms-and-condition"
                    component={TermsAndCondition}
                  />
                  <Route path="/privacy-policy" component={PrivacyAndPolicy} />
                  <Route path="/route-map/:poiId" component={RouteMap} />
                  <Route path="/route-map" component={RouteMap} />
                  <Route component={NotFound} />
                </Switch>
              </Router>
            </AlertProvider>
          </LocationProvider>
        </CategoryProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
