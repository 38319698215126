import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import bannerImage from "../assets/images/faqs.png";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import Divider from "@material-ui/core/Divider/Divider";
import { useState } from "react";
import { useDevice } from "../hooks/useDevice";
import logo from "../assets/icons/main-logo.png";
import MobileWrapper from "../components/containers/MobileContainer";
import AppNavBar from "../components/navbars/AppNavBar";
import config from "../config";
import BackButton from "../components/BackButton";

const useStyles = makeStyles(() =>
  createStyles({
    linkContainer: {
      marginTop: "16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    linkIcon: {
      width: "15px",
    },
    linkText: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "16px",
      letterSpacing: "0.15px",
      marginBottom: "16px",
      margin: "0",
    },
    subtitle: {
      fontFamily: "Cabin",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "48px",
    },
    text: {
      margin: 0,
      padding: 0,
    },
    details: {
      margin: "0 0 8px 0",
      padding: 0,
    },
    banner: {
      objectFit: "cover",
      height: "100vh",
      width: "100%",
    },
    info: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    accordion: {
      width: "100%",
      margin: 0,
      padding: 0,
      background: "rgb(250, 250, 250)",
    },
    containerDesktop: {
      height: "100vh",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr",
    },
    topPane: {
      padding: "16px",
      marginTop: "56px",
      height: "calc(100vh - 56px)",
      //overflow: "scroll",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    bottomPane: {
      gridColumn: "2",
      gridRow: "1",
    },
    logo: {
      position: "absolute",
      maxWidth: "50%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
    },
    imageContainer: {
      position: "relative",
    },
    backButton: {
      marginBottom: "24px",
    },
    children: {
      height: "calc(100vh - 226px)",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  })
);

const FAQs = (): JSX.Element => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<number>(-1);

  const handleChange =
    (panel: number) =>
    (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : -1);
    };
  const questionAnswers = [
    {
      question: "Who are we?",
      answer:
        "We are Electrek Explorer: Recharge in Nature. We are on a mission to help promote low carbon travel and connect people with nature and local business. We’re piloting this web app, in partnership with NatureScot, in the South of Scotland and the Outer Hebrides, as a green recovery initiative.",
    },
    {
      question: "How can I use the app?",
      answer:
        "Use this web app to explore fantastic nature sites and help plan electric journeys with E-car and E-bike. Our maps and filters provide sites for E-charging, nature, travel hire, food and drink, walks, wild swim spots, accommodation and more. For a planned adventure check out EV and E-Bike Adventures where you can find our top curated E-routes to explore along with a guide of things to see and do along the way! You can also see and search near your location by using the navigation icon positioned above the plus and minus buttons.",
    },
    {
      question: "How do I find EV charge points?",
      answer:
        "To find EV charging points on the app, first select your region, then select the Nat Nav option. On all our maps you can filter the results to show EV charge points using the filters menu on the top panel. Blue icons represent slow chargers and green are for fast chargers",
    },
    {
      question: "What is an Electrek-bike charge cafe?",
      answer:
        "An Electrek Bike Charge Cafe is a cafe or centre that has plug sockets available to allow cafe customers to charge up their E-bike battery, if they have brought their own charger",
    },
    {
      question: "What is the etiquette for Electrek Bike charge cafe?",
      answer:
        "When charging in a cafe, please remember to be considerate to the staff and other customers. Ask before plugging in and make sure you buy something to support the business while you wait with your batteries. It is also good conduct to not use the charger for more than an hour. Remember that you are responsible for bringing your own charger or adaptors that you may require! ",
    },
    {
      question:
        "Can I find low carbon hire options such as EV, bike and E-bike hire?",
      answer:
        "Yes, to find them, select EV-Car Hire and Bike Hire within the filter menu at the top of the screen. If there is E-bike hire available you will see an E-bike icon at the top left of the image. Once you have found a hire location on the map, follow the weblink to their website to find more details about hire and booking.",
    },
    {
      question: "What is EV Accommodation?",
      answer:
        "EV Accommodation has EV chargers on-site or very nearby that you can use as a guest during your stay. Many of these charges are not for public use and are only available to guests. EV charging information is provided in the description and in the website link.",
    },
    {
      question: "What is Bike Friendly Accommodation?",
      answer:
        "Bike Friendly Accommodation is a place where you and your bike can stay overnight. This means the accommodation provides a safe place to store your bike during your stay.",
    },
    {
      question: "What routes are available in Electrek Explorer?",
      answer:
        "We host nature-filled e-bike, cycling and driving routes with food stops, activities and charging along the way! These routes are currently based in the South of Scotland and the Outer Hebrides, with more regions coming soon!",
    },
    {
      question: "Why are their different coloured cycle routes?",
      answer: `Our cycling routes have 3 different colours to show difficulty:
           Green = Easy, for a leisurely and not too long cycle. Family Friendly.
           Blue = Moderate, a great leisure cycle but are longer than 15km.
           Red = Difficult/Hard, a longer cycle, some with steeper ascent. Some of these routes can be a fantastic adventure if split over 2-3 days or more.`,
    },
    {
      question:
        "I’m a business, how can I promote my company through Electrek Explorer?",
      answer:
        "We would love to hear from EV, bike-friendly and local businesses. If you would like to become part of the Electrek Explorer business community, fill in the form in the Community section of the app.  You can also share your views in our surveys.",
    },
    {
      question: "How can I get in touch with Electrek Explorer?",
      answer:
        "Whether you would like to share your views on where EV charging is needed or what you would like to see more of in the app, ‘Tell us about it’ or leave feedback in our community page.",
    },
    {
      question: "I love this pilot! How do I invest in Electrek Explorer?",
      answer:
        "We’re a start-up company that is helping the transition to sustainable, electric vehicles and low carbon travel. We’ve got ambitious plans to connect Electrek Explorers across the globe and welcome conversations with investors. Chat to us at team@electrekexplorer.com",
    },
  ];

  return (
    <Box pb="16px" style={{ width: "98%" }}>
      {questionAnswers.map((elem, index) => {
        return (
          <div key={index}>
            <Box className={classes.flexContainer}>
              <Accordion
                elevation={0}
                className={classes.accordion}
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  className={classes.text}
                  expandIcon={<ArrowDropDownIcon fontSize="medium" />}
                >
                  <Typography variant="body1" className={classes.text}>
                    {elem.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <Typography
                    variant="body1"
                    color="primary"
                    className={classes.text}
                  >
                    {elem.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Divider />
          </div>
        );
      })}
    </Box>
  );
};

const FAQsContainer = (): JSX.Element => {
  const isMobile = useDevice();
  const classes = useStyles();
  if (isMobile)
    return (
      <MobileWrapper
        activeTabIndex={2}
        bannerImage={bannerImage}
        backButtonShowed={true}
        title="FAQs"
      >
        <FAQs />
      </MobileWrapper>
    );
  return (
    <>
      <AppNavBar activeTabIndex={2} items={config.bottomNavItems} />
      <Box className={classes.containerDesktop}>
        <Box className={classes.topPane}>
          <Box className={classes.info} px={"16px"}>
            <div
              style={{
                maxWidth: "max(500px, 50%)",
                marginTop: "56px)",
              }}
            >
              <div>
                <BackButton className={classes.backButton} />
                <Typography variant="h5"> FAQs </Typography>
                <div className={classes.children}>
                  <FAQs />
                  <Typography variant="subtitle1">
                    Something we haven't answered, get in touch
                    team@electrekexplorer.com
                  </Typography>
                </div>
              </div>
            </div>
          </Box>
        </Box>
        <Box className={classes.bottomPane}>
          <div className={classes.imageContainer}>
            <img
              src={bannerImage}
              className={classes.banner}
              width="100%"
              height="100%"
              alt=""
            />
            <img src={logo} className={classes.logo} />
          </div>
        </Box>
      </Box>
    </>
  );
};
export default FAQsContainer;
