import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGING_SENDER_ID,
  REACT_APP_MESSAGING_APP_ID,
  REACT_APP_MESSAGING_MEASUREMENT_ID,
} = process.env;

// Firebase config; additionally make sure to have security rules set
const config = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,
  appId: REACT_APP_MESSAGING_APP_ID,
  measurementId: REACT_APP_MESSAGING_MEASUREMENT_ID,
};
const app = firebase.initializeApp(config);

export const auth = app.auth();
export const db = app.firestore();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export default app;
