import { ReactComponent as SettingsIcon } from "./assets/icons/navigation/settings.svg";
import { ReactComponent as ExploreIcon } from "./assets/icons/navigation/explore.svg";
import { ReactComponent as CommunityIcon } from "./assets/icons/navigation/community.svg";
import { ReactComponent as SettingsActiveIcon } from "./assets/icons/navigation/settings-active.svg";
import { ReactComponent as ExploreActiveIcon } from "./assets/icons/navigation/explore-active.svg";
import { ReactComponent as CommunityActiveIcon } from "./assets/icons/navigation/community-active.svg";
export const BASE_URL = "https://ee-cms-bq4o74neoa-nw.a.run.app/api/v1";
import { Routes } from "./types/route";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZWxhaW5lZm9yZCIsImEiOiJja3FnZnptY2IwMHMxMndseW5sNThhc2xqIn0.eu347Rj17NpX1iaobKkzhg";

const imagePath = {
  routeListPlaceholder: require("./assets/images/route-list-placeholder.png")
    .default,
  routeDetailsPlaceholder:
    require("./assets/images/route-details-placeholder.png").default,
  icon: {
    startEnd: require("./assets/icons/start-end.svg").default,
    accommodation: require("./assets/icons/category/accommodation.svg").default,
    accommodationHighlight:
      require("./assets/icons/category/accommodation-highlight.svg").default,
    carPark: require("./assets/icons/category/car-park.svg").default,
    carParkHighlight: require("./assets/icons/category/car-park-highlight.svg")
      .default,
    hide: require("./assets/icons/category/hide.svg").default,
    hideHighlight: require("./assets/icons/category/hide-highlight.svg")
      .default,
    offices: require("./assets/icons/category/offices.svg").default,
    officesHighlight: require("./assets/icons/category/offices-highlight.svg")
      .default,
    visitorCentre: require("./assets/icons/category/visitor-centre.svg")
      .default,
    visitorCentreHighlight:
      require("./assets/icons/category/visitor-centre-highlight.svg").default,
  },
};

const bottomNavItems = [
  {
    label: "Explore",
    icon: <ExploreIcon />,
    activeIcon: <ExploreActiveIcon />,
    path: "/explore",
  },
  {
    label: "Community",
    icon: <CommunityIcon />,
    activeIcon: <CommunityActiveIcon />,
    path: "/community",
  },
  {
    label: "Settings",
    icon: <SettingsIcon />,
    activeIcon: <SettingsActiveIcon />,
    path: "/settings",
  },
];

const mapPOIList = [
  { id: "bike-hire" },
  { id: "train-bus-ferry" },
  { id: "wild-swim" },
  { id: "nature" },
  { id: "ebike-charge-cafe" },
  { id: "ev-accommodation" },
  { id: "bike-friendly-accommodation" },
  { id: "nature-sos" },
];

const eBikeRoutes: Routes = require("../src/assets/ebike_main_route_desc.json");

const eCarRoutes: Routes = require("../src/assets/ecar_main_route_desc.json");

export default {
  MAPBOX_ACCESS_TOKEN,
  imagePath,
  bottomNavItems,
  mapPOIList,
  eBikeRoutes,
  eCarRoutes,
};
