import React, { useContext, useState } from "react";
import { LatLong } from "../types/location";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const LocationContext = React.createContext<any | null>(null);

export const useLocation: any = () => useContext(LocationContext);

export const LocationProvider: React.VFC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [location, setLocation] = useState<LatLong>({
    latitude: 56.815937,
    longitude: -4.182201,
  });
  const [isPermissionDenied, setPermissionDenied] = useState<boolean>(false);

  const watchPosition = () => {
    navigator.geolocation.watchPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        setPermissionDenied(true);
      },
      (error) => {
        setPermissionDenied(false);
        console.log("Error: ", error.message);
      }
    );
  };

  const value = {
    location,
    watchPosition,
    isPermissionDenied,
  };

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};
