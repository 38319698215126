import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import config from "../../config";
import AppNavBar from "../navbars/AppNavBar";
import logo from "../../assets/icons/main-logo.png";
import BackButton from "../BackButton";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  containerDesktop: {
    height: "100vh",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr",
  },
  banner: {
    objectFit: "cover",
    height: "calc(100vh)",
    width: "100%",
  },
  info: {
    overflow: "hidden",
    display: "flex",
    position: "relative",
    alignItems: "center",
    flexDirection: "column",
  },
  imageContainer: {
    position: "relative",
  },
  logo: {
    position: "absolute",
    maxWidth: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
  },
  backButton: {
    marginBottom: "42px",
  },
  children: {
    overflowY: "auto",
  },
});

type Props = {
  activeTabIndex?: number;
  bannerImage?: string;
  backButtonShowed?: boolean;
  hideLogo?: boolean;
  title?: string;
};
const DesktopWrapper: React.FC<Props> = ({
  bannerImage = null,
  children,
  activeTabIndex,
  backButtonShowed = false,
  hideLogo = false,
  title,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      {activeTabIndex && (
        <AppNavBar
          activeTabIndex={activeTabIndex}
          items={config.bottomNavItems}
        />
      )}
      <Box className={classes.containerDesktop}>
        <Box className={classes.info} px={"16px"}>
          <div
            style={{
              maxWidth: "max(400px, 50%)",
              margin: "auto",
              overflowY: "auto",
              paddingTop: activeTabIndex ? "56px" : "0px",
            }}
          >
            <div
              style={{
                overflowY: "auto",
              }}
            >
              {backButtonShowed && (
                <BackButton className={classes.backButton} />
              )}
              {title && <Typography variant="h5"> {title} </Typography>}
              <div className={classes.children}>{children}</div>
            </div>
          </div>
        </Box>
        {bannerImage && (
          <div className={classes.imageContainer}>
            <img
              src={bannerImage}
              className={classes.banner}
              width="100%"
              height="100%"
              alt=""
            />
            {hideLogo || (
              <img
                src={logo}
                className={classes.logo}
                alt="Electrek Explorer Logo"
              />
            )}
          </div>
        )}
      </Box>
    </Fragment>
  );
};

export default DesktopWrapper;
