import React, { useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { RewildingSite } from "../types/rewildingSite";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const CategoryContext = React.createContext<any | null>(null);

export const useCategory: any = () => useContext(CategoryContext);

export const CategoryProvider: React.VFC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [rewildingSites, setRewildingSites] = useState<{
    [rewildingSite: string]: RewildingSite[];
  }>();

  useEffect(() => {
    const fetchRewildingSites = async () => {
      const routes = new Object() as any;
      const snapshot = await db.collection("routes").get();
      snapshot.forEach(async (doc) => {
        const rewildingSitesSnapshot = await db
          .collection("routes")
          .doc(doc.id)
          .collection("rewildingSites")
          .get();
        routes[doc.id] = [];
        rewildingSitesSnapshot.forEach((rewildingSitesDoc) => {
          routes[doc.id].push({
            ...rewildingSitesDoc.data(),
            id: rewildingSitesDoc.id,
          });
        });
        setRewildingSites(routes);
        setLoading(false);
      });
    };
    fetchRewildingSites();
  }, []);

  const value = {
    rewildingSites,
  };

  return (
    <CategoryContext.Provider value={value}>
      {!loading && children}
    </CategoryContext.Provider>
  );
};
