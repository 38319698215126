import { travelMode } from "./../../../types/routeType";
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteInfo, Route } from "../../../types/routeType";

export const parseRouteInfo = (route): RouteInfo => ({
  id: route.id,
  name: route.name,
  distance: Number(route.distance),
  region: route.region,
  travel_mode:
    route.travel_mode === "E-Bike" ? travelMode.eBike : travelMode.eCar,
  difficulty: route.difficulty,
  surface: route.surface,
  image_alt: route.image_resized?.alt,
  image_url: route.image_resized?.url,
});

export const parseRoute = (route): Route => {
  const features = route.line_path.features.map((feature) => ({
    ...feature,
    properties: { id: route.id },
  }));
  const line_path = { ...route.line_path, features };
  return {
    id: route.id,
    name: route.name,
    description: route.description,
    distance: Number(route.distance),
    region: route.region,
    travel_mode:
      route.travel_mode === "E-Bike" ? travelMode.eBike : travelMode.eCar,
    difficulty: route.difficulty,
    start_location: route.start_location,
    parking_location: route.parking_location,
    surface: route.surface,
    route_pois: route.route_pois,
    line_path,
    bike_hire_nearby: route.bike_hire_nearby,
    bike_hire_booking_url: route.bike_hire_booking_url,
    image_alt: route.image_resized?.alt,
    image_url: route.image_resized?.url,
  };
};
