import MobileWrapper from "./MobileContainer";
import DesktopWrapper from "./DesktopContainer";
import { useDevice } from "../../hooks/useDevice";

type Props = {
  activeTabIndex?: number;
  bannerImage?: string;
  bannerHeight?: string;
  backButtonShowed?: boolean;
  title?: string;
  hideLogo?: boolean;
};

const DeviceWrapper: React.FC<Props> = ({
  bannerImage,
  children,
  activeTabIndex,
  bannerHeight,
  backButtonShowed,
  title,
  hideLogo,
}) => {
  const isMobile = useDevice();
  return isMobile ? (
    <MobileWrapper
      hideLogo={hideLogo}
      bannerImage={bannerImage}
      activeTabIndex={activeTabIndex}
      bannerHeight={bannerHeight}
      backButtonShowed={backButtonShowed}
      title={title}
    >
      {children}
    </MobileWrapper>
  ) : (
    <DesktopWrapper
      hideLogo={hideLogo}
      bannerImage={bannerImage}
      activeTabIndex={activeTabIndex}
      backButtonShowed={backButtonShowed}
      title={title}
    >
      {children}
    </DesktopWrapper>
  );
};

export default DeviceWrapper;
