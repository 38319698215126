export const regionTypes = {
  outerHebrides: {
    name: "Outer Hebrides",
    fullName: "Outer Hebrides",
    center: [-6.895122528076171, 57.94392334504255],
  },
  southOfScotland: {
    name: "South of Scotland",
    fullName: "The South of Scotland",
    center: [-4.18073, 54.97374],
  },
};
