import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { default as MaterialAppBar } from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import logo from "../../assets/icons/logo.png";
import clsx from "clsx";

const useStyles = makeStyles({
  toolbar: {
    height: "56px",
    justifyContent: "space-between",
    textOverflow: "ellipsis",
    overflow: "hidden",
    zIndex: 0,
  },
  hidden: {
    visibility: "hidden",
  },
  title: {
    margin: "0 auto",
    textAlign: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  hightToolbar: {
    height: "90px",
  },
  logo: {
    //height: "80px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  filter: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "24px",
  },
});

interface Props {
  color?: "default" | "inherit" | "primary" | "secondary" | "transparent";
  position?: "absolute" | "fixed" | "relative" | "static" | "sticky";
  closeButton?: boolean;
  handleClickBack?: () => void;
  backButton?: boolean;
  title?: string;
  openFilterBar?: () => void | null;
  filterBarHeight?: string;
}

const AppBar: React.VFC<Props> = ({
  color,
  position = "static",
  closeButton = false,
  backButton = true,
  title,
  openFilterBar = null,
  handleClickBack,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const onClickBack = () => {
    if (handleClickBack) handleClickBack();
    else history.goBack();
  };
  return (
    <MaterialAppBar color={color} position={position} style={{ zIndex: 800 }}>
      <Toolbar className={clsx(classes.toolbar)}>
        {backButton && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={onClickBack}
          >
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>
        )}
        {closeButton && (
          <IconButton color="inherit" aria-label="close" onClick={onClickBack}>
            <CloseIcon />
          </IconButton>
        )}
        {title ? (
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        ) : (
          <img
            src={logo}
            className={classes.logo}
            onClick={() =>
              history.push({
                pathname: "/explore",
              })
            }
            alt="Electrek Explorer Logo"
            width="94px"
            height="36px"
          />
        )}

        {openFilterBar && (
          <IconButton edge="end" color="inherit" onClick={openFilterBar}>
            <Typography className={classes.filter}>Filters</Typography>
            <FilterIcon />
          </IconButton>
        )}
      </Toolbar>
    </MaterialAppBar>
  );
};

export default AppBar;
