/* eslint-disable @typescript-eslint/no-explicit-any */
import { createGoogleMapsLink } from "../shared/helpers/poiLinksCreator";
import React, { useEffect, useRef } from "react";
// @ts-ignore
import mapboxgl from "!mapbox-gl";
import RouteIcon from "../assets/icons/Route.svg";
import {
  eChargeConnectorTypes,
  fastConnectorsIcons,
  slowConnectorsIcons,
} from "../types/eCharger";
import { connector } from "../types/PointsOfInterests";

const groupConnectorsByType = (connectors: connector[]): connector[][] => {
  let connectorsByType: connector[][] = [];

  if (connectors.length > 0) {
    const connectorsEntires = Object.values(eChargeConnectorTypes).map(
      (type) => {
        const filterConnectors = connectors.filter(
          (connector) => type === connector.type
        );
        return filterConnectors;
      }
    );
    connectorsByType = connectorsEntires.filter((entire) => entire.length);
  }
  return connectorsByType;
};

const Connectors = ({ connectors }) => {
  const filterConnectors = groupConnectorsByType(connectors);
  return (
    <>
      {filterConnectors.map((connectorGroup) => {
        const connector = connectorGroup[0];
        return (
          <div
            key={`connectors-${connector.type}`}
            style={{ display: "contents" }}
          >
            <img
              src={
                connector.chargeRate === "fast"
                  ? fastConnectorsIcons[connector?.type]
                  : slowConnectorsIcons[connector?.type]
              }
              style={{ marginRight: "5px" }}
            />
            <div style={{ marginRight: "4px" }}>
              {connector.ratedOutputKw} kw
            </div>
            <div style={{ color: "#000" }}>({connectorGroup.length})</div>
          </div>
        );
      })}
    </>
  );
};

const POIPopup = ({ name, imageUrl, googleMapsLink }) => {
  return (
    <>
      <div>
        <img
          src={imageUrl}
          style={{
            objectFit: "cover",
            height: "80px",
            width: "100%",
          }}
          width="100%"
          height="100%"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "https://dummyimage.com/255x140/fff/aaa";
          }}
        />
      </div>
      <div className="content" style={{ padding: "0px 10px 15px" }}>
        <div className="title">
          {name}
          <a target="_blank" href={googleMapsLink} rel="noopener noreferrer">
            <img src={RouteIcon} />
          </a>
        </div>
      </div>
    </>
  );
};

const EVChargePopup = ({ name, googleMapsLink, connectors }) => {
  return (
    <div className="content">
      <div className="title">
        {name}
        <a target="_blank" href={googleMapsLink} rel="noopener noreferrer">
          <img src={RouteIcon} />
        </a>
      </div>
      <div className="info">
        <Connectors connectors={connectors} />
      </div>
    </div>
  );
};

interface PopupProps {
  popupContent: any;
  coordinates: Array<number>;
}
export const Popup: React.VFC<PopupProps> = ({ popupContent, coordinates }) => {
  if (!popupContent || !coordinates.length) return <></>;
  const googleMapsLink = createGoogleMapsLink(coordinates[0], coordinates[1]);
  if (popupContent.type === "EV Charge Point")
    return (
      <EVChargePopup
        name={popupContent.name}
        googleMapsLink={googleMapsLink}
        connectors={popupContent.connectors}
      />
    );
  else {
    return (
      <POIPopup
        name={popupContent.name}
        imageUrl={popupContent.imageUrl}
        googleMapsLink={googleMapsLink}
      />
    );
  }
};

interface Props {
  popupContent: any;
  coordinates: Array<number>;
  handleClose: () => void;
  map: any;
}

const PopupBase: React.VFC<Props> = ({
  popupContent,
  coordinates,
  handleClose,
  map,
}) => {
  const popupRef = useRef(null);
  useEffect(() => {
    const popup = new mapboxgl.Popup({
      closeButton: false,
      offset: [0, -35],
    }).setDOMContent(popupRef.current);

    if (coordinates.length) popup.setLngLat(coordinates).addTo(map);
    return popup.remove;
  }, [popupContent]);

  return (
    <div onClick={handleClose} ref={popupRef}>
      <Popup popupContent={popupContent} coordinates={coordinates} />
    </div>
  );
};

export default PopupBase;
