/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import axios from "axios";
import { BASE_URL } from "../config";
type HttpClient = AxiosInstance;
class CMSService {
  instance: HttpClient;
  constructor(config) {
    this.instance = axios.create(config);
  }

  async getMapPoints(): Promise<any> {
    const response = await this.instance.get(
      "/pages/?type=points_of_interest.PoiPage&fields=poi_type,location,name,image_resized,poi_connectors,-title&limit=1000&format=json"
    );
    return response.data.items;
  }

  async getPointInfo(id: number): Promise<any> {
    const response = await this.instance.get(`/pages/${id}`);
    return response.data;
  }

  async getRoutesInfo(): Promise<any> {
    const response = await this.instance.get(
      "pages/?type=routes.RoutePage&fields=name,distance,surface,difficulty,travel_mode,region,image_resized&format=json"
    );
    return response.data.items;
  }

  async getRouteById(id: number): Promise<any> {
    const response = await this.instance.get(`pages/${id}`);
    return response.data;
  }
}

const cmsService = new CMSService({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export { cmsService };
