/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from "react";

export const useThrottle = (callBack: (...args) => void, interval = 600) => {
  const [args, setArgs] = useState<Array<unknown>>([]);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (!timerId) {
      callBack(...args);
      const currentTimerId = setTimeout(() => {
        setTimerId(null);
      }, interval);
      setTimerId(currentTimerId);
    }
  }, [interval, args]);
  return (...args) => setArgs(args);
};
