/* eslint-disable  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

import firebase from "firebase/app";
import "firebase/storage";

export async function getFirebaseStorageFileJSON(
  fileName: string
): Promise<any> {
  const storageRef = firebase.storage().ref();
  try {
    const file = await storageRef.child(fileName);
    const downloadUrl = await file.getDownloadURL();
    const response = await fetch(downloadUrl);
    const data = await response.json();
    return data;
  } catch {
    return null;
  }
}

export async function getFirebaseStorageFileSize(
  fileName: string
): Promise<number> {
  const storageRef = firebase.storage().ref();
  try {
    const file = await storageRef.child(fileName);
    const metadata = await file.getMetadata();
    return metadata.size;
  } catch {
    return 0;
  }
}
