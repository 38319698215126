import { parseMapPoi, parsePOI } from "./../shared/helpers/parsers/parsePois";
import { Feature } from "geojson";
import { PointOfInterests } from "../types/PointsOfInterests";
import {
  getMarkerIcon,
  parseConnectors,
} from "../shared/helpers/parsers/parsePois";
import { cmsService } from "./CMSservice";

class POIsService {
  static async getMapPoints(): Promise<Array<Feature>> {
    const points = await cmsService.getMapPoints();
    const pointFeatures: Array<Feature> = points.map((point) => {
      const connectors = parseConnectors(point.poi_connectors);
      const markerIcon = getMarkerIcon(point.poi_type, connectors);
      return parseMapPoi({ ...point, connectors, markerIcon });
    });
    return pointFeatures;
  }

  static async getPointInfo(id: number): Promise<PointOfInterests> {
    const point = await cmsService.getPointInfo(id);
    const connectors = parseConnectors(point.poi_connectors);
    return parsePOI({ ...point, connectors });
  }
}

export { POIsService };
