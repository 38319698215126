import { Feature } from "geojson";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../types/app-thunk-type";
import StorageService from "../../services/storageService";
import { Route, RouteInfo } from "../../types/routeType";
import { RouteService } from "../../services/routesService";
const REGION = "region";
const TYPE = "type";
const storageRegion = StorageService.getItem(REGION);
const storageType = StorageService.getItem(TYPE);
const region = typeof storageRegion === "string" ? storageRegion : "";
const type = typeof storageType === "string" ? storageType : "";
export interface RouteType {
  region: string;
  features: Feature[];
  routes: RouteInfo[];
  currentRoute: Route | null;
  type: string;
}

const initialState: RouteType = {
  features: [],
  routes: [],
  currentRoute: null,
  region,
  type,
};

export const routeSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    setRegion: (state, action: PayloadAction<string>) => {
      StorageService.setItem(REGION, action.payload);
      return {
        ...state,
        region: action.payload,
      };
    },
    setType: (state, action: PayloadAction<string>) => {
      StorageService.setItem(TYPE, action.payload);
      return {
        ...state,
        type: action.payload,
      };
    },
    setRoutes: (state, action: PayloadAction<RouteInfo[]>) => {
      return {
        ...state,
        routes: action.payload,
      };
    },
    setCurrentRoute: (state, action: PayloadAction<Route>) => {
      return {
        ...state,
        currentRoute: action.payload,
      };
    },
    deleteCurrentRoute: (state) => {
      return {
        ...state,
        currentRoute: null,
      };
    },
  },
});

export const setRoutesInfoAsync = (): AppThunk => async (dispatch) => {
  const routes = await RouteService.getRoutesInfo();
  dispatch(routeSlice.actions.setRoutes(routes));
};

export const setRouteAsync =
  (id: number): AppThunk =>
  async (dispatch) => {
    const route = await RouteService.getRouteById(id);
    dispatch(routeSlice.actions.setCurrentRoute(route));
  };

export const { setType, setRegion, deleteCurrentRoute } = routeSlice.actions;

export default routeSlice.reducer;
