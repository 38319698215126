import React from "react";
import { useHistory } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { default as MaterialAppBar } from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import logo from "../../assets/icons/logo.png";
import { NavBarItem } from "../../types/navBarItem";
import { ReactComponent as LogoutIcon } from "../../assets/icons/navigation/logout.svg";
import { useAuth } from "../../contexts/AuthContext";
import clsx from "clsx";
import { regionTypes } from "../../types/regions";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const useStyles = makeStyles({
  logoImage: {
    height: "36px",
    width: "auto",
    cursor: "pointer",
  },
  toolbar: {
    minHeight: "56px",
    height: "56px",
    display: "flex",
    justifyContent: "space-between",
  },
  menuItems: {
    display: "flex",
    justifyContent: "space-between",
  },
  navBarItems: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuItem: {
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "#487C71",
    fontSize: "16px",
    cursor: "pointer",
    letterSpacing: "0.4px",
    marginLeft: "48px",
    whiteSpace: "nowrap",
    "&:hover": {
      color: "white",
      backgroundColor: "#487C71",
    },
  },
  menuItemActive: {
    color: "white",
    fontWeight: 700,
  },
  region: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  logoutIcon: {
    paddingLeft: "72px",
  },
});

interface Props {
  activeTabIndex: number;
  items: Array<NavBarItem>;
  showRegion?: boolean;
}

const AppNavBar: React.VFC<Props> = ({
  activeTabIndex,
  items,
  showRegion = true,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { logOut, currentUser } = useAuth();
  const [selectedIndex, setSelectedIndex] = React.useState(activeTabIndex);
  let regionName = useSelector((state: RootState) => state.route.region);
  const region = Object.values(regionTypes).find(
    (region) => region.name === regionName
  );
  regionName = (showRegion && region?.fullName) || "Explore";
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    const path =
      items[index].label === "Explore" ? "/route-picker" : items[index].path;
    return history.push(path);
  };
  return (
    <MaterialAppBar style={{ zIndex: 1000 }}>
      <Toolbar className={classes.toolbar}>
        <img
          src={logo}
          className={classes.logoImage}
          onClick={() =>
            history.push({
              pathname: "/explore",
            })
          }
          alt="Electrek Explorer Logo"
          width="94px"
          height="36px"
        />
        <div className={classes.navBarItems}>
          <div className={classes.menuItems}>
            {items.map((item, index) => (
              <Typography
                className={clsx(
                  classes.menuItem,
                  index === selectedIndex && classes.menuItemActive
                )}
                key={item.label}
                color="primary"
                onClick={(event) => handleMenuItemClick(event, index)}
              >
                <span>
                  {item.label === "Explore" ? regionName : item.label}
                </span>
              </Typography>
            ))}
          </div>
          {currentUser?.isAnonymous || (
            <div
              className={classes.logoutIcon}
              onClick={() => {
                history.push({
                  pathname: "/sign-in",
                });
                logOut();
              }}
            >
              <LogoutIcon />
            </div>
          )}
        </div>
      </Toolbar>
    </MaterialAppBar>
  );
};

export default AppNavBar;
