import StorageService from "../../services/storageService";
import { useState } from "react";
import Tooltip from "./tooltip";

const Tooltips = (): JSX.Element => {
  const tipsPages = StorageService.getTips();
  const [currentTip, setCurrentTip] = useState(0);
  const tooltips = [
    {
      text: "Choose your mode of exploration and discover routes",
      style: {
        top: "151px",
        left: "33.3vw",
        transform: "translateX(-50%)",
      },
    },
    {
      text: "Select Nat Nat to see E-charging, nature spots, activities and more",
      style: {
        top: "calc(47vh - 56px)",
        right: "16.7vw",
        transform: "translateX(50%)",
      },
    },
  ];
  if (tooltips.length <= currentTip || !tipsPages.includes("Explore"))
    return <></>;

  const onClose = () => {
    if (tooltips.length === currentTip + 1)
      StorageService.setTips(tipsPages.filter((tip) => tip !== "Explore"));
    setCurrentTip((prev) => prev + 1);
  };
  return (
    <Tooltip
      text={tooltips[currentTip].text}
      style={tooltips[currentTip].style}
      onClose={onClose}
    />
  );
};

export default Tooltips;
