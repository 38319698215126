import { LatLong } from "./location";

import FastMennekesIcon from "../assets/icons/ev_connectors_types/(Fast)Mennekes (Type 2).svg";
import FastCHAdeMOIcon from "../assets/icons/ev_connectors_types/(Fast)Quick Charge (CHAdeMO).svg";
import FastCSSType2Combo from "../assets/icons/ev_connectors_types/(Fast) SAE.svg";
import FastBS1363Icon from "../assets/icons/ev_connectors_types/(Fast)Wall Outlet (BS1363).svg";
import FastTeslaIcon from "../assets/icons/ev_connectors_types/Tesla Fast.svg";

import MennekesIcon from "../assets/icons/ev_connectors_types/Mennekes (Type 2).svg";
import CHAdeMOIcon from "../assets/icons/ev_connectors_types/Quick Charge (CHAdeMO).svg";
import CSSType2Combo from "../assets/icons/ev_connectors_types/SAE.svg";
import BS1363Icon from "../assets/icons/ev_connectors_types/Wall Outlet (BS1363).svg";
import TeslaIcon from "../assets/icons/ev_connectors_types/Tesla.svg";

export enum eChargeConnectorTypes {
  Mennekes = "Type 2 Mennekes (IEC62196)",
  CHAdeMO = "JEVS G105 (CHAdeMO) DC",
  CSSType2Combo = "CCS Type 2 Combo (IEC62196)",
  J1772 = "J-1772",
  BS1363 = "3-pin Type G (BS1363)",
  Tesla = "Tesla",
  TeslaCCS = "Tesla CCS",
}

export const eChargeConnectorShortNames = {
  [eChargeConnectorTypes.Mennekes]: "Type 2",
  [eChargeConnectorTypes.CHAdeMO]: "CHAdeMO",
  [eChargeConnectorTypes.CSSType2Combo]: "CCS",
  [eChargeConnectorTypes.J1772]: "J-1772",
  [eChargeConnectorTypes.BS1363]: "3-pin Type G (BS1363)",
  [eChargeConnectorTypes.Tesla]: "Tesla",
  [eChargeConnectorTypes.TeslaCCS]: "Tesla CCS",
};

export const fastConnectorsIcons = {
  [eChargeConnectorTypes.Mennekes]: FastMennekesIcon,
  [eChargeConnectorTypes.CHAdeMO]: FastCHAdeMOIcon,
  [eChargeConnectorTypes.CSSType2Combo]: FastCSSType2Combo,
  [eChargeConnectorTypes.BS1363]: FastBS1363Icon,
  [eChargeConnectorTypes.Tesla]: FastTeslaIcon,
  [eChargeConnectorTypes.TeslaCCS]: FastCSSType2Combo,
};

export const slowConnectorsIcons = {
  [eChargeConnectorTypes.Mennekes]: MennekesIcon,
  [eChargeConnectorTypes.CHAdeMO]: CHAdeMOIcon,
  [eChargeConnectorTypes.CSSType2Combo]: CSSType2Combo,
  [eChargeConnectorTypes.BS1363]: BS1363Icon,
  [eChargeConnectorTypes.Tesla]: TeslaIcon,
  [eChargeConnectorTypes.TeslaCCS]: CSSType2Combo,
};

export type eChargeConnector = {
  type: string;
  power: number;
};

export type eChargers = {
  id: number;
  name: string;
  type: string;
  description: string;
  address: string;
  post_code: string;
  contact_number: string;
  email: string;
  opening_times: string;
  website: string;
  sequence_number: number;
  network: string;
  connector_type: string;
  connectors: eChargeConnector[];
  maximumPower: number;
  number_of_chargers: number;
  attribution: string;
  poi_url: string;
  walk_url: string;
  citizen_science_url: string;
  delivery: string;
  ebike: string;
  guiding: string;
  geometry: LatLong;
  facilities: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const eChargersFromJson = (featureJson: any): eChargers => {
  let connectors: eChargeConnector[] = [];

  if (featureJson.properties.connector_type) {
    connectors = featureJson.properties.connector_type
      .replace(/\[|\]/g, "")
      .split(",")
      .filter((elem) => elem != "NA:NA")
      .map(
        (elem) =>
          ({
            type: elem.split(":")[0],
            power: +elem.split(":")[1],
          } as eChargeConnector)
      );
  }
  return {
    id: featureJson.properties.id,
    name: featureJson.properties.name,
    type: featureJson.properties.type,
    description: featureJson.properties.description,
    address: featureJson.properties.address,
    post_code: featureJson.properties.post_code,
    contact_number: featureJson.properties.contact_number,
    email: featureJson.properties.email,
    facilities: featureJson.properties.facilities,
    opening_times: featureJson.properties.opening_times,
    website: featureJson.properties.website,
    sequence_number: featureJson.properties.sequence_number,
    network: featureJson.properties.network,
    connector_type: featureJson.properties.connector_type,
    connectors: connectors,
    maximumPower: Math.max(...connectors.map((c) => c.power)),
    number_of_chargers: featureJson.properties.number_of_chargers,
    attribution: featureJson.properties.attribution,
    poi_url: featureJson.properties.poi_url,
    walk_url: featureJson.properties.walk_url,
    citizen_science_url: featureJson.properties.citizen_science_url,
    delivery: featureJson.properties.delivery,
    ebike: featureJson.properties.ebike,
    guiding: featureJson.properties.guiding,
    geometry: {
      latitude: featureJson.geometry.coordinates[1],
      longitude: featureJson.geometry.coordinates[0],
    },
  };
};
