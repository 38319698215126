import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import bannerImage from "../assets/images/terms.jpg";
import { useDevice } from "../hooks/useDevice";
import logo from "../assets/icons/main-logo.png";
import MobileWrapper from "../components/containers/MobileContainer";
import AppNavBar from "../components/navbars/AppNavBar";
import config from "../config";
import BackButton from "../components/BackButton";

const useStyles = makeStyles(() =>
  createStyles({
    linkContainer: {
      marginTop: "16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    linkIcon: {
      width: "15px",
    },
    linkText: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "16px",
      letterSpacing: "0.15px",
      marginBottom: "16px",
      margin: "0",
    },
    subtitle: {
      fontFamily: "Cabin",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "48px",
    },
    text: {
      margin: 0,
      padding: 0,
    },
    details: {
      margin: "0 0 8px 0",
      padding: 0,
    },
    banner: {
      objectFit: "cover",
      height: "100vh",
      width: "100%",
    },
    info: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    accordion: {
      width: "100%",
      margin: 0,
      padding: 0,
      background: "rgb(250, 250, 250)",
    },
    containerDesktop: {
      height: "100vh",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr",
    },
    topPane: {
      padding: "16px",
      marginTop: "56px",
      height: "calc(100vh - 56px)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    bottomPane: {
      gridColumn: "2",
      gridRow: "1",
    },
    logo: {
      position: "absolute",
      maxWidth: "50%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
    },
    imageContainer: {
      position: "relative",
    },
    backButton: {
      marginBottom: "24px",
    },
    subtitle1: {
      fontWeight: 700,
    },
    children: {
      height: "calc(100vh - 226px)",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  })
);

const TermsAndCondition = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box pb="56px">
      <Box>
        <p className={classes.subtitle1}>Our terms</p>
        <p className={classes.subtitle1}>1. These terms</p>
        <p>
          1.1 These are the terms and conditions on which you make use of our
          website and our mobile application (Terms).
        </p>
        <p>
          1.2 Please read these terms carefully before using our website and /
          or mobile application (our website and mobile as our Platforms).
          Accessing and / or using any our Platforms constitutes your acceptance
          of these Terms. If you do not agree to these Terms, you must refrain
          from using our using our Platforms.
        </p>
        <p>
          1.3 We reserve the right to amend these Terms from time to time. Every
          time you wish to use our Platforms, please check these Terms to ensure
          you understand the Terms that apply at time. Your continued use of our
          Platforms will constitute your acceptance of any changes made.
        </p>
        <p className={classes.subtitle1}>
          2. Information about us and how to contact us
        </p>
        <p>
          2.1 We are Brilliant Biology Ltd (trading as Electrek Explorer) a
          company registered in Scotland. Our company numbers SC629588 You can
          contact us by writing to us using enquiries@electrekexplorer.com
        </p>
        <p className={classes.subtitle1}>3. Our Platforms</p>
        <p>
          3.1 When using our Platforms, you must comply with these Terms at all
          times.
        </p>
        <p>
          3.2 We do not guarantee that our Platforms, or any content on them,
          will always be available or be uninterrupted. We may suspend or
          withdraw or restrict the availability of all or any part of our
          Platforms at any time for business and operational reasons without
          notice. We will not be liable if for any reason our Platform is
          unavailable or interrupted at any time any period.
        </p>
        <p>
          3.3 We do not guarantee that our Platforms will be secure or free from
          bugs or viruses. You are responsible for configuring your information
          technology, computer programmes and platform to access our site. You
          should use your own virus protection software.
        </p>
        <p>
          3.4 You must not misuse our site by knowingly introducing viruses,
          trojans, worms, logic bombs or other material that is malicious or
          technologically harmful. You must not attempt to gain unauthorised
          access to our Platforms, the server on which our Platforms are stored
          or any server, computer or database connected to our Platforms. You
          must not attack our site via a denial-of-service attack or a
          distributed denial-of service attack. We will report any such breach
          to the relevant law enforcement authorities and we will co-operate
          with those authorities by disclosing your identity to them. In the
          event of such a breach, your right to use our Platforms will cease
          immediately
        </p>
        <p className={classes.subtitle1}> 4. Registering on our Platforms</p>
        <p>
          4.1 If you choose to register an account with us, you must treat all
          information pertaining to your account (including without limitation
          your log in details or any other piece of that forms part of our
          security procedures) as confidential.
        </p>
        <p>
          4.2 We reserve the right to disable any account, at any time, if in
          our reasonable opinion you have failed to comply with provision of
          these Terms.
        </p>
        <p className={classes.subtitle1}>5. Information on our Platforms</p>
        <p>
          5.1 The content on our Platforms is provided for general information
          only. It is not intended to amount to advice on which you should rely.
        </p>
        <p>
          5.2 You agree and accept that you take all responsibility and
          liability for your own behaviour and safety, even when using
          information on our Platforms, including, but not exclusively:
          <p>(a) never using our Platforms whilst driving</p>
        </p>
        <p>
          (b) checking your own safety on any walks, swims, or any other outdoor
          sports
        </p>
        <p>
          5.3 Although we make reasonable efforts to update the information on
          our Platforms, we make no representations, warranties or guarantees,
          whether expressed or implied, that the content on our Platforms is
          accurate, complete or up to date.
        </p>
        <p>
          5.4 We do not accept any responsibility or liability arising from any
          reliance placed on any content provided on our Platforms.
        </p>
        <p>
          5.5 Where our Platform contains links to other sites and resources
          provided by third parties, these links are provided for your
          information only. Such links should not be interpreted as approval by
          us of those linked websites or information you may obtain from them.
          We have no control over the contents of those sites of resources and
          you will need to make your own independent judgement about whether to
          use any such independent sites.
        </p>
        <p>
          5.6 [Our Platform may include information and materials uploaded by
          other users of the site. This information and these materials have not
          been verified or approved by us. The views expressed by other users on
          our site do not represent our views or values.]
        </p>
        <p className={classes.subtitle1}>6. Intellectual Property</p>
        <p>
          6.1 We are the owner or the licensee of all intellectual property
          rights in our Platforms, and in the material published on them. Those
          works are protected by copyright laws and treaties around the world.
          All such rights are reserved.
        </p>
        <p>
          6.2 You are permitted to print and download extracts from our
          Platforms on the following basis:
        </p>
        <p>
          (a) you may print off one copy, and may download extracts, of any
          pages from our Platforms for your own personal use;
        </p>
        <p>
          (b) you must not modify the paper or digital copies of any materials
          you have printed off or downloaded in any way, and you must not use
          any illustrations, photographs, video or audio sequences or any
          graphics separately from any accompanying text;
        </p>
        <p>
          (c) our status (and that of any identified contributors) as the
          authors of content on our Platforms must always be acknowledged
          (except where the content is user-generated);
        </p>
        <p>
          (d) you must not use any part of the content on our Platforms for
          commercial purposes without obtaining a licence to do so from us or
          our licensors;
        </p>
        <p>
          (e) the reproduction by whatever means of the whole or any part of our
          Platform for any commercial related purpose is strictly forbidden; and
        </p>
        <p>
          (f) if you print off, copy, download, share or repost any part of our
          Platform in breach of these terms of use, your right to use our
          Platforms will cease immediately and you must, at our option, return
          or destroy any copies of the materials you have made.
        </p>
        <p className={classes.subtitle1}>7. Our liability</p>
        <p>
          7.1 We do not exclude or limit in any way our liability to you where
          it would be unlawful to do so. This includes liability or death or
          personal injury caused by our negligence and for fraud or fraudulent
          misrepresentation.
        </p>
        <p>
          7.2 The content on our Platforms is provided without any guarantees,
          conditions or warranties as to its accuracy. We exclude all implied
          guarantees, conditions, warranties, representations or other terms
          that may apply to our Platforms or any content on them.
        </p>
        <p>
          7.3 We will not be liable to you for any loss or damage, whether in
          contract, delict (including negligence), breach of statutory duty, or
          otherwise, even if foreseeable, arising under or inconnection with:
        </p>
        <p>(a) use of, or inability to use, our Platforms; or</p>
        <p>(b) use of or reliance on any content displayed on our Platforms.</p>
        <p>
          7.4 We do not accept any liability for loss of profits, sales,
          business or revenue; business interruptions; loss of anticipated
          savings; loss of business opportunity, goodwill or or any indirect or
          consequential loss or damage.
        </p>
        <p className={classes.subtitle1}>
          8. How we may use your personal information
        </p>
        <p>
          We process information about you in accordance with our Privacy
          Policy. By using our Platforms, you consent to such and you warrant
          that all data provided by you is accurate.
        </p>
        <p className={classes.subtitle1}>9. Other important terms</p>
        <p>
          9.1 We may transfer this agreement to someone else. We may transfer
          our rights and obligations under these Terms to another organisation.
        </p>
        <p>
          9.2 Termination. We reserve the right to refuse access to our
          Platforms to any user at our sole discretion.
        </p>
        <p>
          9.3 Severance. Each of the paragraphs of these Terms operates
          separately. If any court or relevant authority decides that any of
          them are unlawful, the remaining paragraphs will remain in full force
          and effect.
        </p>
        <p>
          9.4 Even if we delay in enforcing this contract, we can still enforce
          it later. If we do not insist immediately that you do anything you are
          required to do under these Terms, or if we delay in taking steps
          against you in respect of your breaking this contract, that will not
          mean that you do not have to do those things and it will not prevent
          us taking steps against you at a later date.
        </p>
        <p>
          9.5 Governing Law and Jurisdiction. These Terms are governed by Scots
          law and you can bring legal proceedings in respect of the Platforms in
          the Scottish courts.
        </p>
      </Box>
    </Box>
  );
};

const TermsAndConditionsContainer = (): JSX.Element => {
  const isMobile = useDevice();
  const classes = useStyles();
  if (isMobile)
    return (
      <MobileWrapper
        activeTabIndex={2}
        bannerImage={bannerImage}
        backButtonShowed={true}
        title="Terms and conditions"
      >
        <TermsAndCondition />
      </MobileWrapper>
    );
  return (
    <>
      <AppNavBar activeTabIndex={2} items={config.bottomNavItems} />
      <Box className={classes.containerDesktop}>
        <Box className={classes.topPane}>
          <Box className={classes.info} px={"16px"}>
            <div
              style={{
                maxWidth: "max(500px, 50%)",
                marginTop: "56px)",
              }}
            >
              <div>
                <BackButton className={classes.backButton} />
                <Typography variant="h5"> Terms and conditions</Typography>
                <div className={classes.children}>
                  <TermsAndCondition />
                </div>
              </div>
            </div>
          </Box>
        </Box>
        <Box className={classes.bottomPane}>
          <div className={classes.imageContainer}>
            <img
              src={bannerImage}
              className={classes.banner}
              width="100%"
              height="100%"
              alt=""
            />
            <img src={logo} className={classes.logo} />
          </div>
        </Box>
      </Box>
    </>
  );
};
export default TermsAndConditionsContainer;
