import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import StorageService from "../../services/storageService";
import { RewildingSiteTypes } from "../../types/rewildingSite";
const FILTERS = "filters";
const defaultFilters = Object.values(RewildingSiteTypes);

const storageFilters = StorageService.getItem(FILTERS);
const initialFilters = Array.isArray(storageFilters)
  ? storageFilters
  : defaultFilters;

export interface Filters {
  filters: string[];
}

const initialState: Filters = {
  filters: initialFilters,
};

export const filterSlice = createSlice({
  name: FILTERS,
  initialState,
  reducers: {
    addFilter: (state, action: PayloadAction<string[]>) => {
      const newFilters = [...state.filters, ...action.payload];
      StorageService.setItem(FILTERS, newFilters);
      return {
        ...state,
        filters: newFilters,
      };
    },
    removeFilter: (state, action: PayloadAction<string>) => {
      const newFilters = state.filters.filter(
        (filter) => !action.payload.includes(filter)
      );
      StorageService.setItem(FILTERS, newFilters);
      return {
        ...state,
        filters: newFilters,
      };
    },
    setFilters: (state, action: PayloadAction<string[]>) => {
      const newFilters = action.payload;
      StorageService.setItem(FILTERS, newFilters);
      return {
        ...state,
        filters: newFilters,
      };
    },
  },
});

export const { addFilter, removeFilter, setFilters } = filterSlice.actions;
export default filterSlice.reducer;
