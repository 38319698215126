import { RewildingSiteTypes } from "./rewildingSite";

export const FilterCategories = {
  "EV Charge": [
    RewildingSiteTypes.EVChargePoint,
    RewildingSiteTypes.ElectrekBikeChargeCafe,
  ],
  "See and Do": [
    RewildingSiteTypes.BikeHire,
    RewildingSiteTypes.Nature,
    RewildingSiteTypes.Walks,
    RewildingSiteTypes.WildSwim,
    RewildingSiteTypes.AdventureActivity,
    RewildingSiteTypes.Art,
    RewildingSiteTypes.HistoricSite,
    RewildingSiteTypes.Town,
  ],
  "Eat & Drink": [
    RewildingSiteTypes.FoodAndDrink,
    RewildingSiteTypes.ElectrekBikeChargeCafe,
  ],
  Stay: [
    RewildingSiteTypes.EVAccommodation,
    RewildingSiteTypes.BikeFriendlyAccommodation,
    RewildingSiteTypes.OtherAccomodation,
  ],
  Transport: [
    RewildingSiteTypes.TrainBusStation,
    RewildingSiteTypes.EVCarHire,
    RewildingSiteTypes.BikeHire,
    RewildingSiteTypes.FerryStation,
  ],
};
