/* eslint-disable  @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

import {
  getFirebaseStorageFileJSON,
  getFirebaseStorageFileSize,
} from "./firebaseStorage";

const store = require("store/dist/store.modern");

export async function saveStorageFileJSON(fileName: string): Promise<any> {
  const data = await getFirebaseStorageFileJSON(fileName);
  store.set(fileName, JSON.stringify(data));
  return data;
}

export async function saveObjectInStorage(
  fileName: string,
  data: any
): Promise<any> {
  try {
    store.set(fileName, JSON.stringify(data));
  } catch (error) {
    console.log(error);
  }
  return data;
}

export async function getStorageFileJSON(
  fileName: string,
  autoSave = false
): Promise<any> {
  let data = store.get(fileName);

  if (data) {
    return JSON.parse(data);
  } else {
    if (autoSave) {
      data = await saveStorageFileJSON(fileName);
    } else {
      data = await getFirebaseStorageFileJSON(fileName);
    }
  }
  return data;
}

export async function getStorageFileSize(fileName: string): Promise<number> {
  const data = store.get(fileName);
  if (!data) {
    const size = await getFirebaseStorageFileSize(fileName);
    return size;
  }
  return Buffer.byteLength(JSON.stringify(data));
}

export function getObjectFileSize(obj: any): string {
  const length = JSON.stringify(obj).length;
  return formatFileSize(length);
}

export function formatFileSize(bytes: number): string {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function isFileDownloaded(fileName: string): boolean {
  const data = store.get(fileName);
  return data ? true : false;
}
