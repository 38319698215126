import { Fragment, useState } from "react";
import Button from "../../../components/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import accountImage from "../../../assets/images/account-page-min.jpeg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import EmailSended from "./EmailSendedPage";
import { useDevice } from "../../../hooks/useDevice";
import DesktopWrapper from "../../../components/containers/DesktopContainer";
import AppBar from "../../../components/navbars/AppBar";

const ResetPassword = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isEmailSended, setEmailSended] = useState(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const history = useHistory();
  const { sendPasswordResetEmail } = useAuth();

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handleAlertClose = () => setOpenAlert(false);
  const handleSignInClick = () => history.push("/sign-in");
  const handleClickPasswordReset = async () => {
    try {
      await sendPasswordResetEmail(email);
      setError("");
      setEmailSended(true);
    } catch (error) {
      setOpenAlert(true);
      setError(error.message);
    }
  };
  if (isEmailSended)
    return (
      <EmailSended email={email} resendEmail={() => setEmailSended(false)} />
    );
  return (
    <Fragment>
      <Box>
        <Typography variant="h5">Please enter your email </Typography>
        <Typography>
          Enter the email address you used when you joined and we’ll send you
          instructions to reset your password.
        </Typography>
        <Box mt="24px">
          <TextField
            id="email"
            label="Email address"
            variant="filled"
            fullWidth={true}
            onChange={handleEmailChange}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="contained"
            disabled={!email}
            fullWidth={true}
            onClick={handleClickPasswordReset}
          >
            Request password reset
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="text"
            fullWidth={true}
            onClick={handleSignInClick}
          >
            Wait, I remember it now !
          </Button>
        </Box>
      </Box>
      <Dialog
        open={openAlert}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {error && "Failed to reset password"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const ResetPasswordContainer = (): JSX.Element => {
  const isMobile = useDevice();
  if (isMobile)
    return (
      <Fragment>
        <AppBar backButton={true} />
        <Box px={2} pt={3} pb={3}>
          <ResetPassword />
        </Box>
      </Fragment>
    );
  return (
    <DesktopWrapper bannerImage={accountImage} backButtonShowed={true}>
      <ResetPassword />
    </DesktopWrapper>
  );
};
export default ResetPasswordContainer;
