import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "../../components/Button";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DeviceWrapper from "../../components/containers/DeviceContainer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import accountImage from "../../assets/images/account-page-min.jpeg";

import { useAuth } from "../../contexts/AuthContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: theme.spacing(3),
    },
    tsandcs: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
  })
);

interface State {
  email: string;
  password: string;
  showPassword: boolean;
  tsandcsChecked: boolean;
}

const SignUpWithEmail = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = useState<State>({
    email: "",
    password: "",
    showPassword: false,
    tsandcsChecked: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { signUp } = useAuth();

  const handleInputChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickTsAndCs = () => {
    setValues({ ...values, tsandcsChecked: !values.tsandcsChecked });
  };

  const handleClickSignUp = async () => {
    try {
      setError("");
      setLoading(true);
      await signUp(values.email, values.password);
      history.push("/explore");
    } catch (error) {
      setOpenAlert(true);
      setError(error.message);
    }
    setLoading(false);
  };

  const handleAlertClose = () => setOpenAlert(false);

  return (
    <Fragment>
      <Box>
        <Typography variant="h5">Sign up </Typography>
        <Box mb={6}>
          <Typography variant="body1">
            Create your account to keep up to date with new adventures,
            exploring routes and experiences
          </Typography>
        </Box>
        <TextField
          id="email"
          label="Email address"
          variant="filled"
          fullWidth={true}
          onChange={handleInputChange("email")}
          InputProps={{
            disableUnderline: true,
          }}
        />
        <FormControl
          className={classes.margin}
          variant="filled"
          fullWidth={true}
        >
          <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleInputChange("password")}
            disableUnderline={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Box className={classes.tsandcs} mt={3}>
          <Checkbox
            checked={values.tsandcsChecked}
            onChange={handleClickTsAndCs}
            name="checked"
            color="primary"
          />
          <Typography variant="subtitle1">
            I acknowledge that I agree with the&nbsp;
            <Link href="#" onClick={() => history.push("/terms-and-condition")}>
              terms & conditions
            </Link>
            &nbsp;and have read&nbsp;
            <Link href="#" onClick={() => history.push("/privacy-policy")}>
              Privacy Policy
            </Link>
          </Typography>
        </Box>
        <Button
          color="primary"
          variant="contained"
          fullWidth={true}
          disabled={!values.tsandcsChecked}
          onClick={handleClickSignUp}
        >
          Sign up
        </Button>
      </Box>
      <Box mt={3} textAlign={"center"}>
        <Typography variant="subtitle1">
          Already have an account?{" "}
          <Link
            color="primary"
            style={{ textDecoration: "none" }}
            href="/sign-in"
          >
            Sign in
          </Link>
        </Typography>
      </Box>

      <Dialog
        open={openAlert}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Failed to create an account
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary" disabled={loading}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
const SignUpWithEmailContainer = (): JSX.Element => {
  return (
    <DeviceWrapper bannerImage={accountImage} backButtonShowed={true}>
      <SignUpWithEmail />
    </DeviceWrapper>
  );
};
export default SignUpWithEmailContainer;
