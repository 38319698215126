import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDevice } from "../../hooks/useDevice";
import FilterItem from "./FilterItem";
import { useDispatch, useSelector } from "react-redux";
import { removeFilter } from "../../store/slices/filter.slice";
import { RootState } from "../../store/store";

const useStyles = makeStyles({
  carousel: {
    position: "absolute",
    height: "40px",
    width: "calc(100%)",
    overflow: "hidden",
    borderRadius: "20px",
  },
  filterList: {
    display: "flex",
    width: "100%",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  filterButton: {
    position: "absolute",
    cursor: "pointer",
    zIndex: 23,
    top: "0px",
    width: "40px",
    height: "40px",
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "20px",
  },
  arrowIcon: {
    height: "14px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

const FilterCarousel = (): JSX.Element => {
  const classes = useStyles();
  const isMobile = useDevice();
  const filters = useSelector((state: RootState) => state.filter.filters);
  const dispatch = useDispatch();
  const onDelete = (filter) => {
    dispatch(removeFilter(filter));
  };
  const next = () => {
    const carousel = document.getElementById("filterList");
    carousel?.scroll({
      left: carousel.scrollLeft + 500,
      behavior: "smooth",
    });
  };
  const prev = () => {
    const carousel = document.getElementById("filterList");
    carousel?.scroll({
      left: carousel.scrollLeft - 500,
      behavior: "smooth",
    });
  };
  return (
    <div
      className={classes.carousel}
      style={isMobile ? {} : { width: "calc(100% - 70px)", left: "70px" }}
    >
      <div className={classes.filterList} id="filterList">
        {filters.map((filter) => (
          <FilterItem
            key={filter}
            filter={filter}
            removeFilter={() => onDelete(filter)}
          />
        ))}
      </div>
      {isMobile || (
        <>
          <div
            onClick={prev}
            className={classes.filterButton}
            style={{ left: "0" }}
          >
            <ArrowBackIosIcon className={classes.arrowIcon} />
          </div>
          <div
            onClick={next}
            className={classes.filterButton}
            style={{ right: "0" }}
          >
            <ArrowForwardIosIcon className={classes.arrowIcon} />
          </div>
        </>
      )}
    </div>
  );
};

export default FilterCarousel;
