import React, { useContext, useState } from "react";

/* eslint-disable  @typescript-eslint/no-explicit-any */
const AlertContext = React.createContext<any | null>(null);

export const useAlert: any = () => useContext(AlertContext);

export const AlertProvider: React.VFC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [alert, setAlert] = useState(null);
  const sendAlert = (_alert) => {
    setAlert(_alert);
  };

  const getAlert = () => {
    const _alert = alert;
    setAlert(null);
    return _alert;
  };

  const value = {
    alert,
    sendAlert,
    getAlert,
  };

  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};
