import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
  image: {
    objectFit: "cover",
    height: "100%",
  },
  shadow: {
    backgroundColor: "black",
    opacity: "40%",
    position: "absolute",
    height: "100%",
    width: "100%",
    zIndex: 3,
  },
  whiteText: {
    color: "white",
  },
  description: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, .2)",
    cursor: "pointer",
    zIndex: 4,
  },
  info: {
    position: "absolute",
    bottom: "0",
    textAlign: "end",
    right: "12px",
  },
  container: {
    position: "relative",
    height: "240px",
    overflow: "hidden",
  },
});

interface Props {
  name: string;
  distance: number;
  difficulty?: string | boolean;
  photoUrl: string;
  onClick: () => void;
  onMouseEnter: () => void;
  isActive: boolean;
}

const RouteInfo: React.VFC<Props> = ({
  name,
  distance,
  difficulty,
  photoUrl,
  onClick,
  onMouseEnter,
  isActive: isActive,
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.container}
      onMouseEnter={(event) => {
        event.preventDefault();
        onMouseEnter();
      }}
      onClick={onClick}
    >
      {isActive && <Box className={classes.shadow} />}
      <Box className={classes.description}>
        <Box p={2}>
          <Typography
            variant={isActive ? "h5" : "h6"}
            className={classes.whiteText}
            gutterBottom
          >
            {name}
          </Typography>
          <Box className={classes.info}>
            <Typography
              variant="subtitle1"
              className={classes.whiteText}
              gutterBottom
            >
              Distance: {distance} km
            </Typography>
            {difficulty && (
              <Typography
                variant="subtitle1"
                className={classes.whiteText}
                gutterBottom
              >
                Difficulty: {difficulty}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <img
        src={photoUrl}
        height="auto"
        width="100%"
        className={classes.image}
      />
    </Box>
  );
};

export default memo(RouteInfo);
