import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  eChargeConnectorShortNames,
  fastConnectorsIcons,
  slowConnectorsIcons,
} from "../../types/eCharger";
import { connector } from "../../types/PointsOfInterests";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { groupConnectorsByType } from "../../shared/helpers/getConnectorsInfo";

interface Props {
  connectors: connector[];
}

const useStyles = makeStyles(() =>
  createStyles({
    connectorInfo: {
      marginTop: "12px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      flexDirection: "row",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
      color: "#487c71",
    },
    connectorContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

const EVChargeConnectors: React.VFC<Props> = ({ connectors }) => {
  const classes = useStyles();
  const filterConnectors = groupConnectorsByType(connectors);
  return (
    <>
      {filterConnectors.map((connectorsGroup) => {
        const connector = connectorsGroup[0];
        return (
          <Box
            className={classes.connectorInfo}
            key={`connector-${connector.type}`}
          >
            <Box className={classes.connectorContainer}>
              <img
                src={
                  connector.chargeRate === "slow"
                    ? slowConnectorsIcons[connector?.type]
                    : fastConnectorsIcons[connector?.type]
                }
                style={{ marginRight: "4px" }}
              />
              <Typography
                variant="body1"
                style={{ color: "#000", marginLeft: "8px" }}
              >
                {eChargeConnectorShortNames[connector.type]}
              </Typography>
            </Box>
            <Box className={classes.connectorContainer}>
              <Typography variant="body1">
                {connector.ratedOutputKw} kw
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "#000", marginLeft: "5px" }}
              >
                ({connectorsGroup.length})
              </Typography>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
export default EVChargeConnectors;
