import { createStyles, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
    },
    content: {
      flex: 4,
    },
    item: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

interface PoiContactProps {
  url?: string;
  icon: string;
  name: string;
}

const PoiContact: React.VFC<PoiContactProps> = ({ url, icon, name }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} mt="12px">
      <Box mr="12px">
        <img src={icon} />
      </Box>
      <Box className={classes.content}>
        <Typography
          color="primary"
          variant="subtitle1"
          className={classes.item}
        >
          <Link
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", overflowWrap: "anywhere" }}
          >
            {name}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default PoiContact;
