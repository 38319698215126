import { useState } from "react";

export const useDevice = (): boolean => {
  const maxWidth = 600;
  const [isMobile, setMobile] = useState<boolean>(
    window.innerWidth <= maxWidth
  );
  window.addEventListener("resize", () => {
    setMobile(window.innerWidth <= maxWidth);
  });
  return isMobile;
};
