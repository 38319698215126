import { Fragment, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import config from "../config";
import BottomNavBar from "../components/navbars/BottomNavBar";
import RouteInformation from "../components/RouteInfo";
import Map from "../components/Map";
import AppBar from "../components/navbars/AppBar";
import BackButton from "../components/BackButton";
import Typography from "@material-ui/core/Typography";
import FilterBar from "../components/filterBar/FilterBar";
import { useDevice } from "../hooks/useDevice";
import BikeIcon from "../assets/icons/elements/bike.svg";
import CarIcon from "../assets/icons/elements/car.svg";
import { useHistory } from "react-router-dom";
import { setRouteAsync } from "../store/slices/route.slice";
import { RootState } from "../store/store";
import { useDispatch, useSelector } from "react-redux";
import { travelMode } from "../types/routeType";
import { useThrottle } from "../hooks/useThrottle";

const useStyles = makeStyles(() =>
  createStyles({
    map: {
      height: "100%",
      width: "100ww",
    },
    mapContainer: {
      marginTop: "55px",
      height: "calc(var(--vh, vh) * 100 - 350px)",
      width: "100%",
      zIndex: 20,
      transition: "all .4s ease-out",
    },
    title: {
      marginTop: "30px",
    },
    routeInfo: {
      display: "block",
      marginRight: "8px",
      height: "270px",
      width: "255px",
      position: "relative",
    },
    routesInfoImage: {
      width: "255px",
      height: "140px",
      objectFit: "cover",
    },
    routeTitle: {
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
    },
    carousel: {
      maxWidth: "100%",
      overflowX: "scroll",
    },
    subtitle: {
      color: "rgba(0, 0, 0, 0.6)",
    },
  })
);

interface Props {
  showRouteDetails: () => void;
}

const RoutesList: React.VFC<Props> = ({ showRouteDetails }) => {
  const history = useHistory();
  const classes = useStyles();
  const isMobile = useDevice();
  const setRouteThrottled = useThrottle((id) => {
    history.push(`/route-details/${id}`);
    dispatch(setRouteAsync(id));
  });
  const [isFilterBarOpened, setFilterBar] = useState<boolean>(false);
  const { region, currentRoute, type } = useSelector(
    (state: RootState) => state.route
  );
  const routes = useSelector((state: RootState) =>
    state.route.routes.filter(
      (route) => route.region === region && route.travel_mode === type
    )
  );
  const colors = {
    Easy: "green",
    Moderate: "blue",
    Hard: "red",
    Challenging: "black",
  };
  const dispatch = useDispatch();

  const setCurrentRoute = (id) => () => {
    if (id !== currentRoute?.id) setRouteThrottled(id);
  };

  const onScroll = (event) => {
    const scrollLeft = event.nativeEvent.srcElement.scrollLeft;
    const index = Math.floor((scrollLeft - 100) / 255) + 1;
    setCurrentRoute(routes[index].id)();
  };

  if (!isMobile)
    return (
      <Fragment>
        <Box p={3}>
          <BackButton handleClick={() => history.push("/route-picker")} />
          <Typography variant="h5" className={classes.title}>
            Explore {type === travelMode.eBike ? "E-Bikes" : "E-Cars"} routes
          </Typography>
        </Box>
        {routes.map(({ name, distance, difficulty, image_url, id }) => (
          <RouteInformation
            name={name}
            distance={distance}
            difficulty={type === travelMode.eBike && difficulty}
            photoUrl={image_url || ""}
            key={name}
            isActive={currentRoute?.id === id}
            onClick={showRouteDetails}
            onMouseEnter={setCurrentRoute(id)}
          />
        ))}
      </Fragment>
    );

  return (
    <Fragment>
      <AppBar
        title={`Explore ${type} routes`}
        position="fixed"
        openFilterBar={() => setFilterBar(true)}
        backButton
        handleClickBack={() => history.push("/route-picker")}
      />
      <FilterBar isOpen={isFilterBarOpened} setOpen={setFilterBar} />
      <div className={classes.mapContainer}>
        <Map
          lineColor={
            type === travelMode.eBike
              ? colors[currentRoute?.difficulty ?? "Easy"]
              : undefined
          }
          showRoute={!!currentRoute}
          zoom={10}
          interactive={true}
          className={classes.map}
        />
      </div>
      <Box pt={1}>
        <div className={classes.carousel} onScroll={onScroll}>
          <Box style={{ display: "flex", margin: "0 8px" }}>
            {routes.map(({ name, distance, difficulty, image_url, id }) => (
              <Box
                key={id}
                className={classes.routeInfo}
                onClick={showRouteDetails}
              >
                <img
                  src={image_url || ""}
                  className={classes.routesInfoImage}
                />
                <Box>
                  <Box className={classes.routeTitle}>{name}</Box>
                  {type === travelMode.eBike ? (
                    <img src={BikeIcon} style={{ marginRight: "20px" }} />
                  ) : (
                    <img src={CarIcon} style={{ marginRight: "20px" }} />
                  )}
                  <Box className={classes.subtitle}>
                    Distance: {distance} km
                  </Box>
                  {type === travelMode.eCar && (
                    <Box className={classes.subtitle}>
                      Difficulty: {difficulty}
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </div>
      </Box>
      <BottomNavBar activeTabIndex={0} items={config.bottomNavItems} />
    </Fragment>
  );
};

export default RoutesList;
