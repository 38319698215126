const StorageService = {
  firstInit: (): void => {
    localStorage.setItem("welcomeShown", "Yes");
    sessionStorage.setItem("firstSession", "Yes");
    const tipsPages = ["Explore", "Map"];
    sessionStorage.setItem("tips", JSON.stringify(tipsPages));
  },
  getIsFirstSession: (): boolean => {
    const isFirstSession = sessionStorage.getItem("firstSession");
    return !!isFirstSession;
  },
  getTips: (): string[] => {
    const tips = JSON.parse(sessionStorage.getItem("tips") || "[]");
    return tips;
  },
  getAuthShowed: (): boolean => {
    return !!sessionStorage.getItem("authShowed");
  },
  setAuthShowed: (): void => {
    sessionStorage.setItem("authShowed", "showed");
  },
  setTips: (tips: string[]): void => {
    sessionStorage.setItem("tips", JSON.stringify(tips));
  },
  getItem: (itemName: string): unknown => {
    try {
      const item = JSON.parse(
        JSON.stringify(localStorage.getItem(itemName) || "{}")
      );
      if (typeof item === "string") return JSON.parse(item);
      return item;
    } catch (error) {
      return "";
    }
  },
  setItem: (itemName: string, item: unknown): void => {
    localStorage.setItem(itemName, JSON.stringify(item));
  },
};

export default StorageService;
