import React, { Fragment, useEffect, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import AppBar from "../../components/navbars/AppBar";
import Button from "../../components/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import config from "../../config";
import Map from "../../components/Map";
import BackButton from "../../components/BackButton";
import FilterBar from "../../components/filterBar/FilterBar";
import BikeIcon from "../../assets/icons/elements/bike.svg";
import CarIcon from "../../assets/icons/elements/car.svg";
import ShareIcon from "../../assets/icons/elements/share.svg";
import { useDevice } from "../../hooks/useDevice";
import { useAuth } from "../../contexts/AuthContext";
import BottomNavBar from "../../components/navbars/BottomNavBar";
import {
  getObjectFileSize,
  saveObjectInStorage,
} from "../../shared/helpers/fileStorage";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ReactComponent as RouteIcon } from "../../assets/icons/Route.svg";
import { ReactComponent as RouteWhiteIcon } from "../../assets/icons/RouteWhite.svg";
import arrowUp from "../../assets/icons/elements/arrow-up.svg";
import { createGoogleMapsLink } from "../../shared/helpers/poiLinksCreator";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ShareLinkDialog from "../../components/ShareLinkDialog";
import Link from "@material-ui/icons/Link";
import { travelMode } from "../../types/routeType";

import parkingIcon from "../../assets/icons/filter/parking.svg";
import bikeIcon from "../../assets/icons/filter/bicycle.svg";

import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    map: {
      height: "100%",
      width: "100wh",
    },
    mapContainer: {
      top: "0px",
      marginBottom: "24px",
      height: "calc(var(--vh, vh) * 100 - 80px)",
      width: "100%",
      position: "fixed",
      zIndex: -10,
    },
    wrapper: {
      position: "sticky",
      height: "100vh",
      overflowY: "auto",
      top: "0",
      boxSizing: "border-box",
    },
    title: {
      marginTop: "30px",
    },
    image: {
      width: "100%",
    },
    subtitle: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      color: "rgba(0, 0, 0, 0.6)",
    },
    titleMobile: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "24px",
      color: "#000000",
      marginBottom: "14px",
    },
    infoContainer: {
      position: "relative",
      zIndex: 200,
      paddingTop: "32px",
    },
    arrowUp: {
      width: "32px",
      position: "absolute",
      top: "0",
      left: "50%",
      transform: "translate(-50%, 50%)",
    },
    buttonWrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    shareButton: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      color: "#487C71",
    },
    parkingIcon: {
      marginRight: "16px",
    },
    parkingNearby: {
      display: "flex",
      marginTop: "12px",
      marginBottom: "16px",
      color: "#487C71",
    },
    parkingContent: {
      flex: 1,
    },
    parkingLink: {
      textDecoration: "none",
    },
  })
);
interface Props {
  showRoutesList: () => void;
}

const RouteDetails: React.VFC<Props> = ({ showRoutesList }) => {
  const classes = useStyles();
  const type = useSelector((state: RootState) => state.route.type);
  const route = useSelector((state: RootState) => state.route.currentRoute);
  const currentURL = window.location.href;
  const [isPoiDetailsOpen, setPoiDetailsOpen] = useState<boolean>(false);
  const colors = {
    Easy: "green",
    Moderate: "blue",
    Hard: "red",
    Challenging: "black",
  };
  const [isFilterBarOpened, setFilterBar] = useState(false);
  const [downloadingRoute, setDownloadingRoute] = useState(false);
  const [routeDownloaded, setRouteDownloaded] = useState(false);
  const [routeDownloadSize, setRouteDownloadSize] = useState<string>("");
  const [googleMapsRouteDir, setGoogleMapsRouteDir] = useState("");
  const [parkingRouteDir, setParkingRouteDir] = useState("");
  const [isShareOpened, setShareOpen] = useState<boolean>(false);
  const { currentUser } = useAuth();
  const isMobile = useDevice();
  const features = route?.line_path.features[0];

  useEffect(() => {
    if (features?.geometry.type === "MultiLineString") {
      const [longitude, latitude] = features.geometry.coordinates[0][0];
      const googleMapsLink = createGoogleMapsLink(longitude, latitude);
      if (googleMapsLink) setGoogleMapsRouteDir(googleMapsLink);
    }
    if (route?.parking_location) {
      const coordinates =
        route?.parking_location["features"][0].geometry.coordinates;
      const parkingLink = createGoogleMapsLink(coordinates[0], coordinates[1]);
      if (parkingLink) setParkingRouteDir(parkingLink);
    }
    setRouteDownloadSize(getObjectFileSize(route));
  }, []);

  const saveRouteForOfflineAccess = async () => {
    setDownloadingRoute(true);
    await saveObjectInStorage(`${route?.id}-line.json`, route);
    setDownloadingRoute(false);
    setRouteDownloaded(true);
  };
  if (!route) return <></>;

  if (!isMobile)
    return (
      <Fragment>
        <Box px={3} pt={3}>
          <BackButton handleClick={showRoutesList} />
        </Box>
        <Box
          px={3}
          pt={3}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" style={{ marginBottom: "0" }}>
            {route?.name}
          </Typography>
          <Box style={{ position: "absolute", right: "8px", display: "flex" }}>
            {type === travelMode.eBike ? (
              <img src={BikeIcon} style={{ marginRight: "20px" }} />
            ) : (
              <img src={CarIcon} style={{ marginRight: "20px" }} />
            )}
            <a href={googleMapsRouteDir} target="_blank" rel="noreferrer">
              <RouteIcon />
            </a>
          </Box>
        </Box>
        <Box px={3}>
          <Typography className={classes.subtitle} style={{ fontSize: "12px" }}>
            {type === travelMode.eBike ? "E-Bike" : "E-Car"}
          </Typography>
        </Box>

        <img src={route.image_url || ""} className={classes.image} />
        <Box px={3}>
          <Box px={2}>
            <Box pb={3} pt="24px">
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className={classes.subtitle}>
                  Distance: {route?.distance || "Not provided"} km
                </Typography>
                <Box
                  className={classes.shareButton}
                  onClick={() => {
                    setShareOpen(true);
                  }}
                >
                  <img src={ShareIcon} width="16px" />
                  Share
                </Box>
              </Box>
              {type === travelMode.eBike && (
                <Typography variant="subtitle1" color="textSecondary">
                  Difficulty: {route?.difficulty || "Not provided"}
                </Typography>
              )}
            </Box>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: route?.description || "Not provided",
              }}
            />
          </Box>
          {route?.parking_location &&
            route.parking_location !== null &&
            parkingRouteDir !== "" && (
              <>
                <a
                  href={parkingRouteDir}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.parkingLink}
                >
                  <Box className={classes.parkingNearby}>
                    <Box className={classes.parkingIcon}>
                      <img src={parkingIcon} />
                    </Box>
                    <Box className={classes.parkingContent}>
                      <Typography color="primary" variant="subtitle1">
                        Navigate to Parking Nearby this route
                      </Typography>
                    </Box>
                    <Box className={classes.parkingIcon}>
                      <RouteWhiteIcon />
                    </Box>
                  </Box>
                </a>
                <Divider />
              </>
            )}
          {route?.bike_hire_nearby &&
            route?.bike_hire_booking_url &&
            route?.bike_hire_booking_url !== "" && (
              <>
                <a
                  href={route?.bike_hire_booking_url}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.parkingLink}
                >
                  <Box className={classes.parkingNearby}>
                    <Box className={classes.parkingIcon}>
                      <img src={bikeIcon} />
                    </Box>
                    <Box className={classes.parkingContent}>
                      <Typography color="primary" variant="subtitle1">
                        Nearby Bike Hire Website
                      </Typography>
                    </Box>
                    <Box className={classes.parkingIcon}>
                      <Link />
                    </Box>
                  </Box>
                </a>
              </>
            )}
          <Box px={2} py={3}>
            {currentUser && !currentUser?.isAnonymous && (
              <>
                <Typography color="primary" variant="h6">
                  Use offline
                </Typography>
                <div className={classes.buttonWrapper}>
                  <Button
                    color="primary"
                    variant="outlined"
                    fullWidth={true}
                    onClick={saveRouteForOfflineAccess}
                    style={{ marginTop: 0 }}
                    disabled={downloadingRoute || routeDownloaded}
                  >
                    {!routeDownloaded
                      ? `Download route for offline use ${routeDownloadSize}`
                      : `Route downloaded for offline use ${routeDownloadSize}`}
                  </Button>
                  {downloadingRoute && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </>
            )}
          </Box>
        </Box>
        <ShareLinkDialog
          isOpen={isShareOpened}
          onClose={() => setShareOpen(false)}
          url={currentURL}
        />
      </Fragment>
    );
  return (
    <Box>
      <div style={{ display: isPoiDetailsOpen ? "none" : "block" }}>
        <AppBar
          position="fixed"
          title={route?.name}
          openFilterBar={() => setFilterBar(true)}
          backButton
          handleClickBack={showRoutesList}
        />
        <FilterBar isOpen={isFilterBarOpened} setOpen={setFilterBar} />
      </div>
      <div className={classes.mapContainer}>
        <Map
          lineColor={
            type === travelMode.eBike
              ? colors[route?.difficulty ?? "Easy"]
              : undefined
          }
          zoom={10}
          setPOIShowed={setPoiDetailsOpen}
          interactive={true}
          className={classes.map}
        />
      </div>
      <Box
        style={{
          marginBottom: "calc(var(--vh, vh) * 100 - 84px)",
          zIndex: -110,
        }}
      ></Box>
      <Box
        className={classes.infoContainer}
        style={{
          background: "white",
          display: isPoiDetailsOpen ? "none" : "block",
        }}
      >
        <img src={arrowUp} className={classes.arrowUp} />
        <Box px={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" className={classes.titleMobile}>
              {route?.name}
            </Typography>
            <a href={googleMapsRouteDir} target="_blank" rel="noreferrer">
              <RouteIcon />
            </a>
          </Box>
          <Box style={{ marginBottom: "10px" }}>
            {type === travelMode.eBike ? (
              <img src={BikeIcon} style={{ marginRight: "20px" }} />
            ) : (
              <img src={CarIcon} style={{ marginRight: "20px" }} />
            )}
            <img src={route.image_url || ""} className={classes.image} />
          </Box>
          <Box pb={3}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className={classes.subtitle}>
                Distance: {route?.distance || "Not provided"} km
              </Typography>
              <Box
                className={classes.shareButton}
                onClick={() => {
                  setShareOpen(true);
                }}
              >
                <img src={ShareIcon} width="16px" />
                Share
              </Box>
            </Box>
            {type === travelMode.eBike && (
              <Typography variant="subtitle1" color="textSecondary">
                Difficulty: {route?.difficulty || "Not provided"}
              </Typography>
            )}
          </Box>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: route?.description || "Not provided",
            }}
          />
        </Box>
        {route?.parking_location &&
          route.parking_location !== null &&
          parkingRouteDir !== "" && (
            <>
              <a
                href={parkingRouteDir}
                target="_blank"
                rel="noreferrer"
                className={classes.parkingLink}
              >
                <Box px={2} py={1} className={classes.parkingNearby}>
                  <Box className={classes.parkingIcon}>
                    <img src={parkingIcon} />
                  </Box>
                  <Box className={classes.parkingContent}>
                    <Typography color="primary" variant="subtitle1">
                      Navigate to Parking Nearby this route
                    </Typography>
                  </Box>
                  <Box className={classes.parkingIcon}>
                    <RouteWhiteIcon />
                  </Box>
                </Box>
              </a>
              <Divider />
            </>
          )}
        {route?.bike_hire_nearby &&
          route?.bike_hire_booking_url &&
          route?.bike_hire_booking_url !== "" && (
            <>
              <a
                href={route?.bike_hire_booking_url}
                target="_blank"
                rel="noreferrer"
                className={classes.parkingLink}
              >
                <Box px={2} py={1} className={classes.parkingNearby}>
                  <Box className={classes.parkingIcon}>
                    <img src={bikeIcon} />
                  </Box>
                  <Box className={classes.parkingContent}>
                    <Typography color="primary" variant="subtitle1">
                      Nearby Bike Hire Website
                    </Typography>
                  </Box>
                  <Box className={classes.parkingIcon}>
                    <Link />
                  </Box>
                </Box>
              </a>
            </>
          )}
        <Box px={2} py={3} mb="56px">
          {currentUser && !currentUser?.isAnonymous && (
            <>
              <Typography color="primary" variant="h6">
                Use offline
              </Typography>
              <div className={classes.buttonWrapper}>
                <Button
                  color="primary"
                  variant="outlined"
                  fullWidth={true}
                  onClick={saveRouteForOfflineAccess}
                  style={{ marginTop: 0 }}
                  disabled={downloadingRoute || routeDownloaded}
                >
                  {!routeDownloaded
                    ? `Download route for offline use ${routeDownloadSize}`
                    : `Route downloaded for offline use ${routeDownloadSize}`}
                </Button>
                {downloadingRoute && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </>
          )}
        </Box>
      </Box>
      <ShareLinkDialog
        isOpen={isShareOpened}
        onClose={() => setShareOpen(false)}
        url={currentURL}
      />
      <BottomNavBar activeTabIndex={0} items={config.bottomNavItems} />
    </Box>
  );
};

export default RouteDetails;
