import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { default as MaterialAppBar } from "@material-ui/core/AppBar";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as RouteWhiteIcon } from "../assets/icons/RouteWhite.svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import emailIcon from "../assets/icons/email.svg";
import websiteIcon from "../assets/icons/website.svg";
import phoneIcon from "../assets/icons/phone.svg";
import chargeIcon from "../assets/icons/elements/charge.svg";
import routeDesktopIcon from "../assets/icons/elements/route-red.svg";
import ShareIcon from "../assets/icons/elements/share.svg";
import { useDevice } from "../hooks/useDevice";
import { createGoogleMapsLink } from "../shared/helpers/poiLinksCreator";
import { useState } from "react";
import ShareLinkDialog from "./ShareLinkDialog";
import { FacilitiesIcon } from "../types/facilitiesIcon";
import PoiContact from "./PoiContact";
import { PointOfInterests } from "../types/PointsOfInterests";
import POIConnectors from "./connectors/POIconnectors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    routeContainer: {
      display: "flex",
    },
    routeMapIcon: {
      marginTop: theme.spacing(0.5),
      marginRight: "16px",
    },
    routeContent: {
      flex: 4,
    },
    routeItem: {
      display: "flex",
      justifyContent: "space-between",
    },
    routeDivider: {
      marginTop: theme.spacing(2),
      marginRight: -theme.spacing(2),
    },
    mobileWrapper: {
      position: "fixed",
      overflow: "scroll",
      width: "100vw",
      height: "calc(var(--vh, vh) * 100)",
      background: "white",
      paddingBottom: "56px",
    },
    containerMobile: {
      display: "flex",
      flexDirection: "column-reverse",
    },
    containerDesktop: {
      height: "100vh",
      width: "calc(33.3vw + 1px)",
      background: "white",
      paddingRight: "8px",
      overflowY: "auto",
    },
    banner: {
      objectFit: "cover",
      height: "100%",
      width: "100%",
    },
    info: {
      background: "white",
    },
    imageContainer: {
      paddingTop: "56px",
      position: "relative",
    },
    imageContainerDesktop: {
      position: "relative",
      width: "100%",
    },
    toolbar: {
      height: "56px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      zIndex: 9999,
      paddingLeft: "0",
    },
    title: {
      margin: "0 auto",
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    mobileTitle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
    icon: {
      marginLeft: "10px",
    },
    image: {
      objectFit: "cover",
      height: "100%",
    },
    connectorInfo: {
      marginTop: "12px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      flexDirection: "row",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
      color: "#487c71",
    },
    connectorContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    accordion: {
      border: "none",
      marginTop: "10px",
      marginBottom: "16px",
      boxShadow: "none",
    },
    accordionSummary: {
      padding: "0",
    },
    evDescription: {
      color: "#E95225",
      marginTop: "16px",
    },
    shareButton: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      color: "#487C71",
      transform: "translate(50%)",
    },
    facility: {
      marginTop: "16px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "20px",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    facilityItem: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    facilityText: {
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "#487C71",
    },
  })
);
interface Props {
  poi: PointOfInterests | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose: () => void;
}

const PoiDetails: React.VFC<Props> = ({ poi, onClose }) => {
  if (!poi) return <></>;
  const classes = useStyles();
  const isMobile = useDevice();
  const googleMapsLink = createGoogleMapsLink(
    poi.coordinates[0],
    poi.coordinates[1]
  );
  const facilities = poi?.facilities;
  const imageUrl = poi?.image_url;
  const currentURL = window.location.href;
  const [isShareOpened, setShareOpen] = useState<boolean>(false);

  if (!isMobile)
    return (
      <Box className={classes.containerDesktop}>
        <Box className={classes.info} pt={10}>
          <Box px={5}>
            <Button variant="text" onClick={onClose}>
              <CloseIcon /> <Typography variant="subtitle2">Close</Typography>
            </Button>
            <Box
              pt={3}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="h5" color="primary">
                {poi.name}
              </Typography>
              <a
                target="_blank"
                href={googleMapsLink}
                rel="noopener noreferrer"
              >
                <img src={routeDesktopIcon} className={classes.icon} />
              </a>
            </Box>
          </Box>
          <div className={classes.imageContainerDesktop}>
            <img
              src={imageUrl || "https://dummyimage.com/255x140/fff/aaa"}
              className={classes.banner}
              width="100%"
              height="100%"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "https://dummyimage.com/255x140/fff/aaa";
              }}
            />
          </div>
          <Box px={5} pb="16px">
            <Box
              pt={1}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" color="primary">
                {poi.address}
              </Typography>
              <Box
                className={classes.shareButton}
                onClick={() => {
                  setShareOpen(true);
                }}
              >
                <img src={ShareIcon} width="16px" />
                Share
              </Box>
            </Box>
            {poi.description && (
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}
                  aria-controls="description-content"
                  id="description-header"
                  className={classes.accordionSummary}
                >
                  <Box>
                    <Typography
                      color="primary"
                      variant="subtitle1"
                      className={classes.routeItem}
                    >
                      Description
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box pt={2}>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{ __html: poi?.description }}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
            {!poi.connectors?.length || (
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionSummary}
                >
                  <Box className={classes.routeMapIcon}>
                    <img src={chargeIcon} />
                  </Box>
                  <Box>
                    <Typography
                      color="primary"
                      variant="subtitle1"
                      className={classes.routeItem}
                    >
                      EV Charge
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box width="100%" pl="16px">
                    <Typography color="primary">Connector Type</Typography>
                    <POIConnectors connectors={poi.connectors} />
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
            <Divider className={classes.routeDivider} />
            <Box>
              {poi.website && (
                <PoiContact
                  url={poi.website}
                  name={"Website"}
                  icon={websiteIcon}
                />
              )}
              {poi.contact_number && (
                <PoiContact name={poi.contact_number} icon={phoneIcon} />
              )}
              {poi.email && <PoiContact name={poi.email} icon={emailIcon} />}
            </Box>
            {!facilities?.length || (
              <>
                <Box mt="12px">
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    className={classes.routeItem}
                  >
                    <Link style={{ textDecoration: "none" }}>
                      Popular facilities
                    </Link>
                  </Typography>
                </Box>
                <Box className={classes.facility}>
                  {facilities?.map((facility) => {
                    return (
                      <Box className={classes.facilityItem} key={facility}>
                        <img src={FacilitiesIcon[facility]} />
                        <Box className={classes.facilityText}>{facility}</Box>
                      </Box>
                    );
                  })}
                </Box>
              </>
            )}
            {!poi.connectors?.length || (
              <Box className={classes.evDescription}>
                EV chargers provided by accommodation facilities are intended
                for guest use. Contact the accommodation provider to find out
                more information.
              </Box>
            )}
          </Box>
        </Box>
        <ShareLinkDialog
          isOpen={isShareOpened}
          onClose={() => setShareOpen(false)}
          url={currentURL}
        />
      </Box>
    );
  return (
    <Box className={classes.mobileWrapper}>
      <MaterialAppBar color="primary" position="fixed">
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={onClose}
            style={{ marginRight: "16px" }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Typography variant="h6" className={classes.mobileTitle} gutterBottom>
            {poi.type}
          </Typography>
          <a target="_blank" href={googleMapsLink} rel="noopener noreferrer">
            <RouteWhiteIcon />
          </a>
        </Toolbar>
      </MaterialAppBar>
      <Box className={classes.containerMobile}>
        <Box className={classes.info} px="16px" mb="12px">
          <Box>
            <Typography
              color="primary"
              variant="h6"
              style={{ margin: "24px 0 16px" }}
            >
              {poi.name}
            </Typography>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1" color="primary">
                {poi.address}
              </Typography>
              <Box
                mr="16px"
                className={classes.shareButton}
                onClick={() => {
                  setShareOpen(true);
                }}
              >
                <img src={ShareIcon} width="16px" />
                Share
              </Box>
            </Box>
            {poi.description && (
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="primary" />}
                  aria-controls="description-content"
                  id="description-header"
                  className={classes.accordionSummary}
                >
                  <Box>
                    <Typography
                      color="primary"
                      variant="subtitle1"
                      className={classes.routeItem}
                    >
                      Description
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box width="100%">
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{ __html: poi?.description }}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
          {!poi.connectors?.length || (
            <Accordion className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionSummary}
              >
                <Box className={classes.routeMapIcon}>
                  <img src={chargeIcon} />
                </Box>
                <Box>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    className={classes.routeItem}
                  >
                    EV Charge
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box width="100%" pl="16px">
                  <Typography color="primary">Connector Type</Typography>
                  <POIConnectors connectors={poi.connectors} />
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
          <Box>
            <Box>
              {poi.website && (
                <PoiContact
                  url={poi.website}
                  name={"Website"}
                  icon={websiteIcon}
                />
              )}
              {poi.contact_number && (
                <PoiContact name={poi.contact_number} icon={phoneIcon} />
              )}
              {poi.email && <PoiContact name={poi.email} icon={emailIcon} />}
            </Box>
            {!facilities?.length || (
              <>
                <Box mt="12px">
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    className={classes.routeItem}
                  >
                    <Link style={{ textDecoration: "none" }}>
                      Popular facilities
                    </Link>
                  </Typography>
                </Box>
                <Box
                  className={classes.facility}
                  style={{ gridTemplateColumns: "1fr" }}
                >
                  {facilities?.map((facility) => {
                    return (
                      <Box className={classes.facilityItem} key={facility}>
                        <img src={FacilitiesIcon[facility]} />
                        <Box className={classes.facilityText}>{facility}</Box>
                      </Box>
                    );
                  })}
                </Box>
              </>
            )}
          </Box>
          {poi.connectors && (
            <Box className={classes.evDescription}>
              EV chargers provided by accommodation facilities are intended for
              guest use. Contact the accommodation provider to find out more
              information.
            </Box>
          )}
        </Box>
        <div className={classes.imageContainer}>
          <img
            src={imageUrl || "https://dummyimage.com/255x140/fff/aaa"}
            className={classes.banner}
            width="100%"
            height="100%"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "https://dummyimage.com/255x140/fff/aaa";
            }}
          />
        </div>
      </Box>
      <ShareLinkDialog
        isOpen={isShareOpened}
        onClose={() => setShareOpen(false)}
        url={currentURL}
      />
    </Box>
  );
};

export default PoiDetails;
