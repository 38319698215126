import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Button from "../components/Button";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { useAuth } from "../contexts/AuthContext";
import config from "../config";
import { ReactComponent as PencilIcon } from "../assets/icons/pencil.svg";
import BottomNavBar from "../components/navbars/BottomNavBar";
import bannerImage from "../assets/images/settings.png";
import logo from "../assets/icons/main-logo.png";
import AppNavBar from "../components/navbars/AppNavBar";
import AppBar from "../components/navbars/AppBar";
import { useDevice } from "../hooks/useDevice";
import { useState } from "react";
import Explain from "./Explain";
import Auth from "../components/Auth";

const useStyles = makeStyles({
  signInInfo: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  containerDesktop: {
    paddingTop: "56px",
    height: "100vh",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr",
  },
  banner: {
    objectFit: "cover",
    height: "calc(100vh - 56px)",
    width: "100%",
  },
  topWrapper: {
    width: "clamp(300px, 55%, 720px)",
    margin: "auto",
  },
  info: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    flexDirection: "column",
    overflowY: "auto",
  },
  topPane: {
    flexDirection: "column",
    display: "flex",
    overflowY: "auto",
  },
  bottomPane: {
    gridColumn: "2",
    gridRow: "1",
  },
  logo: {
    position: "absolute",
    maxWidth: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
  },
  imageContainer: {
    position: "relative",
  },
  button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: 0,
    padding: 0,
  },
});
interface Props {
  setTooltips?: (boolean) => void;
}

const Settings: React.VFC<Props> = ({ setTooltips }) => {
  const classes = useStyles();
  const history = useHistory();
  const { currentUser, logOut } = useAuth();
  const isMobile = useDevice();

  return (
    <Box>
      <Box>
        {currentUser && currentUser?.isAnonymous && (
          <>
            <Box mt={3}>
              <Typography variant="h6" className={classes.signInInfo}>
                Sign in to save your preferences and to use the navigation
                feature
              </Typography>
            </Box>
            <Auth />
          </>
        )}
      </Box>
      <Box mt={3} mb={2} className={classes.flexContainer}>
        <Button
          onClick={() => history.push("/faqs")}
          className={classes.button}
        >
          <Typography variant="h6" style={{ margin: 0 }}>
            FAQs
          </Typography>
          <ArrowForwardIosIcon fontSize="small" />
        </Button>
      </Box>
      <Divider />
      {isMobile && (
        <>
          <Box mt={3} mb={2} className={classes.flexContainer}>
            <Button
              onClick={() => setTooltips && setTooltips(true)}
              className={classes.button}
            >
              <Typography variant="h6" style={{ margin: 0 }}>
                How to use the app
              </Typography>
              <ArrowForwardIosIcon fontSize="small" />
            </Button>
          </Box>
          <Divider />
        </>
      )}
      <Box mt={3} mb={2} className={classes.flexContainer}>
        <Button
          className={classes.button}
          onClick={() => history.push("/terms-and-condition")}
        >
          <Typography variant="h6" style={{ margin: 0 }}>
            Terms and Conditions
          </Typography>
          <ArrowForwardIosIcon fontSize="small" />
        </Button>
      </Box>
      <Divider />
      <Box mt={3} mb={2} className={classes.flexContainer}>
        <Button
          className={classes.button}
          onClick={() => history.push("/privacy-policy")}
        >
          <Typography variant="h6" style={{ margin: 0 }}>
            Privacy Policy
          </Typography>
          <ArrowForwardIosIcon fontSize="small" />
        </Button>
      </Box>
      <Divider />
      {currentUser && !currentUser?.isAnonymous && (
        <>
          <Box mt={6}>
            <Box className={classes.flexContainer}>
              <Typography variant="h5" style={{ margin: 0 }}>
                Sign-in details
              </Typography>
              <PencilIcon />
            </Box>
            <Box mt={3} className={classes.flexContainer}>
              <Typography variant="body1" style={{ margin: 0 }}>
                Email
              </Typography>
              <Typography variant="body1" style={{ margin: 0 }}>
                {currentUser.email}
              </Typography>
            </Box>
            <Divider />
            <Box>
              <Box mt={2}>
                <Button
                  style={{ margin: 0 }}
                  color="primary"
                  variant="outlined"
                  fullWidth={true}
                  onClick={() => logOut()}
                >
                  Log out
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const WrappedSettings = (): JSX.Element => {
  const isMobile = useDevice();
  const classes = useStyles();
  const [showTooltips, setTooltips] = useState<boolean>(false);

  if (showTooltips && isMobile)
    return <Explain onClose={() => setTooltips(false)} />;
  if (isMobile) {
    return (
      <>
        <AppBar backButton={false} title={"Settings"} />
        <Box mx={2} pb="56px">
          <Settings setTooltips={setTooltips} />
        </Box>
        <BottomNavBar activeTabIndex={2} items={config.bottomNavItems} />
      </>
    );
  }

  return (
    <>
      <AppNavBar activeTabIndex={2} items={config.bottomNavItems} />
      <Box className={classes.containerDesktop}>
        <Box className={classes.topPane}>
          <Box className={classes.topWrapper}>
            <Typography variant="h4">Settings</Typography>
            <Box className={classes.info}>
              <Settings />
            </Box>
          </Box>
        </Box>
        <Box className={classes.bottomPane}>
          <div className={classes.imageContainer}>
            <img
              src={bannerImage}
              className={classes.banner}
              width="100%"
              height="100%"
              alt=""
            />
            <img src={logo} className={classes.logo} />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default WrappedSettings;
