import bikeStorageIcon from "../assets/icons/facilities/bike-storage.svg";
import disabledAccessIcon from "../assets/icons/facilities/disabled-access.svg";
import parkingIcon from "../assets/icons/facilities/parking.svg";
import wifiIcon from "../assets/icons/facilities/wifi.svg";
import petFriendlyIcon from "../assets/icons/facilities/pet-friendly.svg";

export const FacilitiesIcon = {
  "Wi-Fi": wifiIcon,
  Parking: parkingIcon,
  "Pet Friendly": petFriendlyIcon,
  "Disabled Access": disabledAccessIcon,
  "Bike Storage": bikeStorageIcon,
};
