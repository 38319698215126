import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import LinkIcon from "@material-ui/icons/Link";
import { default as MaterialAppBar } from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";

import EVChargePointPlaceholder from "../assets/images/ev-charge-point-placeholder.png";
import { ReactComponent as RouteWhiteIcon } from "../assets/icons/RouteWhite.svg";
import { ReactComponent as RouteIcon } from "../assets/icons/Route.svg";
import { ReactComponent as WalksIcon } from "../assets/icons/walks.svg";
import { useDevice } from "../hooks/useDevice";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { PointOfInterests } from "../types/PointsOfInterests";
import { createGoogleMapsLink } from "../shared/helpers/poiLinksCreator";
import EVChargeConnectors from "./connectors/EVChargeConnectors.ts";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    routeContainer: {
      display: "flex",
    },
    routeMapIcon: {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(4),
    },
    routeContent: {
      flex: 4,
    },
    routeItem: {
      display: "flex",
      justifyContent: "space-between",
    },
    routeDivider: {
      marginTop: theme.spacing(2),
      marginRight: -theme.spacing(2),
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    containerMobile: {
      display: "flex",
      flexDirection: "column-reverse",
    },
    containerDesktop: {
      height: "100vh",
      width: "calc(33.3vw + 1px)",
      background: "white",
      overflowY: "auto",
      margin: "0",
    },
    banner: {
      objectFit: "cover",
      height: "100%",
      width: "100%",
    },
    info: {
      background: "white",
    },
    imageContainer: {
      position: "relative",
    },
    logo: {
      position: "absolute",
      maxWidth: "50%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
    },
    backButton: {
      position: "absolute",
      top: "-50px",
      left: "max(50px, 20%)",
    },
    toolbar: {
      height: "56px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    title: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "50%",
    },
    connectorInfo: {
      marginTop: "12px",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      flexDirection: "row",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
      color: "#487c71",
    },
    connectorContainer: {
      display: "flex",
    },
    chargePointTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    mobileWrapper: {
      position: "fixed",
      width: "100vw",
      height: "calc(var(--vh, vh) * 100 - 56px)",
      marginBottom: "56px",
      background: "white",
      overflowY: "auto",
    },
  })
);
interface Props {
  poi: PointOfInterests | null;
  onClose: () => void;
}

const EVChargePointInfo: React.VFC<Props> = ({ poi, onClose }) => {
  const classes = useStyles();
  const isMobile = useDevice();
  if (!poi) return <></>;
  const googleMapsLink = createGoogleMapsLink(
    poi.coordinates[0],
    poi.coordinates[1]
  );

  if (!isMobile)
    return (
      <Box>
        <Box className={classes.containerDesktop}>
          <Box className={classes.info} px={5} pt={10}>
            <Button variant="text" onClick={onClose}>
              <CloseIcon /> <Typography variant="subtitle2">Close</Typography>
            </Button>
            <Box pt={3} className={classes.chargePointTitle}>
              <Typography variant="h6" color="primary">
                EV Charge
              </Typography>
              <a
                target="_blank"
                href={googleMapsLink}
                rel="noopener noreferrer"
              >
                <RouteIcon />
              </a>
            </Box>
            <div className={classes.imageContainer}>
              <img
                src={EVChargePointPlaceholder}
                className={classes.banner}
                width="100%"
                height="100%"
              />
            </div>
            <Box pt={3}>
              <Box pt={3}>
                <Typography color="primary" variant="body1">
                  {poi?.address}
                </Typography>
              </Box>
              <Box pt={3}>
                <Typography color="primary" variant="h6">
                  {poi?.connectors.length > 0 ? poi.connectors[0].network : ""}
                </Typography>
                {!poi.connectors.length || (
                  <EVChargeConnectors connectors={poi?.connectors} />
                )}
              </Box>
            </Box>

            <Box pt={3}>
              {!poi.poi_walks.length || (
                <Box className={classes.routeContainer} pb={3}>
                  <Box className={classes.routeMapIcon}>
                    <WalksIcon />
                  </Box>
                  <Box className={classes.routeContent}>
                    <Typography
                      color="primary"
                      variant="subtitle1"
                      className={classes.routeItem}
                    >
                      <Link
                        href={poi?.poi_walks[0]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Nearby walks
                      </Link>
                      <a
                        target="_blank"
                        href={poi?.poi_walks[0]}
                        rel="noopener noreferrer"
                      >
                        <LinkIcon color="primary" />
                      </a>
                    </Typography>
                    <Divider className={classes.routeDivider} />
                  </Box>
                </Box>
              )}
              {!poi?.poi_nearbys.length || (
                <Box className={classes.routeContainer} pb={3}>
                  <Box className={classes.routeMapIcon}>
                    <WalksIcon />
                  </Box>
                  <Box className={classes.routeContent}>
                    <Typography
                      color="primary"
                      variant="subtitle1"
                      className={classes.routeItem}
                    >
                      <Link
                        href={poi.poi_nearbys[0]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Places near you
                      </Link>
                      <a
                        target="_blank"
                        href={poi.poi_nearbys[0]}
                        rel="noopener noreferrer"
                      >
                        <LinkIcon color="primary" />
                      </a>
                    </Typography>
                    <Divider className={classes.routeDivider} />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  return (
    <Box className={classes.mobileWrapper}>
      <MaterialAppBar color="primary">
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={onClose}
            style={{ paddingLeft: "0" }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title} gutterBottom>
            EV Charge
          </Typography>
          <a target="_blank" href={googleMapsLink} rel="noopener noreferrer">
            <RouteWhiteIcon />
          </a>
        </Toolbar>
      </MaterialAppBar>
      <Box className={classes.containerMobile}>
        <Box className={classes.info} px={5} pt={2}>
          <Box pt={3}>
            <Typography color="primary" variant="h6">
              {poi?.name}
            </Typography>
            <Typography color="primary" variant="body1">
              {poi?.address}
            </Typography>
          </Box>
          <Box pt={3}>
            <Typography color="primary" variant="h6">
              {poi?.connectors.length > 0 ? poi.connectors[0].network : ""}
            </Typography>
            {!poi.connectors.length || (
              <EVChargeConnectors connectors={poi?.connectors} />
            )}
          </Box>
          <Box pt={3}>
            {poi?.poi_walks && (
              <Box className={classes.routeContainer} pb={3}>
                <Box className={classes.routeMapIcon}>
                  <WalksIcon />
                </Box>
                <Box className={classes.routeContent}>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    className={classes.routeItem}
                  >
                    <Link
                      href={poi?.poi_walks[0]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Nearby walks
                    </Link>
                    <a
                      target="_blank"
                      href={poi?.poi_walks[0]}
                      rel="noopener noreferrer"
                    >
                      <LinkIcon color="primary" />
                    </a>
                  </Typography>
                  <Divider className={classes.routeDivider} />
                </Box>
              </Box>
            )}
            {poi?.poi_nearbys && (
              <Box className={classes.routeContainer} pb={3}>
                <Box className={classes.routeMapIcon}>
                  <WalksIcon />
                </Box>
                <Box className={classes.routeContent}>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    className={classes.routeItem}
                  >
                    <Link
                      href={poi?.poi_nearbys[0]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Places near you
                    </Link>
                    <a
                      target="_blank"
                      href={poi?.poi_nearbys[0]}
                      rel="noopener noreferrer"
                    >
                      <LinkIcon color="primary" />
                    </a>
                  </Typography>
                  <Divider className={classes.routeDivider} />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <div className={classes.imageContainer}>
          <img
            src={EVChargePointPlaceholder}
            className={classes.banner}
            width="100%"
            height="100%"
          />
        </div>
      </Box>
    </Box>
  );
};

export default EVChargePointInfo;
