import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const NotFound = (): JSX.Element => {
  return (
    <Box px={2} py={3}>
      <Typography variant="h3">Not Found</Typography>
      <Typography variant="body1">
        The page you are looking for does not exist...
      </Typography>
    </Box>
  );
};

export default NotFound;
