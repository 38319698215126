import { Fragment, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AppBar from "../../components/navbars/AppBar";
import Box from "@material-ui/core/Box";
import config from "../../config";
import Map from "../../components/Map";
import AppNavBar from "../../components/navbars/AppNavBar";
import BottomNavBar from "../../components/navbars/BottomNavBar";
import FilterBar from "../../components/filterBar/FilterBar";
import { useDevice } from "../../hooks/useDevice";
import MapTooltips from "../../components/toolltips/MapTooltips";

const useStyles = makeStyles(() =>
  createStyles({
    desktopMap: {
      height: "100vh",
      width: "100wh",
    },
    mobileMap: {
      height: "calc(var(--vh, vh) * 100 - 56px)",
      width: "100wh",
      marginBottom: "56px",
    },
    wrapper: {
      position: "relative",
      height: "calc(100vh - 56px)",
      top: "0",
      boxSizing: "border-box",
    },
  })
);

const RouteMap = (): JSX.Element => {
  const classes = useStyles();
  const isMobile = useDevice();
  const [isFilterBarOpened, setFilterBar] = useState(false);

  return (
    <Fragment>
      {isMobile ? (
        <>
          <AppBar
            position="fixed"
            title="Navigation"
            openFilterBar={() => setFilterBar(true)}
            backButton
          />
          <FilterBar isOpen={isFilterBarOpened} setOpen={setFilterBar} />
        </>
      ) : (
        <AppNavBar activeTabIndex={0} items={config.bottomNavItems} />
      )}
      <Box className={classes.wrapper}>
        <div style={{ position: "relative" }}>
          <Map
            zoom={10}
            interactive={true}
            showRoute={false}
            className={clsx(isMobile ? classes.mobileMap : classes.desktopMap)}
          />
        </div>
      </Box>
      {!isMobile && (
        <MapTooltips
          style={{
            top: "140px",
            left: "18px",
            //transform: "translateX(-50%)",
          }}
        />
      )}
      {isMobile && (
        <BottomNavBar activeTabIndex={0} items={config.bottomNavItems} />
      )}
    </Fragment>
  );
};

export default RouteMap;
