import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebook-negative.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/twitter-negative.svg";
import { ReactComponent as WhatsappIcon } from "../assets/icons/whatsapp-negative.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/email-negative.svg";
import { ReactComponent as LinkIcon } from "../assets/icons/link-negative.svg";
import { ReactComponent as OkIcon } from "../assets/icons/elements/ok-icon.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles({
  dialog: {
    backdropFilter: "blur(1px)",
    background: "rgba(196, 196, 196, 0.15)",
    "& .MuiPaper-root": {
      background: "rgba(255, 255, 255, 0.85)",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "20px",
    },
  },
  dialogItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    margin: "0",
    padding: "20px",
    width: "223px",
    height: "72px",
    cursor: "pointer",
  },
  icon: {
    width: "32px",
    height: "32px",
  },
  closeIcon: {
    background: "rgba(0, 0, 0, 0.12)",
    width: "24px",
    height: "24px",
    color: "white",
    padding: "5px",
    position: "absolute",
    right: "11px",
    top: "11px",
    borderRadius: "20px",
    cursor: "pointer",
  },
  dialogText: {
    width: "100%",
    margin: "0",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    color: "#50555C",
    textAlign: "center",
  },
  dialogLink: {
    textAlign: "center",
    color: "#50555C",
    width: "100%",
    textDecoration: "none",
    "&:visited": {
      color: "#50555C",
    },
    "&:hover": {
      color: "#50555C",
    },
    "&:active": {
      color: "#50555C",
    },
  },
  copiedLinkContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    gap: "10px",
    position: "fixed",
    width: "166px",
    height: "44px",
    bottom: "calc(50vh - 235px)",
    right: "calc(50vw)",
    zIndex: 999999,
    transform: "translate(50%, 50%)",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "20px",
  },
  copiedLinkText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#487C71",
  },
});

interface Props {
  isOpen: boolean;
  onClose: () => void;
  url: string;
}

const ShareLinkDialog: React.VFC<Props> = ({ isOpen, onClose, url }) => {
  const classes = useStyles();
  const [isLinkCopied, setLinkCopied] = useState(false);
  const closeHandle = () => {
    onClose();
    setLinkCopied(false);
  };
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={closeHandle}
        className={classes.dialog}
        style={{ zIndex: 900 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          classes: {
            root: classes.dialog,
          },
        }}
      >
        {isLinkCopied && (
          <Box className={classes.copiedLinkContainer}>
            <OkIcon />
            <Box className={classes.copiedLinkText}>Link copied</Box>
          </Box>
        )}
        <DialogContent
          style={{
            width: "320px",
            height: "400px",
            display: "flex",
            padding: "10px 0",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "20px",
            overflow: "hidden",
          }}
        >
          <DialogActions>
            <CloseIcon onClick={closeHandle} className={classes.closeIcon} />
          </DialogActions>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogItem}
          >
            <FacebookIcon className={classes.icon} />
            <a
              className={classes.dialogLink}
              target="_blank"
              href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
              rel="noreferrer"
            >
              <Box className={classes.dialogText}>Facebook</Box>
            </a>
          </DialogContentText>
          <Divider style={{ height: "1px", width: "100%" }} />
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogItem}
          >
            <WhatsappIcon className={classes.icon} />
            <a
              className={classes.dialogLink}
              href={`https://api.whatsapp.com/send?text=${url}`}
              data-action="share/whatsapp/share"
            >
              <Box className={classes.dialogText}>Whatsapp</Box>
            </a>
          </DialogContentText>
          <Divider style={{ height: "1px", width: "100%" }} />
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogItem}
          >
            <TwitterIcon className={classes.icon} />
            <a
              className={classes.dialogLink}
              href="https://twitter.com/share?ref_src=twsrc%5Etfw"
              data-url={url}
              data-show-count="false"
            >
              <script
                async
                src="https://platform.twitter.com/widgets.js"
              ></script>
              <Box className={classes.dialogText}>Twitter</Box>
            </a>
          </DialogContentText>
          <Divider style={{ height: "1px", width: "100%" }} />
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogItem}
          >
            <EmailIcon className={classes.icon} />
            <a
              className={classes.dialogLink}
              href={`mailto:?subject=Electrek%20Explorer&body=${url}`}
              title="Share by Email"
            >
              <Box className={classes.dialogText}>Email</Box>
            </a>
          </DialogContentText>
          <Divider style={{ height: "1px", width: "100%" }} />
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogItem}
          >
            <LinkIcon className={classes.icon} />
            <Box
              onClick={() => {
                navigator.clipboard.writeText(url);
                setLinkCopied(true);
              }}
              className={classes.dialogText}
            >
              Copy Link
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ShareLinkDialog;
