/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Feature } from "geojson";
import { PointOfInterests } from "./../../../types/PointsOfInterests";
import { connector } from "../../../types/PointsOfInterests";
import { RewildingSiteTypes } from "../../../types/rewildingSite";

export const parseConnectors = (connectors): connector[] => {
  return connectors.map(
    ({ meta, chargeRate, ratedOutputKw, status, network }) => ({
      type: meta.type,
      chargeRate,
      ratedOutputKw: Number(ratedOutputKw),
      status,
      network,
    })
  );
};

export const getMarkerIcon = (
  type: string,
  connectors: connector[]
): string => {
  if (type === RewildingSiteTypes.EVChargePoint) {
    const slowConnectorsLength = connectors.reduce(
      (prev, current) => prev + Number(current.chargeRate === "slow"),
      0
    );
    if (slowConnectorsLength === 0) return "EV Charge Point Fast";
    else if (slowConnectorsLength === connectors.length)
      return "EV Charge Point Slow";
  }
  return type;
};

export const parseMapPoi = (point): Feature => ({
  type: "Feature",
  properties: {
    name: point.name,
    id: point.id,
    type: point.poi_type,
    connectors: point.connectors,
    markerIcon: point.markerIcon,
    imageUrl: point.image_resized?.url,
  },
  geometry: point.location.features[0].geometry,
});

export const parsePOI = (point): PointOfInterests => {
  return {
    name: point.name,
    type: point.poi_type,
    description: point.description,
    postcode: point.postcode,
    facilities: point.facilities.map((facility) => facility.description) || [],
    address: point.address,
    email: point.email,
    website: point.website,
    contact_number: point.contact_number,
    opening_times: point.opening_times,
    delivery: point.bike_delivery,
    ebike: point.ebike,
    poi_nearbys: point.poi_nearbys?.map(({ url }) => url) || [],
    poi_walks: point.poi_walks?.map(({ url }) => url) || [],
    coordinates: point.location.features[0].geometry.coordinates,
    guiding: point.bike_guiding,
    connectors: point.connectors,
    image_alt: point.image_resized?.alt,
    image_url: point.image_resized?.url,
  };
};
