import Drawer from "@material-ui/core/Drawer";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  ListItem,
  Toolbar,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton/IconButton";
import { RewildingSiteTypes } from "../../types/rewildingSite";
import { RewildingSiteIcons } from "../../types/rewildingSiteIcons";
import { FilterCategories } from "../../types/filterCategories";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { default as MaterialAppBar } from "@material-ui/core/AppBar";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import { useDevice } from "../../hooks/useDevice";
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { setFilters } from "../../store/slices/filter.slice";

const useStyles = makeStyles({
  filter: {
    position: "absolute",
    zIndex: 50,
    top: "80px",
    left: "120px",
  },
  hidden: {
    display: "none",
  },
  wrapper: {
    background: "rgba(255, 255, 255, 0.8)",
    position: "sticky",
    height: "calc(100vh - 256px)",
    overflowY: "auto",
    top: "0",
    left: "40px",
    boxSizing: "border-box",
  },
  shadow: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    background: "rgba(0, 53, 42, 0.4)",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  title: {
    textAlign: "center",
    marginBottom: "0",
  },
  filterItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  categoryItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
  },
  categoryFilters: {
    width: "100%",
  },
  buttonContainer: {
    width: "100%",
    padding: "16px 12px",
    position: "absolute",
    bottom: "0",
    background: "white",
  },
  desktopButtonContainer: {
    position: "absolute",
    bottom: "0",
    transform: "translateY(100%)",
    width: "100%",
    background: "rgba(255, 255, 255, 0.8)",
    padding: "16px 12px",
  },
  button: {
    marginBottom: "56px",
  },
  titleDesktop: {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.15px",
    margin: "9px 20px 0",
  },
  checkbox: {
    color: "#487C71",
  },
  checkLine: {
    background: "#487C71",
    height: "2px",
    width: "8px",
    position: "absolute",
    top: "28px",
    left: "22px",
    zIndex: 10,
  },
});
interface Props {
  isOpen: boolean;
  setOpen: (value) => void;
  height?: string;
}

const FilterBar: React.VFC<Props> = ({
  isOpen,
  setOpen,
  height = "calc(var(--vh, vh) * 100 - 108px)",
}) => {
  const classes = useStyles();
  const isMobile = useDevice();
  const filters = useSelector((state: RootState) => state.filter.filters);
  const dispatch = useDispatch();
  const defaultFilters = Object.values(RewildingSiteTypes);
  const [currentFilters, setCurrentFilters] = useState<string[]>(filters);

  const filterIsAdded = (filter: string) => {
    return currentFilters?.includes(filter);
  };

  useEffect(() => {
    if (filters) setCurrentFilters([...filters]);
  }, [isOpen]);

  const addFilter = (filter: string) => {
    if (!filterIsAdded(filter)) {
      currentFilters?.push(filter);
    }
    if (
      typeof setCurrentFilters !== "undefined" &&
      typeof currentFilters !== "undefined"
    ) {
      setCurrentFilters([...currentFilters]);
    }
  };

  const removeFilters = (filters: string[]) => {
    const newFilters = currentFilters?.filter(
      (elem) => !filters.includes(elem)
    );
    setCurrentFilters(newFilters);
  };

  const saveFilter = () => {
    dispatch(setFilters(currentFilters));
  };
  const clearAll = () => {
    setCurrentFilters([]);
  };
  const selectAll = () => {
    setCurrentFilters(defaultFilters);
  };

  if (!isMobile)
    return (
      <Box className={clsx(classes.filter, isOpen || classes.hidden)}>
        <div
          className={classes.shadow}
          onClick={() => {
            clearAll();
            setOpen(false);
          }}
        ></div>
        <Box className={classes.wrapper}>
          <Typography color="primary" className={classes.titleDesktop}>
            Filter
          </Typography>
          <List>
            {Object.keys(FilterCategories).map((category) => {
              const checked = FilterCategories[category].reduce(
                (prev, next) => {
                  return prev && currentFilters?.includes(next);
                },
                true
              );
              const onChange = () => {
                if (!checked)
                  FilterCategories[category].forEach((filter) => {
                    addFilter(filter);
                  });
                else removeFilters(FilterCategories[category]);
              };
              const displayCheckLine =
                !FilterCategories[category].reduce((prev, next) => {
                  return prev && !currentFilters?.includes(next);
                }, true) && !checked;

              return (
                <ListItem key={category} className={classes.categoryItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={onChange}
                        className={classes.checkbox}
                      />
                    }
                    label={category}
                  />
                  {displayCheckLine && <Box className={classes.checkLine} />}
                  <div className={classes.categoryFilters}>
                    {FilterCategories[category].map((filter) => (
                      <ListItem
                        button
                        key={filter}
                        className={classes.filterItem}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              className={classes.checkbox}
                              checked={filterIsAdded(filter)}
                              onChange={(e, checked) =>
                                checked
                                  ? addFilter(filter)
                                  : removeFilters([filter])
                              }
                            />
                          }
                          label={
                            <>
                              {filter}
                              {RewildingSiteTypes.EVChargePoint === filter && (
                                <>
                                  <span> (</span>
                                  <span style={{ color: "#22B3D2" }}>fast</span>
                                  <span>/</span>
                                  <span style={{ color: "#70C063" }}>slow</span>
                                  <span>)</span>
                                </>
                              )}
                            </>
                          }
                        />
                        <img src={RewildingSiteIcons[filter]?.filter} />
                      </ListItem>
                    ))}
                  </div>
                </ListItem>
              );
            })}
          </List>
        </Box>
        <Box className={classes.desktopButtonContainer}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            style={{ marginBottom: "16px" }}
            onClick={() => {
              saveFilter();
              setOpen(false);
            }}
          >
            Save
          </Button>
          <Button
            fullWidth
            onClick={selectAll}
            color="primary"
            variant="outlined"
            style={{ marginBottom: "16px" }}
          >
            Select all
          </Button>
          <Button
            fullWidth
            onClick={clearAll}
            color="primary"
            variant="outlined"
          >
            Clear all
          </Button>
        </Box>
      </Box>
    );

  return (
    <div>
      <Drawer
        hideBackdrop={true}
        anchor="right"
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
      >
        <MaterialAppBar color="primary">
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={() => {
                setOpen(false);
                setCurrentFilters(filters);
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" className={classes.title}>
              Filter
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => {
                saveFilter();
                setOpen(false);
              }}
            >
              <DoneIcon fontSize="small" />
            </IconButton>
          </Toolbar>
        </MaterialAppBar>
        <Box
          style={{
            position: "relative",
            width: "100vw",
            marginTop: "56px",
          }}
        >
          <List
            style={{
              overflowY: "auto",
              height: height,
              paddingBottom: "108px",
            }}
          >
            {Object.keys(FilterCategories).map((category) => {
              const checked = FilterCategories[category].reduce(
                (prev, next) => {
                  return prev && currentFilters?.includes(next);
                },
                true
              );
              const onChange = () => {
                if (!checked)
                  FilterCategories[category].forEach((filter) => {
                    addFilter(filter);
                  });
                else removeFilters(FilterCategories[category]);
              };
              const displayCheckLine =
                !FilterCategories[category].reduce((prev, next) => {
                  return prev && !currentFilters?.includes(next);
                }, true) && !checked;

              return (
                <ListItem key={category} className={classes.categoryItem}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={checked}
                        onChange={onChange}
                        className={classes.checkbox}
                      />
                    }
                    label={category}
                  />
                  {displayCheckLine && <Box className={classes.checkLine} />}
                  <div className={classes.categoryFilters}>
                    {FilterCategories[category].map((filter) => (
                      <ListItem
                        button
                        key={filter}
                        className={classes.filterItem}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              className={classes.checkbox}
                              checked={filterIsAdded(filter)}
                              onChange={(e, checked) =>
                                checked
                                  ? addFilter(filter)
                                  : removeFilters([filter])
                              }
                            />
                          }
                          label={
                            <>
                              {filter}
                              {RewildingSiteTypes.EVChargePoint === filter && (
                                <>
                                  <span> (</span>
                                  <span style={{ color: "#22B3D2" }}>fast</span>
                                  <span>/</span>
                                  <span style={{ color: "#70C063" }}>slow</span>
                                  <span>)</span>
                                </>
                              )}
                            </>
                          }
                        />
                        <img src={RewildingSiteIcons[filter]?.filter} />
                      </ListItem>
                    ))}
                  </div>
                </ListItem>
              );
            })}
          </List>
          <Box className={classes.buttonContainer}>
            <Button
              fullWidth
              onClick={selectAll}
              color="primary"
              variant="outlined"
              style={{ marginBottom: "16px" }}
            >
              Select all
            </Button>
            <Button
              fullWidth
              onClick={clearAll}
              color="primary"
              variant="outlined"
            >
              Clear all
            </Button>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
};

export default FilterBar;
