import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "../../components/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import attentionIcon from "../../assets/icons/elements/attention.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import accountImage from "../../assets/images/account-page-min.jpeg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ReactComponent as GoogleIcon } from "../../assets/icons/google-negative.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook-negative.svg";
import Link from "@material-ui/core/Link";

import { useAuth } from "../../contexts/AuthContext";
import DeviceWrapper from "../../components/containers/DeviceContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: theme.spacing(3),
      "& input": {
        padding: "16px",
        "&::placeholder": {
          color: "#000000",
          opacity: 100,
        },
      },
    },
    errorContainer: {
      marginTop: theme.spacing(3),
      position: "relative",
    },
    image: {
      position: "absolute",
    },
    error: {
      textAlign: "center",
      marginLeft: "34px",
      color: "#E95225",
      fontSize: "16px",
      lineHeight: "24px",
    },
    field: {
      marginTop: theme.spacing(3),
      border: "2px solid #E95225",
      borderRadius: "2px",
      "& input": {
        padding: "16px",
        "&::placeholder": {
          color: "#000000",
          opacity: 100,
        },
      },
      "& .MuiInputBase-root": {
        overflow: "hidden",
        borderRadius: 4,
        "& input": {
          padding: "16px",
          "&::placeholder": {
            color: "#000000",
            opacity: 100,
          },
        },
      },
    },
  })
);

interface State {
  email: string;
  password: string;
  showPassword: boolean;
}

const SignIn = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = useState<State>({
    email: "",
    password: "",
    showPassword: false,
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openFacebookAlert, setOpenFacebookAlert] = useState<boolean>(false);
  const [openGoogleAlert, setOpenGoogleAlert] = useState<boolean>(false);
  const { signInWithEmail, signInWithFacebook, signInWithGoogle } = useAuth();
  const errorMessages = {
    "auth/wrong-password":
      "Invalid email address and password combination, please try again.",
    "auth/invalid-email": "Please check your email address.",
    "auth/user-not-found": "Please check your email address.",
    "auth/too-many-requests":
      "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
  };
  const handleInputChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickSignInWithEmail = async () => {
    try {
      setLoading(true);
      await signInWithEmail(values.email, values.password);
      history.push("/explore");
      setError(null);
    } catch (error) {
      setError(error.code);
    }
    setLoading(false);
  };

  const handleClickSignInWithFacebook = async () => {
    try {
      setLoading(true);
      await signInWithFacebook();
      history.push("/explore");
    } catch (error) {
      setOpenFacebookAlert(true);
    }
    setLoading(false);
  };
  const handleClickSignInWithGoogle = async () => {
    try {
      setLoading(true);
      await signInWithGoogle();
      history.push("/explore");
    } catch (error) {
      setOpenGoogleAlert(true);
    }
    setLoading(false);
  };

  const handleAlertClose = () => setOpenAlert(false);
  return (
    <Fragment>
      <Box>
        <Typography variant="h5">Sign in</Typography>
        <Box mb={3}>
          <Typography variant="body1">
            Sign in to save your preferences and to use the navigation feature
          </Typography>
        </Box>
        <TextField
          color={"secondary"}
          error={!!error}
          className={error ? classes.field : classes.margin}
          id="email"
          placeholder="Email address"
          variant="filled"
          fullWidth={true}
          onChange={handleInputChange("email")}
          InputProps={{
            disableUnderline: true,
          }}
        />
        {error && error !== "auth/wrong-password" && (
          <Box className={classes.errorContainer}>
            <img src={attentionIcon} className={classes.image} />
            <span className={classes.error}>{errorMessages[error]}</span>
          </Box>
        )}
        <FormControl
          className={
            error === "auth/wrong-password" ? classes.field : classes.margin
          }
          variant="filled"
          fullWidth={true}
        >
          <FilledInput
            error={error === "auth/wrong-password"}
            id="filled-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            fullWidth={false}
            placeholder="Password"
            onChange={handleInputChange("password")}
            disableUnderline={true}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {error === "auth/wrong-password" && (
          <Box className={classes.errorContainer}>
            <img src={attentionIcon} className={classes.image} />
            <span className={classes.error}>{errorMessages[error]}</span>
          </Box>
        )}
        <Box mt="24px">
          <Link color="primary" href="/reset">
            Forgot your password?
          </Link>
        </Box>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={handleClickSignInWithEmail}
          >
            Sign in
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="outlined"
            fullWidth={true}
            onClick={handleClickSignInWithFacebook}
          >
            Sign in with <FacebookIcon style={{ marginLeft: "8px" }} />
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="outlined"
            fullWidth={true}
            onClick={handleClickSignInWithGoogle}
          >
            Sign in with <GoogleIcon style={{ marginLeft: "8px" }} />
          </Button>
        </Box>
        <Box mt={3} textAlign={"center"}>
          <Typography variant="subtitle1">
            Don't have an account?&nbsp;
            <Link
              color="primary"
              style={{ textDecoration: "none" }}
              href="/sign-up"
            >
              Sign up
            </Link>
          </Typography>
        </Box>
      </Box>
      <Dialog
        open={openAlert}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Failed to sign in</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The email or password is invalid. Please correct them to sign in.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertClose} color="primary" disabled={loading}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openFacebookAlert}
        onClose={() => setOpenFacebookAlert(false)}
      >
        <DialogTitle>Failed to sign in</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Failed to login using Facebook
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFacebookAlert(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openGoogleAlert} onClose={() => setOpenGoogleAlert(false)}>
        <DialogTitle>Failed to sign in</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Failed to login using Google
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenGoogleAlert(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
const SignInContainer = (): JSX.Element => {
  return (
    <DeviceWrapper bannerImage={accountImage} backButtonShowed={true}>
      <SignIn />
    </DeviceWrapper>
  );
};
export default SignInContainer;
