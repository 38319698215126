import {
  parseRoute,
  parseRouteInfo,
} from "./../shared/helpers/parsers/parseRoutes";
import { Route, RouteInfo } from "../types/routeType";
import { cmsService } from "./CMSservice";
const store = require("store/dist/store.modern");

class RouteService {
  static async getRoutesInfo(): Promise<Array<RouteInfo>> {
    const routes = await cmsService.getRoutesInfo();
    return routes.map(parseRouteInfo);
  }
  static async getRouteById(id: number): Promise<Route> {
    const storageRoute = store.get(`${id}-line`);
    if (storageRoute) return await Promise.resolve(storageRoute);
    const route = await cmsService.getRouteById(id);
    return parseRoute(route);
  }
}
export { RouteService };
