import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "../../contexts/LocationContext";
import Typography from "@material-ui/core/Typography";
import Button from "../../components/Button";
import Box from "@material-ui/core/Box";

import locationAccessImage from "../../assets/images/location-access-page.png";
import DeviceWrapper from "../../components/containers/DeviceContainer";

const LocationAccess = (): JSX.Element => {
  const history = useHistory();
  const { watchPosition } = useLocation();

  const accessLocation = () => {
    watchPosition();
    history.goBack();
  };

  return (
    <Fragment>
      <Box>
        <Typography variant="h5">Allow location access</Typography>
        <Typography variant="body1">
          We’ll need to access your location to download route maps for offline
          use.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          fullWidth={true}
          onClick={accessLocation}
        >
          Access my location
        </Button>
        <Button
          color="primary"
          fullWidth={true}
          onClick={() => history.goBack()}
        >
          No thanks
        </Button>
      </Box>
    </Fragment>
  );
};
const LocationAccessContainer = (): JSX.Element => {
  return (
    <DeviceWrapper bannerImage={locationAccessImage}>
      <LocationAccess />
    </DeviceWrapper>
  );
};
export default LocationAccessContainer;
