import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import StorageService from "../../services/storageService";
import { useState } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    tooltipsContainer: {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100vw",
      zIndex: 9000,
      height: "100vh",
      background: "rgba(0, 53, 42, 0.4)",
    },
    tooltips: {
      textAlign: "center",
      position: "absolute",
      background: "white",
      display: "flex",
      alignItems: "center",
      maxWidth: "310px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
      opacity: 0.8,
      zIndex: 9999,
      padding: "10px 8px",
    },
    tooltipsArrow: {
      position: "absolute",
      top: "0px",
      zIndex: -1,
      left: "16px",
      height: "20px",
      width: "20px",
      background: "white",
      transform: "translateY(-50%) rotate(45deg)",
    },
    close: {
      marginLeft: "5px",
      cursor: "pointer",
      width: "16x",
      height: "16px",
    },
  })
);
interface Props {
  style?: React.CSSProperties | undefined;
}

const MapTooltips: React.VFC<Props> = ({ style }) => {
  const tipsPages = StorageService.getTips();
  const [currentTip, setCurrentTip] = useState(0);
  const classes = useStyles();

  const tooltips = [
    {
      text: "Filter (choose your preferences)",
    },
  ];

  const onClose = () => {
    if (tooltips.length === currentTip + 1)
      StorageService.setTips(tipsPages.filter((tip) => tip !== "Map"));
    setCurrentTip((prev) => prev + 1);
  };
  if (tooltips.length <= currentTip || !tipsPages.includes("Map")) return <></>;
  return (
    <>
      <div className={classes.tooltipsContainer} onClick={onClose} />
      <div className={classes.tooltips} style={style}>
        <div>{tooltips[currentTip].text}</div>
        <CloseIcon onClick={onClose} className={classes.close} />
        <div className={classes.tooltipsArrow}></div>
      </div>
    </>
  );
};

export default MapTooltips;
