import React from "react";
import clsx from "clsx";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { default as MaterialButton } from "@material-ui/core/Button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: theme.spacing(3),
    },
  })
);

interface Props {
  style?: React.CSSProperties | undefined;
  className?: string | undefined;
  color?: "inherit" | "primary" | "secondary" | "default" | undefined;
  disabled?: boolean;
  variant?: "text" | "outlined" | "contained" | undefined;
  fullWidth?: boolean;
  children: string | React.ReactNode;
  onClick?: () => void;
}

const Button: React.VFC<Props> = ({
  style,
  className,
  color,
  disabled,
  variant,
  fullWidth = false,
  children,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <MaterialButton
      style={style}
      className={clsx(classes.margin, className)}
      color={color}
      disabled={disabled}
      variant={variant}
      fullWidth={fullWidth}
      onClick={onClick}
    >
      {children}
    </MaterialButton>
  );
};

export default Button;
