import React, { Fragment, useState, useMemo } from "react";
import Button from "../../../components/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import accountImage from "../../../assets/images/account-page-min.jpeg";
import { useHistory, useLocation } from "react-router-dom";
import { auth } from "../../../firebase";
import FormControl from "@material-ui/core/FormControl";
import FilledInput from "@material-ui/core/FilledInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DesktopWrapper from "../../../components/containers/DesktopContainer";
import AppBar from "../../../components/navbars/AppBar";
import { useDevice } from "../../../hooks/useDevice";
const MIN_PASSWORD_LENGTH = 6;

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontFamily: "Poppins",
      fontSize: "24px",
    },
    subtitle: {
      fontFamily: "Poppins",
      fontSize: "16px",
      marginBottom: "10px",
    },
    hint: {
      marginTop: "10px",
      marginBottom: "24px",
    },
  })
);

interface State {
  email: string;
  password: string;
  showPassword: boolean;
  confirmedPassword: string;
  showConfirmedPassword: boolean;
}

const ResetPassword = (): JSX.Element => {
  const [values, setValues] = useState<State>({
    email: "",
    password: "",
    showPassword: false,
    confirmedPassword: "",
    showConfirmedPassword: false,
  });

  const [isPasswordReset, setPasswordReset] = useState<boolean>(false);
  const [showPasswordsError, setShowPasswordsError] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const handleAlertClose = () => setOpenAlert(false);

  const history = useHistory();
  const query = useQuery();
  const classes = useStyles();
  const handleInputChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = (passwordType) => () => {
    setValues({ ...values, [passwordType]: !values[passwordType] });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickPasswordReset = async () => {
    try {
      const actionCode = query.get("oobCode");
      await auth.confirmPasswordReset(actionCode || "", values.password);
      setPasswordReset(true);
    } catch (error) {
      setOpenAlert(true);
      console.error(error);
    }
  };
  const handleClickContinue = () => history.push("/explore");
  const handleClickSignIn = () => history.push("/sign-in");

  const passwordError = values.password.length < MIN_PASSWORD_LENGTH;
  const showPasswordError = values.password !== values.confirmedPassword;
  const handleBlur = () => {
    if (showPasswordError) setShowPasswordsError(true);
  };
  return (
    <>
      {isPasswordReset ? (
        <Box>
          <Typography variant="h5">Done ! Password reset</Typography>
          <Typography>
            Your password has been successfully reset. Click below to sign in
            and start exploring !
          </Typography>
          <Box mt={2}>
            <Button
              style={{ margin: 0 }}
              color="primary"
              variant="contained"
              fullWidth={true}
              onClick={handleClickContinue}
            >
              Continue
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              style={{ margin: 0 }}
              color="primary"
              fullWidth={true}
              onClick={handleClickSignIn}
            >
              Back to sign in
            </Button>
          </Box>
        </Box>
      ) : (
        <Fragment>
          <Box>
            <Typography variant="h5" className={classes.title}>
              Please reset your password{" "}
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>
              New password
            </Typography>
            <FormControl variant="filled" fullWidth={true}>
              <FilledInput
                id="filled-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                fullWidth={false}
                placeholder="Password"
                onChange={handleInputChange("password")}
                disableUnderline={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword("showPassword")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Typography className={classes.hint}>
              Must be at least <span style={{ color: "#E95225" }}>6</span>{" "}
              characters
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>
              Confirm new password
            </Typography>
            <FormControl variant="filled" fullWidth={true}>
              <FilledInput
                id="filled-adornment-password"
                type={values.showConfirmedPassword ? "text" : "password"}
                value={values.confirmedPassword}
                fullWidth={false}
                placeholder="Confirm password"
                onChange={handleInputChange("confirmedPassword")}
                onBlur={handleBlur}
                disableUnderline={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword("showConfirmedPassword")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmedPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {showPasswordsError && (
              <Typography className={classes.hint} style={{ color: "#E95225" }}>
                Passwords do not match
              </Typography>
            )}
            <Box mt={2}>
              <Button
                style={{ margin: 0 }}
                color="primary"
                variant="contained"
                disabled={passwordError || showPasswordError}
                fullWidth={true}
                onClick={handleClickPasswordReset}
              >
                Request password reset
              </Button>
            </Box>
            <Box mt={2}>
              <Button
                style={{ margin: 0 }}
                color="primary"
                fullWidth={true}
                onClick={handleClickSignIn}
              >
                Back to sign in
              </Button>
            </Box>
          </Box>
          <Dialog
            open={openAlert}
            onClose={handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Failed to reset password"}
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleAlertClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    </>
  );
};

const ResetPasswordContainer = (): JSX.Element => {
  const isMobile = useDevice();
  if (isMobile)
    return (
      <Fragment>
        <AppBar backButton={false} />
        <Box px={2} pt={3} pb={3}>
          <ResetPassword />
        </Box>
      </Fragment>
    );
  return (
    <DesktopWrapper bannerImage={accountImage} backButtonShowed={false}>
      <ResetPassword />
    </DesktopWrapper>
  );
};
export default ResetPasswordContainer;
