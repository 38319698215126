import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../types/app-thunk-type";
import { PointOfInterests } from "../../types/PointsOfInterests";
import { Feature } from "geojson";
import { POIsService } from "../../services/POIsService";

export interface POIs {
  poiFeatures: Feature[];
  currentPOI: PointOfInterests | null;
}

const initialState: POIs = {
  poiFeatures: [],
  currentPOI: null,
};

export const POISlice = createSlice({
  name: "POIfeatures",
  initialState,
  reducers: {
    setPoiFeatures: (state, action: PayloadAction<Feature[]>) => {
      return {
        ...state,
        poiFeatures: action.payload,
      };
    },
    setCurrentPoi: (state, action: PayloadAction<PointOfInterests>) => {
      return {
        ...state,
        currentPOI: action.payload,
      };
    },
  },
});

export const setPoiFeaturesAsync = (): AppThunk => async (dispatch) => {
  const POIs = await POIsService.getMapPoints();
  dispatch(POISlice.actions.setPoiFeatures(POIs));
};
export const setCurrentPoiAsync =
  (id: number): AppThunk =>
  async (dispatch) => {
    const POI = await POIsService.getPointInfo(id);
    dispatch(POISlice.actions.setCurrentPoi(POI));
  };

export default POISlice.reducer;
