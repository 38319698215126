/* eslint-disable @typescript-eslint/no-explicit-any */
import { Feature, FeatureCollection } from "geojson";

export type connector = any;

export enum travelMode {
  eBike = "eBike",
  eCar = "eCar",
}

export type RouteInfo = {
  id: number;
  name: string;
  distance: number;
  region: string;
  travel_mode: string;
  difficulty: string;
  surface: string | null;
  image_alt: string | null;
  image_url: string | null;
};

export type Route = {
  id: number;
  name: string;
  description: string | null;
  distance: number;
  region: string;
  travel_mode: string;
  difficulty: string;
  surface: string | null;
  start_location: Feature;
  parking_location: Feature;
  route_pois: Array<any>;
  line_path: FeatureCollection;
  bike_hire_nearby: boolean;
  bike_hire_booking_url: string;
  image_alt: string | null;
  image_url: string | null;
};
