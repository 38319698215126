import React, { useEffect, useState, useRef } from "react";
// @ts-ignore
import mapboxgl, { Map as Mapbox } from "!mapbox-gl";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import type { Feature, FeatureCollection } from "geojson";
import { RewildingSiteTypes } from "../types/rewildingSite";
import config from "../config";
import Box from "@material-ui/core/Box";
import FilterIcon from "@material-ui/icons/FilterList";
import FilterBar from "./filterBar/FilterBar";
import Fab from "@material-ui/core/Fab/Fab";
import PoiDetails from "./PoiDetails";
import { RewildingSiteIcons } from "../types/rewildingSiteIcons";
import flagIcon from "../assets/icons/map/flag.svg";

import { getStorageFileJSON } from "../shared/helpers/fileStorage";
import EVChargePointInfo from "./EVChargePointInfo";
import { useDevice } from "../hooks/useDevice";
import { regionTypes } from "../types/regions";
import FilterCarousel from "./filterBar/FilterCarousel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useHistory, useParams } from "react-router-dom";
import PopupBase from "./POIPopup";
import { setCurrentPoiAsync } from "../store/slices/poiFeatures.slice";
import { connector } from "../types/routeType";

mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;

const useStyles = makeStyles({
  mapContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  marker: {
    position: "absolute",
    top: "-14px",
    backgroundSize: "cover",
    width: "40px",
    height: "40px",
    cursor: "pointer",
  },
  flagMarker: {
    position: "absolute",
    top: "-14px",
    backgroundSize: "cover",
    width: "40px",
    height: "40px",
    cursor: "pointer",
  },
  logo: {
    position: "absolute",
    top: "16px",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "20px",
    maxWidth: "20px",
  },
  filterButton: {
    position: "absolute",
    cursor: "pointer",
    zIndex: 23,
    top: "0px",
    width: "40px",
    height: "40px",
    background: "rgba(255, 255, 255, 0.5)",
    borderRadius: "20px",
  },
});

interface Props {
  className?: string | undefined;
  interactive?: boolean;
  lineColor?: string;
  zoom?: number;
  showRoute?: boolean;
  setPOIShowed?: (boolean) => void;
}
interface PopupContent {
  name: string;
  type: string;
  imageUrl: string;
  connectors: connector[];
}

const Map: React.VFC<Props> = ({
  className,
  lineColor,
  interactive = true,
  zoom = 7,
  showRoute = true,
  setPOIShowed,
}) => {
  const classes = useStyles();
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<Mapbox | null>(null);
  const [isFeatureDetailsOpen, setFeatureDetailsOpen] = React.useState(false);
  const currentFeatureId = useRef(null);
  const [isFilterBarOpened, setFilterBar] = React.useState(false); // set what to be filtered out
  const [isMapAdded, setMapAdded] = useState<boolean>(false);
  const [popupCoordinates, setPopupCoordinates] = useState<Array<number>>([]);
  const [popupContent, setPopupContent] = useState<PopupContent | null>(null);
  const isMobile = useDevice();
  const history = useHistory();
  const { poiId } = useParams<{ poiId?: string }>();
  const dispatch = useDispatch();
  const openFeatureDetails = () => {
    setPOIShowed && setPOIShowed(true);
    setFeatureDetailsOpen(true);
  };

  useEffect(() => {
    const id = Number(poiId);
    if (id) {
      dispatch(setCurrentPoiAsync(id));
      openFeatureDetails();
    }
  }, [poiId]);

  const poiFeatures = useSelector(
    (state: RootState) => state.poiFeatures.poiFeatures
  );
  const currentPOI = useSelector(
    (state: RootState) => state.poiFeatures.currentPOI
  );
  const filter = useSelector((state: RootState) => state.filter.filters);
  const { region } = useSelector((state: RootState) => state.route);
  const routeFeatures = useSelector(
    (state: RootState) => state.route.currentRoute?.line_path
  );
  const updateRouteMarkers = (routeCoordinates) => {
    document
      .querySelectorAll(`.${classes.flagMarker}`)
      .forEach((el) => el.remove());
    if (routeCoordinates.length > 1) {
      [
        [routeCoordinates[0][0], routeCoordinates[0][1]],
        [
          routeCoordinates[routeCoordinates.length - 1][0],
          routeCoordinates[routeCoordinates.length - 1][1],
        ],
      ].map((routeCoordinate) => {
        const el = document.createElement("div");
        el.className = classes.flagMarker;
        el.style.backgroundImage = `url(${flagIcon})`;
        new mapboxgl.Marker(el)
          .setLngLat(routeCoordinate as mapboxgl.LngLatLike)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .addTo(map.current!);
      });
    }
  };

  const getRouteCoordinates = (
    routeFeatures?: FeatureCollection
  ): number[][] | null => {
    if (
      routeFeatures &&
      routeFeatures?.features[0].geometry.type === "MultiLineString"
    ) {
      return routeFeatures?.features[0].geometry.coordinates[0];
    } else return null;
  };

  const getCenterCoordinates = (routeCoordinates?: number[][]): number[] => {
    if (routeCoordinates) return routeCoordinates[0];
    else {
      const regionObj = Object.values(regionTypes).find(
        (elem) => elem.name === region
      );
      return regionObj?.center || [-4.18073, 54.97374];
    }
  };
  useEffect(() => {
    if (routeFeatures) {
      const routeCoordinates = getRouteCoordinates(routeFeatures);
      if (routeCoordinates) {
        map.current?.flyTo({
          center: routeCoordinates[0],
          essential: true,
        });
        if (map.current?.getSource("routeMain")) {
          map.current.setPaintProperty(
            "routeMain",
            "line-color",
            lineColor || "#E04303"
          );
          map.current.getSource("routeMain").setData(routeFeatures);
          updateRouteMarkers(routeCoordinates);
        }
      }
    }
  }, [routeFeatures]);

  const handlePOIclick = (e) => {
    const poiFeature = e.features[0];
    const id = poiFeature.properties.id;
    const routes = map.current.querySourceFeatures("routeMain", {
      sourceLayer: "routeMain",
    });
    const routeId = routes[0]?.properties?.id;
    if (routeId) history.push(`/route-details/${routeId}/poi-details/${id}`);
    else history.push(`/route-map/${id}`);
    if (currentFeatureId.current == poiFeature.properties.id) {
      openFeatureDetails();
    } else {
      currentFeatureId.current = poiFeature.properties.id;
    }
    const properties = poiFeature.properties;
    setPopupCoordinates(poiFeature.geometry.coordinates);
    setPopupContent({
      name: properties.name,
      type: properties.type,
      imageUrl: properties.imageUrl,
      connectors:
        properties.connectors && properties.connectors !== ""
          ? JSON.parse(properties.connectors)
          : [],
    });
  };
  const addImages = (map, images) => {
    const addImage = (map, id, url) => {
      return new Promise((resolve) => {
        const img = new Image(90, 100);
        img.src = url;
        img.onload = () => {
          map.addImage(id, img);
          resolve(img);
        };
      });
    };
    const promises = images.map((imageData) =>
      addImage(map, imageData.id, imageData.url)
    );
    return Promise.all(promises);
  };
  const mapUnload = async () => {
    if (!map.current.getSource("poi")) {
      try {
        const images = Object.keys(RewildingSiteIcons).map((key) => ({
          url: RewildingSiteIcons[key].poiBackground,
          id: key,
        }));
        await addImages(map.current, images);
        map.current.addSource("poi", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: filterPOI(poiFeatures),
          },
          cluster: false,
          clusterRadius: 20,
          clusterMaxZoom: 16,
        });
        map.current.addLayer({
          id: "unclustered-poi",
          type: "symbol",
          source: "poi",
          cluster: false,
          layout: {
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
            "icon-image": ["get", "markerIcon"],
            "icon-size": 0.48,
          },
          paint: {
            "icon-translate": [0, -16],
          },
        });
        map.current.addLayer({
          id: "clusters",
          type: "circle",
          source: "poi",
          paint: {
            "circle-radius": 0,
          },
        });
        map.current.on("click", "unclustered-poi", handlePOIclick);
        map.current.on("mousemove", "unclustered-poi", () => {
          map.current.getCanvasContainer().style.cursor = "pointer";
        });
        map.current.on("mouseout", "unclustered-poi", () => {
          map.current.getCanvasContainer().style.cursor = "grab";
        });
      } catch (error) {
        console.log(error.message);
      }
    }
  };
  const showRoad = () => {
    if (
      !map.current.getLayer("routeMain") ||
      !map.current.getSource("routeMain")
    ) {
      if (routeFeatures) {
        map.current.addSource("routeMain", {
          type: "geojson",
          data: routeFeatures,
        });
        map.current.addLayer(
          {
            id: "routeMain",
            type: "line",
            source: "routeMain",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": lineColor || "#E04303",
              "line-width": 6,
            },
          },
          "unclustered-poi"
        );
        const coordinates = getRouteCoordinates(routeFeatures);
        updateRouteMarkers(coordinates);
      }
    }
  };

  const filterPOI = (poiFeatures: Feature[]): Feature[] => {
    if (!poiFeatures) return [];
    return poiFeatures.filter(({ properties }) =>
      filter?.includes(properties?.type)
    );
  };

  useEffect(() => {
    const loadMap = async (): Promise<void> => {
      const routeCoordinates = getRouteCoordinates(routeFeatures);
      const center = getCenterCoordinates(routeCoordinates || undefined);
      let mapStyle = await getStorageFileJSON("outdoors-v11.json", true);
      // The user has requested offline access so we use the optimized tiles
      mapStyle = await getStorageFileJSON("outdoors-v11-optimized.json", true);
      if (mapContainer.current) {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: mapStyle,
          center: center,
          minZoom: 5,
          maxZoom: 20,
          zoom: zoom,
          interactive: interactive,
        });
        map.current.addControl(
          new mapboxgl.NavigationControl(),
          "bottom-right"
        );
        map.current.addControl(
          new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true,
            },
            trackUserLocation: true,
            showUserHeading: true,
          }),
          "bottom-right"
        );
        map.current.on("load", async () => {
          await mapUnload();
          setMapAdded(true);
        });
      }
    };
    if (!isMapAdded && (!showRoute || routeFeatures)) loadMap();
  }, [routeFeatures]);

  useEffect(() => {
    if (isMapAdded) showRoute && showRoad();
  }, [showRoad, routeFeatures]);

  useEffect(() => {
    if (filterPOI(poiFeatures) && isMapAdded) {
      map.current?.getSource("poi")?.setData({
        type: "FeatureCollection",
        features: filterPOI(poiFeatures),
      });
    }
  }, [filter, isMapAdded, poiFeatures]);

  return (
    <>
      <div
        ref={mapContainer}
        className={clsx(classes.mapContainer, className)}
      />
      <PopupBase
        map={map?.current}
        coordinates={popupCoordinates}
        popupContent={popupContent}
        handleClose={openFeatureDetails}
      />
      <div
        style={{
          position: "absolute",
          width: "100%",
          top: "80px",
          zIndex: 10,
        }}
      >
        {!isMobile && (
          <>
            <Fab
              style={{
                position: "absolute",
                marginLeft: "24px",
                width: "40px",
                height: "40px",
                zIndex: 11,
              }}
              color="secondary"
              aria-label="filter"
              onClick={() => setFilterBar(!isFilterBarOpened)}
            >
              <FilterIcon />
            </Fab>
          </>
        )}
        <FilterCarousel />
      </div>
      <FilterBar isOpen={isFilterBarOpened} setOpen={setFilterBar}></FilterBar>
      {isFeatureDetailsOpen && (
        <Box
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 800,
            width: "34vw",
          }}
        >
          {currentPOI?.type !== RewildingSiteTypes.EVChargePoint ? (
            <PoiDetails
              poi={currentPOI}
              onClose={() => {
                setPOIShowed && setPOIShowed(false);
                setFeatureDetailsOpen(false);
              }}
            />
          ) : (
            <EVChargePointInfo
              poi={currentPOI}
              onClose={() => {
                setPOIShowed && setPOIShowed(false);
                setFeatureDetailsOpen(false);
                currentFeatureId.current = null;
              }}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Map;
