import { createTheme } from "@material-ui/core/styles";
import PoppinsRegular from "./assets/fonts/Poppins-Regular.ttf";
import CabinVariable from "./assets/fonts/Cabin-Variable.ttf";

const appHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

window.addEventListener("resize", appHeight);
appHeight();

const poppins = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  src: `url(${PoppinsRegular}) format('truetype')`,
};

const cabin = {
  fontFamily: "Cabin",
  fontStyle: "normal",
  src: `url(${CabinVariable}) format('truetype')`,
};

const baseTheme = createTheme({
  spacing: 8,
});

const theme = createTheme({
  ...baseTheme,
  palette: {
    primary: { main: "#487C71", dark: "#3D6158" },
    secondary: { main: "#CE95BF" },
    error: { main: "#B00020" },
  },
  typography: {
    h1: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "96px",
      marginBottom: baseTheme.spacing(3),
    },
    h2: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "60px",
      marginBottom: baseTheme.spacing(3),
    },
    h3: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "48px",
      marginBottom: baseTheme.spacing(3),
    },
    h4: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "34px",
      marginBottom: baseTheme.spacing(3),
    },
    h5: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "24px",
      marginBottom: baseTheme.spacing(3),
    },
    h6: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "20px",
      marginBottom: baseTheme.spacing(3),
    },
    subtitle1: {
      fontFamily: "Cabin",
      fontWeight: 400,
      fontSize: "16px",
    },
    subtitle2: {
      fontFamily: "Cabin",
      fontWeight: 500,
      fontSize: "14px",
    },
    body1: {
      fontFamily: "Cabin",
      fontWeight: 400,
      fontSize: "16px",
    },
    body2: {
      fontFamily: "Cabin",
      fontWeight: 400,
      fontSize: "14px",
    },
    button: {
      fontFamily: "Cabin",
      fontWeight: 500,
      fontSize: "14px",
      textTransform: "capitalize",
    },
    caption: {
      fontFamily: "Cabin",
      fontWeight: 400,
      fontSize: "12px",
    },
    overline: {
      fontFamily: "Cabin",
      fontWeight: 400,
      fontSize: "10px",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [poppins, cabin],
      },
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: baseTheme.spacing(1),
      },
    },
    MuiFab: {
      primary: {
        backgroundColor: "#3D6158",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
    MuiLink: {
      underlineHover: {
        textDecoration: "underline",
      },
    },
    MuiBottomNavigation: {
      root: {
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "#487C71",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: "white !important",
        "&$selected": {
          fontWeight: "600 !important",
        },
      },
      label: {
        fontFamily: "Cabin",
        fontSize: "12px",
      },
    },
  },
});

export default theme;
