import { configureStore } from "@reduxjs/toolkit";
import filterReducer from "./slices/filter.slice";
import POIFeaturesReducer from "./slices/poiFeatures.slice";
import routeReducer from "./slices/route.slice";
import thunk from "redux-thunk";

export const store = configureStore({
  reducer: {
    filter: filterReducer,
    route: routeReducer,
    poiFeatures: POIFeaturesReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
