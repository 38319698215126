import { makeStyles, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { db } from "../../firebase";
import { useHistory } from "react-router-dom";

import BackButton from "../../components/BackButton";
import AppNavBar from "../../components/navbars/AppNavBar";
import { useState } from "react";
import config from "../../config";
import TextField from "@material-ui/core/TextField";
import Button from "../../components/Button";
import AppBar from "../../components/navbars/AppBar";
import BottomNavBar from "../../components/navbars/BottomNavBar";
import { useDevice } from "../../hooks/useDevice";
import bannerImage from "../../assets/images/comment.jpg";
import logo from "../../assets/icons/main-logo.png";
import { ReactComponent as PaperPlaneIcon } from "../../assets/icons/paper-plane-tilt.svg";
import { ReactComponent as PaperPlaneIconWhite } from "../../assets/icons/paper-plane-tilt-white.svg";
import Typography from "@material-ui/core/Typography";
import { useAlert } from "../../contexts/AlertProvider";

const useStyles = makeStyles(() =>
  createStyles({
    containerDesktop: {
      height: "100vh",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "1fr",
    },
    banner: {
      objectFit: "cover",
      height: "100vh",
      width: "100%",
    },
    info: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      flexDirection: "column",
      width: "max(400px, 60%)",
      padding: "16px",
    },
    topPane: {
      gridColumn: "1",
      gridRow: "1",
      display: "flex",
      justifyContent: "center",
    },
    bottomPane: {
      gridColumn: "2",
      gridRow: "1",
    },
    logo: {
      position: "absolute",
      maxWidth: "50%",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
    },
    imageContainer: {
      position: "relative",
    },
    button: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      margin: 0,
      padding: 0,
    },
    wrapper: {
      width: "min(70%, 410px)",
      marginRight: "min(140px, 10%)",
      display: "flex",
      height: "100%",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    mobileWrapper: {
      marginBottom: "48px",
    },
    mobileContainer: {
      width: "100%",
      padding: "24px 16px",
    },
    title: {
      fontFamily: "Cabin",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.5px",
      marginBottom: "16px",
    },
    dialogMainText: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "24px",
      marginBottom: 0,
    },
    dialogSecondaryText: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    dialogDesktopText: {
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "28px",
      letterSpacing: "0.5px",
      marginBottom: 0,
      marginRight: "19px",
      color: "#fff",
    },
    dialogDesktopIcon: {
      marginRight: "24px",
    },
  })
);

const WrappedComment = (): JSX.Element => {
  const classes = useStyles();
  const [comment, setComment] = useState<string>("");
  const [formError, setFormError] = useState<string>("");
  const isMobile = useDevice();
  const history = useHistory();
  const alert = useAlert();
  const validateForm = (comment: string) => {
    setFormError(
      comment.trim().length > 0
        ? ""
        : "You must enter a comment to submit feedback"
    );
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
    validateForm(event.target.value);
  };

  const MobileAlert = (
    <>
      <PaperPlaneIcon />
      <Typography variant="h6" className={classes.dialogMainText}>
        Feedback sent
      </Typography>
      <Typography
        variant="h6"
        color="primary"
        className={classes.dialogSecondaryText}
      >
        Thank you!
      </Typography>
    </>
  );

  const DesktopAlert = (
    <>
      <Typography variant="h6" className={classes.dialogDesktopText}>
        Feedback sent. Thank you!
      </Typography>
      <PaperPlaneIconWhite className={classes.dialogDesktopIcon} />
    </>
  );

  const handleSubmit = () => {
    validateForm(comment);
    if (comment.length > 0)
      db.collection("comments")
        .add({ comment })
        .then(() => {
          alert.sendAlert(
            <>
              {isMobile && MobileAlert}
              {!isMobile && DesktopAlert}
            </>
          );
          history.goBack();
        });
  };
  if (isMobile) {
    return (
      <>
        <AppBar />
        <Box>
          <Box pt={10} className={classes.mobileContainer}>
            <Box className={classes.mobileWrapper}>
              <Box>{isMobile || <BackButton />}</Box>
              <Box>
                <Box className={classes.title}>
                  Leave us a comment and anything else you think might help
                </Box>
              </Box>
              <Box>
                <TextField
                  id="comment"
                  rows={6}
                  multiline
                  label="Write here"
                  variant="filled"
                  fullWidth={true}
                  value={comment}
                  onChange={handleInputChange}
                  required
                  error={formError.length === 0 ? false : true}
                  helperText={formError}
                />
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <BottomNavBar activeTabIndex={2} items={config.bottomNavItems} />
      </>
    );
  }

  return (
    <>
      <AppNavBar activeTabIndex={1} items={config.bottomNavItems} />
      <Box className={classes.containerDesktop}>
        <Box className={classes.topPane}>
          <Box className={classes.info}>
            <Box>
              <BackButton />
              <Box>
                <Box className={classes.title} mt="48px">
                  Leave us a comment and anything else you think might help
                </Box>
              </Box>
              <Box style={{ marginTop: "48px" }}>
                <TextField
                  id="comment"
                  rows={6}
                  multiline
                  label="Write here"
                  variant="filled"
                  fullWidth={true}
                  value={comment}
                  onChange={handleInputChange}
                  required
                  error={formError.length === 0 ? false : true}
                  helperText={formError}
                />
              </Box>
              <Button
                color="primary"
                variant="contained"
                fullWidth={true}
                style={{ marginTop: "48px" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={classes.bottomPane}>
          <div className={classes.imageContainer}>
            <img
              src={bannerImage}
              className={classes.banner}
              width="100%"
              height="100%"
              alt=""
            />
            <img src={logo} className={classes.logo} />
          </div>
        </Box>
      </Box>
    </>
  );
};
export default WrappedComment;
