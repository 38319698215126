import EVChargePointIcon from "../assets/icons/map/ev-charge-point.svg";
import EVChargeFilterIcon from "../assets/icons/filter/ev-charge-point.svg";
import EVChargeFastIcon from "../assets/icons/poi/ev-charge-point-fast.svg";
import EVChargeSlowIcon from "../assets/icons/poi/ev-charge-point-slow.svg";
import EVChargeFastSlowIcon from "../assets/icons/poi/ev-charge-point-slow-fast.svg";
import EVCarPointIcon from "../assets/icons/map/ev-car-hire.svg";
import EVCarFilterIcon from "../assets/icons/filter/ev-car-hire.svg";
import EVCarMapIcon from "../assets/icons/poi/ev-car-hire.svg";
import bikeHireIcon from "../assets/icons/map/bike-hire.svg";
import bikeFilterIcon from "../assets/icons/filter/bicycle.svg";
import bikeMapIcon from "../assets/icons/poi/bike-hire.svg";
import busStationIcon from "../assets/icons/map/bus-station.svg";
import busStationFilterIcon from "../assets/icons/filter/bus-station.svg";
import busStationMapIcon from "../assets/icons/poi/bus-station.svg";
import electrekBikeChargeCafeIcon from "../assets/icons/map/electrek-bike-charge-cafe.svg";
import electrekBikeChargeFilterIcon from "../assets/icons/filter/electrek-bike-charge-cafe.svg";
import electrekBikeChargeMapIcon from "../assets/icons/poi/electrek-bike-charge-cafe.svg";
import ferryStationIcon from "../assets/icons/map/ferry-station.svg";
import ferryStationFilterIcon from "../assets/icons/filter/ferry-station.svg";
import ferryStationMapIcon from "../assets/icons/poi/ferry-station.svg";
import otherAccomodationIcon from "../assets/icons/map/other-accommodation.svg";
import otherAccomodationFilterIcon from "../assets/icons/filter/other-accommodation.svg";
import otherAccomodationMapIcon from "../assets/icons/poi/other-accommodation.svg";
import foodAndDrinkIcon from "../assets/icons/map/food-and-drink.svg";
import foodAndDrinkFilterIcon from "../assets/icons/filter/food-and-drink.svg";
import foodAndDrinkMapIcon from "../assets/icons/poi/food-and-drink.svg";
import natureIcon from "../assets/icons/map/nature.svg";
import natureFilterIcon from "../assets/icons/filter/nature.svg";
import natureMapIcon from "../assets/icons/poi/nature.svg";
import walksIcon from "../assets/icons/map/walks.svg";
import walksFilterIcon from "../assets/icons/filter/walks.svg";
import walksMapIcon from "../assets/icons/poi/walks.svg";
import wildSwimIcon from "../assets/icons/map/wild-swim.svg";
import wildSwimFilterIcon from "../assets/icons/filter/wild-swim.svg";
import wildSwimMapIcon from "../assets/icons/poi/wild-swim.svg";
import townIcon from "../assets/icons/map/town.svg";
import townFilterIcon from "../assets/icons/filter/town.svg";
import townMapIcon from "../assets/icons/poi/town.svg";
import historicSiteIcon from "../assets/icons/map/historic-site.svg";
import historicSiteFilterIcon from "../assets/icons/filter/historic-site.svg";
import historicSiteMapIcon from "../assets/icons/poi/historic-site.svg";
import bikeFriendlyAccomodation from "../assets/icons/map/bike-friendly-accommodation.svg";
import bikeFriendlyAccomodationFilter from "../assets/icons/filter/bike-friendly-accommodation.svg";
import bikeFriendlyAccomodationMap from "../assets/icons/poi/bike-friendly-accommodation.svg";
import adventureActivityIcon from "../assets/icons/map/adventure-activity.svg";
import adventureActivityFilterIcon from "../assets/icons/filter/adventure-activity.svg";
import adventureActivityMapIcon from "../assets/icons/poi/adventure-activity.svg";
import parkingIcon from "../assets/icons/map/parking.svg";
import parkingFilterIcon from "../assets/icons/filter/parking.svg";
import parkingMapIcon from "../assets/icons/poi/parking.svg";
import bikeRepairStationIcon from "../assets/icons/map/bike-repair-station.svg";
import bikeRepairStationFilterIcon from "../assets/icons/filter/bike-repair-station.svg";
import bikeRepairStationMapIcon from "../assets/icons/poi/bike-repair-station.svg";
import artFilterIcon from "../assets/icons/filter/art.svg";
import artMapIcon from "../assets/icons/poi/art.svg";
import artIcon from "../assets/icons/map/art.svg";
import EVAcommodationMapIcon from "../assets/icons/poi/ev-accommodation.svg";
import EVAcommodationFilterIcon from "../assets/icons/filter/ev-accommodation.svg";
import EVAcommodationIcon from "../assets/icons/map/ev-accommodation.svg";

export const RewildingSiteIcons = {
  "EV Charge Point": {
    point: EVChargePointIcon,
    filter: EVChargeFilterIcon,
    color: "#70C063",
    poiBackground: EVChargeFastSlowIcon,
  },
  "EV Charge Point Slow": {
    point: EVChargePointIcon,
    filter: EVChargeFilterIcon,
    color: "#54B1CF",
    poiBackground: EVChargeSlowIcon,
  },
  "EV Charge Point Fast": {
    point: EVChargePointIcon,
    filter: EVChargeFilterIcon,
    color: "#70C063",
    poiBackground: EVChargeFastIcon,
  },
  "EV-Car hire": {
    point: EVCarPointIcon,
    filter: EVCarFilterIcon,
    color: "#7D77BA",
    poiBackground: EVCarMapIcon,
  },
  "Bike Hire": {
    point: bikeHireIcon,
    filter: bikeFilterIcon,
    color: "#2F89DC",
    poiBackground: bikeMapIcon,
  },
  "Electrek Bike Charge Cafe": {
    point: electrekBikeChargeCafeIcon,
    filter: electrekBikeChargeFilterIcon,
    color: "#D83B90",
    poiBackground: electrekBikeChargeMapIcon,
  },
  "Food and Drink": {
    point: foodAndDrinkIcon,
    filter: foodAndDrinkFilterIcon,
    color: "#E95225",
    poiBackground: foodAndDrinkMapIcon,
  },
  Nature: {
    point: natureIcon,
    filter: natureFilterIcon,
    color: "#348897",
    poiBackground: natureMapIcon,
  },
  Walks: {
    point: walksIcon,
    filter: walksFilterIcon,
    color: "#9C5295",
    poiBackground: walksMapIcon,
  },
  "EV Accommodation": {
    point: EVAcommodationIcon,
    filter: EVAcommodationFilterIcon,
    color: "#EAB127",
    poiBackground: EVAcommodationMapIcon,
  },
  "Bike Friendly Accommodation": {
    point: bikeFriendlyAccomodation,
    filter: bikeFriendlyAccomodationFilter,
    color: "#E28A23",
    poiBackground: bikeFriendlyAccomodationMap,
  },
  "Eco Accomodation": {
    point: otherAccomodationIcon,
    filter: otherAccomodationFilterIcon,
    color: "#E1D035",
    poiBackground: otherAccomodationMapIcon,
  },
  "Historic site": {
    point: historicSiteIcon,
    filter: historicSiteFilterIcon,
    color: "#B79F82",
    poiBackground: historicSiteMapIcon,
  },
  "Train Station/Bike Bus": {
    point: busStationIcon,
    filter: busStationFilterIcon,
    color: "#959595",
    poiBackground: busStationMapIcon,
  },
  "Ferry Terminal": {
    point: ferryStationIcon,
    filter: ferryStationFilterIcon,
    color: "#518DBB",
    poiBackground: ferryStationMapIcon,
  },
  "Adventure Activity": {
    point: adventureActivityIcon,
    filter: adventureActivityFilterIcon,
    color: "#487C71",
    poiBackground: adventureActivityMapIcon,
  },
  Town: {
    point: townIcon,
    filter: townFilterIcon,
    color: "#E1D035",
    poiBackground: townMapIcon,
  },
  "Wild Swim": {
    point: wildSwimIcon,
    filter: wildSwimFilterIcon,
    color: "#518DBB",
    poiBackground: wildSwimMapIcon,
  },
  "Bike repair station": {
    point: bikeRepairStationIcon,
    filter: bikeRepairStationFilterIcon,
    color: "#7D77BA",
    poiBackground: bikeRepairStationMapIcon,
  },
  "Car park": {
    point: parkingIcon,
    filter: parkingFilterIcon,
    color: "#518DBB",
    poiBackground: parkingMapIcon,
  },
  Art: {
    point: artIcon,
    filter: artFilterIcon,
    color: "#E95225",
    poiBackground: artMapIcon,
  },
};
