import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import bannerImage from "../assets/images/community.jpeg";
import DeviceWrapper from "../components/containers/DeviceContainer";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useHistory } from "react-router-dom";
import { Button, Divider } from "@material-ui/core";
import { useDevice } from "../hooks/useDevice";
import { useEffect, useState } from "react";
import { useAlert } from "../contexts/AlertProvider";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(() =>
  createStyles({
    linkContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    linkIcon: {
      width: "15px",
    },
    linkText: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: "16px",
      letterSpacing: "0.15px",
      marginBottom: "16px",
      margin: "0",
    },
    text: {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    banner: {
      objectFit: "cover",
      height: "100vh",
      width: "100%",
    },
    info: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonText: {
      fontFamily: "Cabin",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1.25px",
      padding: "4px",
    },
    mobileMargin: {
      marginTop: "24px",
    },
    desktopMargin: {
      marginTop: "48px",
    },
    mobileDialog: {
      width: "279px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    desktopDialog: {
      width: "458px",
      height: "48px",
      background: "#487C71",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  })
);

const Community = (): JSX.Element => {
  const [isCommentDialogOpen, setCommentDialogOpen] = useState<boolean>(false);
  const [dialogAlert, setDialogAlert] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useDevice();
  const alert = useAlert();

  useEffect(() => {
    if (alert.alert) {
      setDialogAlert(alert.getAlert());
      setCommentDialogOpen(true);
    }
  });

  const CommentSuccessDialogMobile = (
    <Dialog
      open={isCommentDialogOpen}
      onClose={() => setCommentDialogOpen(false)}
      PaperProps={{
        style: {
          boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.5)",
          borderRadius: "16px",
        },
      }}
      hideBackdrop
    >
      <DialogContent className={classes.mobileDialog}>
        {dialogAlert}
      </DialogContent>
    </Dialog>
  );

  const CommentSuccessDialogDesktop = (
    <Dialog
      open={isCommentDialogOpen}
      onClose={() => setCommentDialogOpen(false)}
      PaperProps={{
        style: {
          borderRadius: 0,
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
          position: "absolute",
          left: "0",
          bottom: "10vh",
          margin: 0,
        },
      }}
      hideBackdrop
    >
      <DialogContent className={classes.desktopDialog} style={{ padding: 0 }}>
        {dialogAlert}
      </DialogContent>
    </Dialog>
  );

  return (
    <Box>
      {isMobile && CommentSuccessDialogMobile}
      {!isMobile && CommentSuccessDialogDesktop}
      <Box mb="24px">
        <Typography className={classes.text}>
          Where would you like to see more EV or E-bike chargers?,&nbsp;
          <Link
            className={classes.linkText}
            onClick={() => history.push("/comment")}
          >
            tell us about it
          </Link>
        </Typography>
        <Box
          className={classes.linkContainer}
          mt={isMobile ? "24px" : "48px"}
          onClick={() => history.push("/promote")}
        >
          <Typography className={classes.linkText} color="primary">
            Click to be part of the Electrek Explorer business community
          </Typography>
          <IconButton color="primary" aria-label="go to used storage page">
            <ArrowForwardIosIcon
              fontSize="small"
              className={classes.linkIcon}
            />
          </IconButton>
        </Box>
        <Divider />
        <Typography className={classes.text} style={{ margin: "16px 0" }}>
          Local businesses share your views by completing our surveys:
        </Typography>
        <Box
          className={classes.linkContainer}
          onClick={() =>
            window.open(
              "https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAMAAErdTNBUQkJYQlZOOUdNTjZBRVRHWDVKVDgyQ1pBRS4u"
            )
          }
        >
          <Typography className={classes.linkText} color="primary">
            Accommodation, Visitor or Activity Center Survey
          </Typography>
          <IconButton color="primary" aria-label="go to used storage page">
            <ArrowForwardIosIcon
              fontSize="small"
              className={classes.linkIcon}
            />
          </IconButton>
        </Box>
        <Divider />
        <Box
          className={classes.linkContainer}
          onClick={() =>
            window.open(
              "https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAMAAErdTNBUMFVWVU1XMU9FSDRSU1RJT0dJU0QyNTQxQS4u"
            )
          }
        >
          <Typography className={classes.linkText} color="primary">
            Food and Drink Survey
          </Typography>
          <IconButton color="primary" aria-label="go to used storage page">
            <ArrowForwardIosIcon
              fontSize="small"
              className={classes.linkIcon}
            />
          </IconButton>
        </Box>
        <Divider />
      </Box>
      <Typography className={classes.text}>
        Nature, wellness and community initives -&nbsp;
        <Link
          className={classes.linkText}
          onClick={() => history.push("/comment")}
        >
          tell us what you do
        </Link>
      </Typography>
      <Box mt={isMobile ? "24px" : "48px"} pb="72px">
        <Button
          style={{ margin: 0 }}
          color="primary"
          variant="outlined"
          fullWidth={true}
          onClick={() => history.push("/comment")}
        >
          <span className={classes.buttonText}>Leave us feedback</span>
        </Button>
      </Box>
    </Box>
  );
};

const CommunityContainer = (): JSX.Element => {
  return (
    <DeviceWrapper
      activeTabIndex={1}
      bannerImage={bannerImage}
      backButtonShowed={false}
      title="Community"
    >
      <Community />
    </DeviceWrapper>
  );
};
export default CommunityContainer;
