import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "./Button";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import { useAuth } from "../contexts/AuthContext";
import { ReactComponent as GoogleIcon } from "../assets/icons/google-negative.svg";
import { ReactComponent as FacebookIcon } from "../assets/icons/facebook-negative.svg";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { useState } from "react";

const Auth = (): JSX.Element => {
  const history = useHistory();
  const { signInWithFacebook, signInWithGoogle } = useAuth();
  const [openFacebookAlert, setOpenFacebookAlert] = useState<boolean>(false);
  const [openGoogleAlert, setOpenGoogleAlert] = useState<boolean>(false);

  const FacebookAlertDialog = (
    <Dialog
      open={openFacebookAlert}
      onClose={() => setOpenFacebookAlert(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Failed to sign in</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Failed to login using Facebook
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenFacebookAlert(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const GoogleAlertDialog = (
    <Dialog
      open={openGoogleAlert}
      onClose={() => setOpenGoogleAlert(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Failed to sign in</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Failed to login using Google
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenGoogleAlert(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleClickSignInWithFacebook = async () => {
    try {
      await signInWithFacebook();
      history.push("/explore");
    } catch (error) {
      setOpenFacebookAlert(true);
      console.log(error.message);
    }
  };

  const handleClickSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      history.push("/explore");
    } catch (error) {
      setOpenGoogleAlert(true);
      console.log(error.message);
    }
  };

  return (
    <>
      <Box mt={2}>
        <Button
          style={{ margin: 0 }}
          color="primary"
          variant="contained"
          fullWidth={true}
          onClick={() => history.push("/sign-up")}
        >
          Sign up
        </Button>
      </Box>
      <Box mt={2}>
        <Button
          style={{ margin: 0 }}
          color="primary"
          variant="outlined"
          fullWidth={true}
          onClick={handleClickSignInWithFacebook}
        >
          Sign up with <FacebookIcon style={{ marginLeft: "8px" }} />
        </Button>
      </Box>
      <Box mt={2}>
        <Button
          style={{ margin: 0 }}
          color="primary"
          variant="outlined"
          fullWidth={true}
          onClick={handleClickSignInWithGoogle}
        >
          Sign up with <GoogleIcon style={{ marginLeft: "8px" }} />
        </Button>
      </Box>
      <Box mt={3} textAlign={"center"}>
        <Typography variant="subtitle1">
          Already have an account?{" "}
          <Link
            color="primary"
            style={{ textDecoration: "none" }}
            href="/sign-in"
          >
            Sign in
          </Link>
        </Typography>
      </Box>
      {FacebookAlertDialog}
      {GoogleAlertDialog}
    </>
  );
};

export default Auth;
