import { eChargeConnectorTypes } from "../../types/eCharger";
import { connector } from "../../types/PointsOfInterests";

export const groupConnectorsByType = (
  connectors: connector[]
): connector[][] => {
  const connectorsEntires = Object.values(eChargeConnectorTypes).map((type) => {
    const filterConnectors = connectors.filter(
      (connector) => type === connector.type
    );
    return filterConnectors;
  });
  const filterConnectorsEntires = connectorsEntires.filter(
    (entire) => entire.length
  );
  return filterConnectorsEntires;
};
