import React, { useState } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { NavBarItem } from "../../types/navBarItem";
import { useHistory } from "react-router-dom";

interface Props {
  activeTabIndex: number;
  items: Array<NavBarItem>;
}

const BottomNavBar: React.VFC<Props> = ({ activeTabIndex, items }) => {
  const history = useHistory();
  const [bottomNavNumber, setBottomNavNumber] = useState(activeTabIndex);
  const handleClickBottomNav = (
    event: React.ChangeEvent<unknown>,
    newNavNumber: number
  ) => {
    setBottomNavNumber(newNavNumber);
    return history.push(items[newNavNumber].path);
  };
  return (
    <BottomNavigation
      style={{ zIndex: 9999 }}
      value={bottomNavNumber}
      onChange={handleClickBottomNav}
      showLabels
    >
      {items.map((item: NavBarItem, index: number) => (
        <BottomNavigationAction
          key={index}
          label={item.label}
          icon={index === bottomNavNumber ? item.activeIcon : item.icon}
        />
      ))}
    </BottomNavigation>
  );
};

export default BottomNavBar;
