import React, { Fragment } from "react";
import Button from "../components/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DeviceWrapper from "../components/containers/DeviceContainer";
import bannerImage from "../assets/images/settings.png";

const UsedStorage = (): JSX.Element => {
  return (
    <Fragment>
      <Box py={3}>
        <Typography variant="h5">Used storage</Typography>
        <Typography variant="body1">
          Downloaded maps are currently using [x]mb of your device’s storage.
        </Typography>
        <Box pt={2}>
          <Button
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => null}
          >
            Clear downloaded maps
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};

const UsedStorageContainer = (): JSX.Element => {
  return (
    <DeviceWrapper
      activeTabIndex={2}
      bannerImage={bannerImage}
      backButtonShowed={false}
      title="Settings"
    >
      <UsedStorage />
    </DeviceWrapper>
  );
};
export default UsedStorageContainer;
