import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "../../components/Button";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import accountImage from "../../assets/images/create-account-page.png";
import DeviceWrapper from "../../components/containers/DeviceContainer";
import { ReactComponent as GoogleIcon } from "../../assets/icons/google-negative.svg";
import { ReactComponent as FacebookIcon } from "../../assets/icons/facebook-negative.svg";
import Link from "@material-ui/core/Link";
import { useAuth } from "../../contexts/AuthContext";
import DialogTitle from "@material-ui/core/DialogTitle";

const CreateAccount = (): JSX.Element => {
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openFacebookAlert, setOpenFacebookAlert] = useState<boolean>(false);
  const [openGoogleAlert, setOpenGoogleAlert] = useState<boolean>(false);
  const { signInWithFacebook, signInWithGoogle } = useAuth();

  const handleDialogClose = () => history.push("/explore");

  const handleClickSignInWithFacebook = async () => {
    try {
      await signInWithFacebook();
      history.push("/explore");
    } catch (error) {
      setOpenFacebookAlert(true);
      console.log(error.message);
    }
  };

  const handleClickSignInWithGoogle = async () => {
    try {
      await signInWithGoogle();
      history.push("/explore");
    } catch (error) {
      setOpenGoogleAlert(true);
      console.log(error.message);
    }
  };

  return (
    <Fragment>
      <Box>
        <Typography variant="h5">Create your account</Typography>
        <Typography variant="body1">
          Sign up to save your preferences and to use the navigation feature
        </Typography>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={() => history.push("/sign-up")}
          >
            Sign up
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="outlined"
            fullWidth={true}
            onClick={handleClickSignInWithFacebook}
          >
            Sign up with <FacebookIcon style={{ marginLeft: "8px" }} />
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="outlined"
            fullWidth={true}
            onClick={handleClickSignInWithGoogle}
          >
            Sign up with <GoogleIcon style={{ marginLeft: "8px" }} />
          </Button>
        </Box>
        <Box mt={3} textAlign={"center"}>
          <Typography variant="subtitle1">
            Already have an account?{" "}
            <Link
              color="primary"
              style={{ textDecoration: "none" }}
              href="/sign-in"
            >
              Sign in
            </Link>
          </Typography>
        </Box>

        <Button
          color="primary"
          fullWidth={true}
          onClick={() => setOpenDialog(true)}
        >
          I’ll join later
        </Button>
      </Box>
      <Dialog
        open={openFacebookAlert}
        onClose={() => setOpenFacebookAlert(false)}
      >
        <DialogTitle>Failed to sign in</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Failed to login using Facebook
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFacebookAlert(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openGoogleAlert} onClose={() => setOpenGoogleAlert(false)}>
        <DialogTitle>Failed to sign in</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Failed to login using Google
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenGoogleAlert(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Routes will be unavailable to download without an account
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const CreateAccountContainer = (): JSX.Element => {
  return (
    <DeviceWrapper bannerImage={accountImage}>
      <CreateAccount />
    </DeviceWrapper>
  );
};
export default CreateAccountContainer;
