import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

interface Props {
  email: string;
  resendEmail: () => void;
}
const EmailSended: React.VFC<Props> = ({ email, resendEmail }) => {
  const history = useHistory();

  const handleSignInClick = () => history.push("/sign-in");
  const openEmailApp = () => window.open("mailto:");
  return (
    <>
      <Box>
        <Typography variant="h5">Please check your email </Typography>
        <Typography>
          We sent a password reset link to{" "}
          <Link color="primary" style={{ textDecoration: "none" }}>
            {email}
          </Link>
        </Typography>
        <Box mt="24px"> </Box>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="contained"
            fullWidth={true}
            onClick={openEmailApp}
          >
            Open email app
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="text"
            fullWidth={true}
            onClick={resendEmail}
          >
            Don’t receive the email? &nbsp;
            <span style={{ textDecoration: "underline" }}>Click to resend</span>
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            style={{ margin: 0 }}
            color="primary"
            variant="text"
            fullWidth={true}
            onClick={handleSignInClick}
          >
            Back to sign in
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EmailSended;
