import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    tooltipsContainer: {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100vw",
      zIndex: 9000,
      height: "100vh",
      background: "rgba(0, 53, 42, 0.4)",
    },
    tooltips: {
      textAlign: "center",
      position: "fixed",
      background: "white",
      display: "flex",
      maxWidth: "310px",
      padding: "4px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: "4px",
      zIndex: 9999,
      opacity: 0.8,
    },
    tooltipsArrow: {
      position: "absolute",
      bottom: "0px",
      zIndex: -1,
      left: "50%",
      height: "20px",
      width: "20px",
      background: "white",
      transform: "translate(-50%, 50%) rotate(45deg)",
    },
    close: {
      cursor: "pointer",
    },
  })
);

interface Props {
  text: string;
  style?: React.CSSProperties | undefined;
  onClose?: () => void;
}

const Tooltip: React.VFC<Props> = ({ text, style, onClose }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.tooltipsContainer} onClick={onClose} />
      <div className={classes.tooltips} style={style} onClick={() => null}>
        <div>{text}</div>
        <CloseIcon onClick={onClose} className={classes.close} />
        <div className={classes.tooltipsArrow}></div>
      </div>
    </>
  );
};

export default Tooltip;
