import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import config from "../../config";
import BottomNavBar from "../navbars/BottomNavBar";
import AppBar from "../navbars/AppBar";
import logo from "../../assets/icons/main-logo.png";

const useStyles = makeStyles({
  banner: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  imageContainer: {
    position: "relative",
  },
  logo: {
    position: "absolute",
    maxWidth: "60%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
  },
});
type Props = {
  activeTabIndex?: number;
  bannerImage?: string;
  bannerHeight?: string;
  backButtonShowed?: boolean;
  title?: string;
  hideLogo?: boolean;
};
const MobileWrapper: React.FC<Props> = ({
  bannerImage = null,
  title,
  children,
  activeTabIndex,
  hideLogo = false,
  backButtonShowed = false,
  bannerHeight = "calc(100vh - 400px)",
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      {(title || backButtonShowed) && (
        <AppBar backButton={backButtonShowed} title={title} />
      )}
      {backButtonShowed ||
        (bannerImage && (
          <div style={{ position: "relative", height: bannerHeight }}>
            <img src={bannerImage} className={classes.banner} alt="" />
            {hideLogo || (
              <img
                src={logo}
                className={classes.logo}
                alt="Electrek Explorer Logo"
              />
            )}
          </div>
        ))}
      <Box px={2} pt={3} pb={3}>
        {children}
      </Box>
      {activeTabIndex && (
        <Box pt={7}>
          <BottomNavBar
            activeTabIndex={activeTabIndex}
            items={config.bottomNavItems}
          />
        </Box>
      )}
    </Fragment>
  );
};

export default MobileWrapper;
