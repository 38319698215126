import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "../components/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import welcomeImage from "../assets/images/logo.jpg";
import DeviceWrapper from "../components/containers/DeviceContainer";
import { useDevice } from "../hooks/useDevice";
import Explain from "./Explain";
import StorageService from "../services/storageService";

const useStyles = makeStyles({
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  wrapper: {
    width: "100%",
    maxWidth: "max(500px, 50%)",
  },
});
interface Props {
  handleGetStartedOnClick?: () => void;
  showTooltips?: () => void;
}

const Welcome: React.VFC<Props> = ({
  handleGetStartedOnClick,
  showTooltips,
}) => {
  const classes = useStyles();
  const isMobile = useDevice();

  return (
    <Fragment>
      <Box px={2} className={classes.wrapper}>
        {isMobile || (
          <>
            <Typography variant="h5"> Welcome to Electrek Explorer </Typography>
            <Typography variant="body1">Plan my Electrek Adventure.</Typography>
          </>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth={true}
          onClick={isMobile ? showTooltips : handleGetStartedOnClick}
        >
          Get started
        </Button>
      </Box>
    </Fragment>
  );
};

const WelcomeContainer = (): JSX.Element => {
  const [showTooltips, setTooltips] = useState<boolean>(false);
  const isMobile = useDevice();
  const history = useHistory();

  useEffect(() => {
    const welcomeShown = localStorage.getItem("welcomeShown");
    if (welcomeShown) {
      history.push("/explore");
    }
  });

  const handleGetStartedOnClick = () => {
    StorageService.firstInit();
    history.push("/create-account");
  };
  if (showTooltips && isMobile)
    return <Explain onClose={handleGetStartedOnClick} />;

  return (
    <DeviceWrapper
      bannerImage={welcomeImage}
      bannerHeight={"calc((var(--vh) * 100) - 108px)"}
      hideLogo={true}
    >
      <Welcome
        handleGetStartedOnClick={handleGetStartedOnClick}
        showTooltips={() => setTooltips(true)}
      />
    </DeviceWrapper>
  );
};
export default WelcomeContainer;
