import { Fragment, useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import config from "../config";
import Map from "../components/Map";
import AppNavBar from "../components/navbars/AppNavBar";
import { useDevice } from "../hooks/useDevice";
import MapTooltips from "../components/toolltips/MapTooltips";
import RouteDetails from "./routes/RouteDetails";
import RoutesList from "./RoutesList";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteCurrentRoute,
  setRegion,
  setRouteAsync,
  setType,
} from "../store/slices/route.slice";
import { travelMode } from "../types/routeType";

const useStyles = makeStyles(() =>
  createStyles({
    map: {
      height: "100%",
      width: "100ww",
    },
    containerDesktop: {
      display: "grid",
      gridTemplateColumns: "1fr 2fr",
    },
    mapContainer: {
      marginTop: "55px",
      height: "calc(var(--vh, vh) * 100 - 350px)",
      width: "100%",
      zIndex: 20,
      transition: "all .4s ease-out",
    },
    wrapper: {
      position: "sticky",
      height: "100vh",
      overflowY: "auto",
      top: "0",
      boxSizing: "border-box",
    },
  })
);

const MapContainer = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { routeId } = useParams<{ routeId?: string }>();
  const [isRoutesList, setRoutesList] = useState<boolean>(!routeId);
  const { region, type, currentRoute, routes } = useSelector(
    (state: RootState) => state.route
  );
  const isMobile = useDevice();
  const colors = {
    Easy: "green",
    Moderate: "blue",
    Hard: "red",
    Challenging: "black",
  };
  useEffect(() => {
    if (routeId) {
      dispatch(setRouteAsync(Number(routeId)));
    }
  }, [routes]);

  useEffect(() => {
    if (currentRoute) {
      if (!type) dispatch(setType(currentRoute.travel_mode));
      if (!region) dispatch(setRegion(currentRoute.region));
    } else history.push("/route-details");
  }, [currentRoute]);
  useEffect(
    () => () => {
      dispatch(deleteCurrentRoute());
    },
    []
  );

  if (!isMobile)
    return (
      <Fragment>
        <AppNavBar activeTabIndex={0} items={config.bottomNavItems} />
        <Box className={classes.containerDesktop}>
          <Box pt={10} pr={1} className={classes.wrapper}>
            {isRoutesList ? (
              <RoutesList
                showRouteDetails={() => {
                  setRoutesList(false);
                }}
              />
            ) : (
              <RouteDetails
                showRoutesList={() => {
                  setRoutesList(true);
                  history.push("/route-details");
                }}
              />
            )}
          </Box>
          <div style={{ position: "relative" }}>
            <Map
              lineColor={
                type === travelMode.eBike
                  ? colors[currentRoute?.difficulty ?? "Easy"]
                  : undefined
              }
              showRoute={!!routeId}
              zoom={10}
              interactive={true}
              className={classes.map}
            />
          </div>
        </Box>
        <MapTooltips
          style={{
            top: "138px",
            left: "calc(33vw + 24px)",
          }}
        />
      </Fragment>
    );

  return (
    <Fragment>
      {isRoutesList ? (
        <RoutesList
          showRouteDetails={() => {
            setRoutesList(false);
          }}
        />
      ) : (
        <RouteDetails
          showRoutesList={() => {
            setRoutesList(true);
            history.push("/route-details");
          }}
        />
      )}
    </Fragment>
  );
};

export default MapContainer;
