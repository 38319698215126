import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { db } from "../../firebase";

import BackButton from "../../components/BackButton";
import AppNavBar from "../../components/navbars/AppNavBar";
import React, { Fragment, useState } from "react";
import config from "../../config";
import TextField from "@material-ui/core/TextField";
import { MenuItem } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../../components/Button";
import clsx from "clsx";
import AppBar from "../../components/navbars/AppBar";
import BottomNavBar from "../../components/navbars/BottomNavBar";
import { useHistory } from "react-router-dom";
import { useDevice } from "../../hooks/useDevice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "min(50%, 410px)",
      marginRight: "min(140px, 10%)",
      display: "flex",
      height: "100%",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    mobileWrapper: {
      marginBottom: "48px",
    },
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      paddingBottom: "clamp(10px, 5%, 133px)",
      paddingLeft: "min(158px, 10%)",
    },
    mobileContainer: {
      width: "100%",
      padding: "24px 16px",
    },
    title: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "32px",
      lineHeight: "40px",
      letterSpacing: "0.18px",
    },
    mobileTitle: {
      fontFamily: "Cabin",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "0.5px",
    },
    margin: {
      marginTop: theme.spacing(3),
    },
    comment: {
      marginBottom: "24px",
    },
    checkbox: {
      fontFamily: "Cabin",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
  })
);
interface State {
  name: string;
  address: string;
  postcode: string;
  email: string;
  phoneNumber: string;
  type: string;
  comment: string;
}

interface checkBoxState {
  EVChargerOnsite: boolean;
  bikeHire: boolean;
  bikeFriendly: boolean;
  plugSockets: boolean;
}

const Promote = (): JSX.Element => {
  const classes = useStyles();
  const [values, setValues] = useState<State>({
    name: "",
    address: "",
    postcode: "",
    email: "",
    phoneNumber: "",
    type: "Type1",
    comment: "",
  });
  const isMobile = useDevice();

  const [checkboxValues, setCheckboxValues] = useState<checkBoxState>({
    EVChargerOnsite: false,
    bikeHire: false,
    bikeFriendly: false,
    plugSockets: false,
  });
  const history = useHistory();
  const buisnessTypes = [
    {
      value: "Organisation Type",
      label: "Organisation Type",
    },
    {
      value: "Bike hire",
      label: "Bike hire",
    },
    {
      value: "Food & Drink",
      label: "Food & Drink",
    },
    {
      value: "Accommodation",
      label: "Accommodation",
    },
    {
      value: "Nature and wellness",
      label: "Nature and wellness",
    },
    {
      value: "Tourism",
      label: "Tourism",
    },
    {
      value: "Nature",
      label: "Nature",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];

  const handleInputChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const handleCheckBoxChange = (prop: keyof checkBoxState) => () => {
    setCheckboxValues({ ...checkboxValues, [prop]: !checkboxValues[prop] });
  };

  const handleSubmit = () => {
    db.collection("promotedBusinesses").add({ ...values, ...checkboxValues });
  };

  return (
    <Fragment>
      {isMobile ? (
        <AppBar />
      ) : (
        <AppNavBar activeTabIndex={1} items={config.bottomNavItems} />
      )}
      <Box
        pt={10}
        className={clsx(isMobile ? classes.mobileContainer : classes.container)}
      >
        <Box
          className={clsx(isMobile ? classes.mobileWrapper : classes.wrapper)}
        >
          <Box>{isMobile || <BackButton />}</Box>
          <Box>
            <Box
              className={clsx(isMobile ? classes.mobileTitle : classes.title)}
            >
              Promote your business with us
            </Box>
            <Box>
              <TextField
                className={classes.margin}
                id="name"
                label="Organization Name"
                variant="filled"
                fullWidth={true}
                onChange={handleInputChange("name")}
              />
              <TextField
                className={classes.margin}
                id="address"
                label="Organization address"
                variant="filled"
                fullWidth={true}
                onChange={handleInputChange("address")}
              />
              <TextField
                className={classes.margin}
                id="postcode"
                label="Organization Postcode"
                variant="filled"
                fullWidth={true}
                onChange={handleInputChange("postcode")}
              />
              <TextField
                className={classes.margin}
                id="email"
                label="Contact email"
                variant="filled"
                fullWidth={true}
                onChange={handleInputChange("email")}
              />
              <TextField
                className={classes.margin}
                id="phoneNumber"
                label="Contact number (not essential)"
                variant="filled"
                fullWidth={true}
                onChange={handleInputChange("phoneNumber")}
              />
              <TextField
                className={classes.margin}
                select
                id="type"
                label="Organization Type"
                variant="filled"
                fullWidth={true}
                onChange={handleInputChange("type")}
              >
                {buisnessTypes.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    style={{ background: "#EDEDED" }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </Box>
        <Box
          className={clsx(isMobile ? classes.mobileWrapper : classes.wrapper)}
        >
          <Box></Box>
          <Box>
            <Typography variant="h6"> Tick all applies </Typography>
            <Box>
              <Box>
                <Checkbox
                  id="EVChargerOnsite"
                  onChange={handleCheckBoxChange("EVChargerOnsite")}
                  name="checked"
                  color="primary"
                />
                <span className={classes.checkbox}>EV charger onsite</span>
              </Box>
              <Box>
                <Checkbox
                  id="bikeHire"
                  onChange={handleCheckBoxChange("bikeHire")}
                  name="checked"
                  color="primary"
                />
                <span className={classes.checkbox}>Bike Hire onsite</span>
              </Box>
              <Box>
                <Checkbox
                  id="bikeFriendly"
                  onChange={handleCheckBoxChange("bikeFriendly")}
                  name="checked"
                  color="primary"
                />
                <span className={classes.checkbox}>Bike Friendly</span>
              </Box>
              <Box style={{ marginBottom: "24px" }}>
                <Checkbox
                  id="plugSockets"
                  onChange={handleCheckBoxChange("plugSockets")}
                  name="checked"
                  color="primary"
                />
                <span className={classes.checkbox}>
                  Plug sockets on site that E-bikers can use
                </span>
              </Box>
              <TextField
                className={classes.comment}
                id="comment"
                rows={isMobile ? 1 : 4}
                multiline
                label="Any other comment"
                variant="filled"
                fullWidth={true}
                onChange={handleInputChange("comment")}
              />
              <Button
                color="primary"
                variant="outlined"
                style={{ marginBottom: "16px" }}
                fullWidth={true}
                onClick={() => history.goBack()}
              >
                Delete and exit
              </Button>
              <Button
                color="primary"
                variant="contained"
                fullWidth={true}
                onClick={() => {
                  handleSubmit();
                  history.goBack();
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {isMobile && (
        <BottomNavBar activeTabIndex={1} items={config.bottomNavItems} />
      )}
    </Fragment>
  );
};

export default Promote;
